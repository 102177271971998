import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom'

// Action
import *  as UserAction from '../../mthp/actionCreators/user.actionCreator';

// Pages
import Mthp from '../../mthp/pages/index.page';

class RequireAuthentication extends Component {
    componentDidMount() {
        this.props.dispatch(UserAction.retrieveUser());
    }

    render() {
        const {
            isLoggedIn,
            path,
            component,
            // ...rest
        } = this.props;

        // return (
        //     <Route {...rest} render={props => (
        //         isLoggedIn === true
        //             ? <Mthp.MainPage>
        //                 <Component {...props} />
        //             </Mthp.MainPage> 
        //             : <Redirect to='/mthp/login' />
        //         )}    
        //     />
        // )

        return isLoggedIn === true
            ? (
                <Mthp.MainPage>
                    <Route path={path} component={component} />
                </Mthp.MainPage>
            )
            : <Route path='/mthp' component={Mthp.LoginPage} exact /> 
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.session && state.session.isLoggedIn
});

export default connect(mapStateToProps)(RequireAuthentication);