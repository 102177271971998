import { Typography, withStyles } from '@material-ui/core';

export const HeaderTypography = withStyles({
    root: {
        fontSize: 16,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    }
})(Typography);

export const LabelTypography = withStyles({
    root: {
        fontWeight: 'bold'
    }
})(Typography);