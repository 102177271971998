import { Request } from '../../utils';

import { headersGlobal, refreshToken } from '../services/utilities.services';

import store from 'store';

// Actions
import { loginSuccess, loginUnauthorized } from './oauth.action';
import { apiRequestStart, apiRequestEnd } from './ui.action';

// Constants
import {
     RETRIEVE_USER_FAILED,
    GET_USER_LIST_SUCCESS, GET_USER_LIST_FAILED,
    UPDATE_USER_STATUS_SUCCESS, UPDATE_USER_STATUS_FAILED
} from '../../constants/actions.contants';
import { GET_USER_LIST, UPDATE_USER_STATUS } from '../../constants/url-collection-mycare';

const getUserListSuccess = ({ status, message, result }) => ({
    type: GET_USER_LIST_SUCCESS,
    payload: { status, message, result }
});

const getUserListFailed = ({ status, message, result }) => ({
    type: GET_USER_LIST_FAILED,
    payload: { status, message, result }
});

//not used
// const retrieveUserSuccess = ({ currentUser, organizationData, userPromotionData }) => ({
//     type: RETRIEVE_USER_SUCCESS,
//     payload: {
//         currentUser,
//         organizationData,
//         userPromotionData
//     }
// });

const retrieveUserFailed = () => ({
    type: RETRIEVE_USER_FAILED
});

const updateUserStatusSuccess = ({ status, message, result }) => ({
    type: UPDATE_USER_STATUS_SUCCESS,
    payload: { status, message, result }
});

const updateUserStatusFailed = error => ({
    type: UPDATE_USER_STATUS_FAILED,
    payload: error
});

export function getUserList() {
    return async dispatch => {
        const trigger = 'getUserList';
        try {
            dispatch(apiRequestStart(trigger));
            const { data } = await Request({
                method: 'GET',
                url: GET_USER_LIST,
                params: {
                    skip: 0,
                    limit: 100
                }
            });
            dispatch(getUserListSuccess(data));
        }
        catch (error) {
            dispatch(getUserListFailed(error));
        }
        finally {
            dispatch(apiRequestEnd(trigger));
        }
    }
}

export function retrieveUser() {
    return async dispatch => {
        
        try {
            const currentUser = store.get('user-mycare');
            if (!currentUser){
                dispatch(loginUnauthorized());
                store.remove('user-mycare');
                dispatch(retrieveUserFailed());
            }
            else {
                await Request({
                    method: 'GET',
                    url: `${process.env.REACT_APP_BASE_API_URL_MYCARE}/mycare/admin/transactions`,
                }
                , headersGlobal());

                dispatch(loginSuccess());

            }
        }
        catch (error) {
            try{
                if (error.response && error.response.status === 401) {
                    await refreshToken();

                    dispatch(loginSuccess());
                }else{
                    throw error;
                }
            }
            catch(e){

                // if(e.response && e.response.status === 401){
                //     dispatch(loginUnauthorized());
                    
                //     store.remove('user-mycare');

                //     setTimeout(() => {
                //         window.location.reload();
                //     }, 2000);
                // }
            }
        }
    }
}

export function updateUserStatus({ userId, status }) {
    return async dispatch => {
        const trigger = 'updateUserStatus';
        try {

            dispatch(apiRequestStart(trigger));
            const { data } = await Request({
                method: 'PUT', 
                url: UPDATE_USER_STATUS(userId),
                data: {
                    user_id: userId,
                    status
                }
            },
            headersGlobal());

            dispatch(updateUserStatusSuccess(data));
        }
        catch(error) {
            try{
                if(error.response && error.response.status === 401){
                    await refreshToken();

                    const { data } = await Request({
                        method: 'PUT', 
                        url: UPDATE_USER_STATUS(userId),
                        data: {
                            user_id: userId,
                            status
                        }
                    },
                    headersGlobal());

                    dispatch(updateUserStatusSuccess(data));

                }
                else{
                    throw error;
                }
            }
            catch(e){
                if(e.response)
                dispatch(updateUserStatusFailed(e.response.data));

            }
        }
        finally {
            dispatch(apiRequestEnd(trigger))
        }
    }
}