import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools  } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import { LOGOUT_SUCCESS } from '../constants/actions.contants';
import {
    apiResponseReducer,
    hcpReducer,
    messageReducer,
    patientReducer,
    productReducer,
    promotionReducer,
    sessionReducer,
    transactionReducer,
    uiReducer,
    userReducer,
    unverifiedEmailReducer
} from '../reducers/common/index.reducer';
import { paymentReducer } from '../reducers/mthp/index.reducer';

const appReducer = combineReducers({
    apiResponse: apiResponseReducer,
    hcp: hcpReducer,
    message: messageReducer,
    patient: patientReducer,
    payment: paymentReducer,
    product: productReducer,
    promotion: promotionReducer,
    session: sessionReducer,
    transaction: transactionReducer,
    ui: uiReducer,
    user: userReducer,
    unverifiedEmail: unverifiedEmailReducer
});

// This is used to reset the reducer to it's initial state upon logging out.
const rootReducer = (state, action) => {
    if (action.type === LOGOUT_SUCCESS)
        state = undefined;

    return appReducer(state, action);
};

export default () => {
    const composeEnhancers = composeWithDevTools({});

    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
}