import { API_REQUEST } from '../../constants/actions.contants';

// TODO: Simplify this.
const defaultState = {
    exportClientPaymentReport: { isLoading: false },
    addHCP: { isLoading: false },
    getHCPList: { isLoading: false },
    getHCPDetails: { isLoading: false },
    getPromotionList: { isLoading: false},
    getPromotionDetails: { isLoading: false },
    getHCPNumberOfTransactions: { isLoading: false },
    getHCPPatientList: { isLoading: false },
    getHCPTransactions: { isLoading: false },
    getMessageLogs: { isLoading: false },
    getPatientDetails: { isLoading: false },
    getPatientList: { isLoading: false },
    getPatientProgrammeCodes: { isLoading: false },
    getPatientTransactions: { isLoading: false },
    getPatientRegistrationHistory: { isLoading: false },
    getProductList: { isLoading: false },
    getTransactions: { isLoading: false },
    getUserList: { isLoading: false },
    getClientPaymentSummaryList: { isLoading: false },
    getClientPaymentTransactionDetails: { isLoading: false },
    getFinancePaymentSummaryList: { isLoading: false },
    getFinancePaymentTransactionDetails: { isLoading: false },
    login: { isLoading: false },
    updatePromotionAllocation: { isLoading: false },
    updateUserStatus: { isLoading: false },
    voidTransaction: { isLoading: false },
    addUser: {isLoading: false },
    verifyHcpUser: { isLoading: false },
    updateUserPassword: { isLoading: false },
    forgotUserPassword: { isLoading: false },
    getUnverifiedEmailList: { isLoading: false }
}

const uiReducer = (state = defaultState, action) => {
    switch (action.type) {
        case API_REQUEST:
            return { ...defaultState, ...action.payload };
        default:
            return state;
    }
};

export default uiReducer;