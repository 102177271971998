// Constants
import { GET_MESSAGE_LOGS_SUCCESS, GET_MESSAGE_LOGS_FAILED } from '../constants/actions.contants';

export const getMessageLogsSuccess = ({ status, message, result }) => ({
    type: GET_MESSAGE_LOGS_SUCCESS,
    payload: { status, message, result }
});

export const getMessageLogsFailed = ({ status, message, result }) => ({
    type: GET_MESSAGE_LOGS_FAILED,
    payload: { status, message, result }
});