/**
 * TODO:
 * 1. Add GST Enabled checkbox
 * 2. Add GST Number textbox
 * 3. Integrate with Update API
 */

import React, { Component } from 'react';
import {
    Grid,
    Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText,
    FormControl, InputLabel, FormHelperText, InputAdornment,
    Select, MenuItem, Button
} from '@material-ui/core';
import { isEmail, isJSON } from 'validator';

/**
 * Custom Components 
 */
import { InputControl } from '../FormComponents';
import { HeaderTypography } from '../Typography';

/**
 * Error Messages
 */
import {
    FIRST_NAME_REQUIRED, LAST_NAME_REQUIRED,
    EMAIL_REQUIRED, EMAIL_INVALID_FORMAT, PASSWORD_REQUIRED,
    PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH, PASSWORD_AND_CONFIRM_PASSWORD_NOT_MATCH,
    HCP_CODE_REQUIRED,
    MOBILE_NUMBER_REQUIRED,
    CLINIC_NAME_REQUIRED, CLINIC_ADDRESS_REQUIRED,
    PHARMACY_NAME_REQUIRED, PHARMACY_ADDRESS_REQUIRED,
    HEAD_OFFICE_EMAIL_REQUIRED, HEAD_OFFICE_EMAIL_INVALID_FORMAT,
    BANK_NAME_REQUIRED, BANK_ACCOUNT_NAME_REQUIRED, BANK_ACCOUNT_NUMBER_REQUIRED
} from '../../constants/error-messages';

const isOrganizationEmailValid = organizationEmailArray => Array.isArray(organizationEmailArray)
    ? organizationEmailArray.every(email => isEmail(email))
    : false;

const formattedOrganizationEmail = organizationEmail => {
    if (typeof organizationEmail === 'string' && isJSON(organizationEmail))
        return JSON.stringify(JSON.parse(organizationEmail));
    else if (typeof organizationEmail === 'string')
        return JSON.stringify(organizationEmail.split(','));
}

class AddHCPForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            hcpCode: "",
            userType: "Physician",
            organizationName: "",
            organizationAddress: "",
            organizationEmail: "",
            mobileNumber: "",
            bankName: "",
            accountName: "",
            accountNumber: "",
            error: {
                firstName: '',
                middleName: '',
                lastName: '',
                email: '',
                password: "",
                confirmPassword: "",
                hcpCode: "",
                organizationName: '',
                organizationAddress: '',
                organizationEmail: '',
                mobileNumber: '',
                bankName: '',
                accountName: '',
                accountNumber: '',
            }
        }
    }

    resetState = () => this.setState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        hcpCode: "",
        userType: "Physician",
        organizationName: "",
        organizationAddress: "",
        organizationEmail: "",
        mobileNumber: "",
        bankName: "",
        accountName: "",
        accountNumber: "",
        error: {
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            password: "",
            confirmPassword: "",
            hcpCode: "",
            organizationName: '',
            organizationAddress: '',
            organizationEmail: '',
            mobileNumber: '',
            bankName: '',
            accountName: '',
            accountNumber: '',
        }
    });

    onInputChange = e => {
        const { name, value } = e.target;
        const error = { ...this.state.error };

        // TODO: Find a way to optimize this like -- [error[name]]: ''
        error['firstName'] = '';
        error['lastName'] = '';
        error['email'] = '';
        error['password'] = '';
        error['confirmPassword'] = '';
        error['hcpCode'] = '';
        error['organizationName'] = '';
        error['organizationAddress'] = '';
        error['organizationEmail'] = '';
        error['mobileNumber'] = '';
        error['bankName'] = '';
        error['accountName'] = '';
        error['accountNumber'] = '';

        // TODO: Tax identification number is currently not set
        if (name === 'mobileNumber')
            value.match(/^[0-9]*$/) && this.setState({ [name]: value });
        else if (name === 'accountNumber')
            value.match(/^[0-9]*$/) && this.setState({ [name]: value });
        else
            this.setState({ [name]: value });

        this.setState({ error });
    }

    onSubmit = () => {
        const {
            user_id: userId,
            organization_id: organizationId,
            location_id: locationId,
            payment_entity_id: paymentEntityId,
            onClick
        } = this.props
        const {
            firstName, middleName, lastName,
            email, password, confirmPassword, hcpCode,
            userType,
            organizationName, organizationAddress, organizationEmail, mobileNumber,
            bankName, accountName, accountNumber
        } = this.state;
        const error = { ...this.state.error };

        // TODO: Move this to a validator class.
        if (firstName.trim().length === 0)
            error['firstName'] = FIRST_NAME_REQUIRED;
        else if (lastName.trim().length === 0)
            error['lastName'] = LAST_NAME_REQUIRED;
        else if (email.trim().length === 0)
            error['email'] = EMAIL_REQUIRED;
        else if (!isEmail(email))
            error['email'] = EMAIL_INVALID_FORMAT;
        else if (password.length === 0)
            error['password'] = PASSWORD_REQUIRED;
        else if (password.length < 8)
            error['password'] = PASSWORD_MIN_LENGTH;
        else if (password.length > 16)
            error['password'] = PASSWORD_MAX_LENGTH;
        else if (password !== confirmPassword)
            error['confirmPassword'] = PASSWORD_AND_CONFIRM_PASSWORD_NOT_MATCH;
        else if (hcpCode.trim().length === 0)
            error['hcpCode'] = HCP_CODE_REQUIRED;
        else if (organizationName.trim().length === 0)
            error['organizationName'] = userType.toLowerCase() === 'pharmacist' ? PHARMACY_NAME_REQUIRED : CLINIC_NAME_REQUIRED;
        else if (organizationAddress.trim().length === 0)
            error['organizationAddress'] = userType.toLowerCase() === 'pharmacist' ? PHARMACY_ADDRESS_REQUIRED : CLINIC_ADDRESS_REQUIRED;
        else if (!organizationEmail || organizationEmail.trim().length === 0)
            error['organizationEmail'] = HEAD_OFFICE_EMAIL_REQUIRED;
        else if (!isOrganizationEmailValid(JSON.parse(formattedOrganizationEmail(organizationEmail))))
            error['organizationEmail'] = HEAD_OFFICE_EMAIL_INVALID_FORMAT;
        else if (mobileNumber.trim().length === 0)
            error['mobileNumber'] = MOBILE_NUMBER_REQUIRED;
        else if (bankName.trim().length === 0)
            error['bankName'] = BANK_NAME_REQUIRED;
        else if (accountName.trim().length === 0)
            error['accountName'] = BANK_ACCOUNT_NAME_REQUIRED;
        else if (accountNumber.trim().length === 0)
            error['accountNumber'] = BANK_ACCOUNT_NUMBER_REQUIRED;
        else
            onClick({
                firstName, middleName, lastName,
                email, password, hcpCode,
                userType,
                organizationName, organizationAddress, organizationEmail: formattedOrganizationEmail(organizationEmail), mobileNumber,
                bankName, accountName, accountNumber
            });

        this.setState({ error });
    };

    render() {
        const { open, title, subtitle, onClose } = this.props;
        let {
            firstName, middleName, lastName,
            email, password, confirmPassword, hcpCode, userType,
            organizationName, organizationAddress, organizationEmail, mobileNumber,
            bankName, accountName, accountNumber,
            error
        } = this.state;

        const isOrganizationEmailJSON = organizationEmail !== null && isJSON(organizationEmail);
        organizationEmail = isOrganizationEmailJSON && Array.isArray(JSON.parse(organizationEmail))
            ? JSON.parse(organizationEmail).toString()
            : organizationEmail
            || '';

        return (
            <Dialog open={open}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{subtitle}</DialogContentText>
                    <HeaderTypography color='primary'>User Details</HeaderTypography>
                    <Grid container
                        justify='space-between'
                        spacing={24}>
                        <Grid item xs={4}>
                            <InputControl fullWidth
                                error={error.firstName}
                                margin='dense'
                                inputLabelFor='firstName'
                                inputLabel='First Name'
                                inputName='firstName'
                                value={firstName}
                                onChange={this.onInputChange} />
                        </Grid>

                        <Grid item xs={4}>
                            <InputControl fullWidth
                                error={error.middleName}
                                margin='dense'
                                inputLabelFor='middleName'
                                inputLabel='Middle Name'
                                inputName='middleName'
                                value={middleName}
                                onChange={this.onInputChange} />
                        </Grid>

                        <Grid item xs={4}>
                            <InputControl fullWidth
                                error={error.lastName}
                                margin='dense'
                                inputLabelFor='lastName'
                                inputLabel='Last Name'
                                inputName='lastName'
                                value={lastName}
                                onChange={this.onInputChange} />
                        </Grid>
                    </Grid>

                    <InputControl fullWidth
                        error={error.email}
                        margin='dense'
                        inputLabelFor='email'
                        inputLabel='Email'
                        inputName='email'
                        value={email}
                        onChange={this.onInputChange} />

                    <InputControl fullWidth
                        error={error.password}
                        margin='dense'
                        inputLabelFor='password'
                        inputLabel='Password'
                        inputName='password'
                        value={password}
                        onChange={this.onInputChange} />

                    <InputControl fullWidth
                        error={error.confirmPassword}
                        margin='dense'
                        inputLabelFor='confirmPassword'
                        inputLabel='Confirm Password'
                        inputName='confirmPassword'
                        value={confirmPassword}
                        onChange={this.onInputChange} />

                    <InputControl fullWidth
                        error={error.hcpCode}
                        margin='dense'
                        inputLabelFor='hcpCode'
                        inputLabel='Health Care Professional Code'
                        inputName='hcpCode'
                        value={hcpCode}
                        onChange={this.onInputChange} />

                    <FormControl
                        error={Boolean(error.userType)}
                        fullWidth={true}
                        margin='dense'>
                        <InputLabel htmlFor='userType'>User Type</InputLabel>
                        <UserTypeList
                            name='userType'
                            value={userType}
                            onChange={this.onInputChange} />
                        <FormHelperText>{error.userType}</FormHelperText>
                    </FormControl>

                    <HeaderTypography color='primary'>Outlet Details</HeaderTypography>

                    <InputControl fullWidth
                        error={error.organizationName}
                        margin='dense'
                        inputLabelFor='organizationName'
                        inputLabel={`${userType === 'Physician' ? 'Clinic' : 'Drugstore'} Name`}
                        inputName='organizationName'
                        value={organizationName}
                        onChange={this.onInputChange} />

                    <InputControl fullWidth
                        error={error.organizationAddress}
                        margin='dense'
                        inputLabelFor='organizationAddress'
                        inputLabel={`${userType === 'Physician' ? 'Clinic' : 'Drugstore'} Address`}
                        inputName='organizationAddress'
                        value={organizationAddress}
                        onChange={this.onInputChange} />

                    <InputControl fullWidth
                        error={error.organizationEmail}
                        margin='dense'
                        inputLabelFor='organizationEmail'
                        inputLabel='Head Office Email'
                        inputName='organizationEmail'
                        value={organizationEmail}
                        onChange={this.onInputChange} />

                    <InputControl fullWidth
                        error={error.mobileNumber}
                        margin='dense'
                        inputLabelFor='mobileNumber'
                        inputLabel='Mobile Number'
                        inputName='mobileNumber'
                        value={mobileNumber}
                        onChange={this.onInputChange}
                        startAdornment={<InputAdornment position='start'>+</InputAdornment>} />

                    <HeaderTypography color='primary'>Bank Details</HeaderTypography>
                    <InputControl fullWidth
                        error={error.bankName}
                        margin='dense'
                        inputLabelFor='bankName'
                        inputLabel='Bank Name'
                        inputName='bankName'
                        value={bankName}
                        onChange={this.onInputChange} />

                    <InputControl fullWidth
                        error={error.accountName}
                        margin='dense'
                        inputLabelFor='accountName'
                        inputLabel='Account Name'
                        inputName='accountName'
                        value={accountName}
                        onChange={this.onInputChange} />

                    <InputControl fullWidth
                        error={error.accountNumber}
                        margin='dense'
                        inputLabelFor='accountNumber'
                        inputLabel='Account Number'
                        inputName='accountNumber'
                        value={accountNumber}
                        onChange={this.onInputChange} />
                </DialogContent>
                <DialogActions>
                    <Button size='small' onClick={() => { 
                        this.resetState();
                        onClose();
                        }}>Cancel</Button>
                    <Button size='small' variant='contained' color='primary' onClick={this.onSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const UserTypeList = ({ name, value, onChange, style }) => {
    const userTypes = ['Pharmacist', 'Physician'];
    const options = userTypes.map((value, index) => (
        <MenuItem key={index} value={value}>{value}</MenuItem>
    ));

    return (
        <Select name={name}
            value={value}
            onChange={onChange}
            style={style}>
            {options}
        </Select>
    )
}

export default AddHCPForm;