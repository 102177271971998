import React, { Component } from "react";
import { InputAdornment } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { InputControl } from "./FormComponents";

class Search extends Component {
  render() {
    return (
      <form onSubmit={this.props.onSubmit}>
        <InputControl
          fullWidth={false}
          error={""}
          margin="dense"
          inputLabelFor="search"
          inputName="search"
          onChange={this.props.onChange}
          onSubmit={this.props.onSubmit}
          value={this.props.value}
          startAdornment={
            <InputAdornment>
              <SearchIcon />
            </InputAdornment>
          }
        />
      </form>
    );
  }
}

export default Search;
