import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    Grid, Button, 
    TableRow, TableCell, 
    AppBar, Tabs, Tab, Typography,
    withStyles
} from '@material-ui/core';

// Actions
import {
    getPatientProgrammeCodes,
    getPatientTransactions,
    getPatientRegistrationHistory
} from '../actionCreators/patient.actionCreator';
import { voidTransaction } from '../actionCreators/transaction.actionCreator'

// Custom Components
import { VoidButton } from '../../components/Button';
import Dialog from '../../components/Dialog';
import Search from '../../components/Search.Input';
import Table from '../../components/Table';
import TransactionDetails from '../../components/TransactionDetails';
import TransactionListTable from '../../components/TransactionList.Table';

// Utilities
import { dateTimeFormat } from '../../utils';

const TransactionDialog = withStyles({
    paper: {
        maxWidth: 450
    }
})(Dialog);

// TODO: Add a response handler and dialog for void transaction

class PatientDetails extends Component {
    state = {
        currentPage: 0,
        rowsPerPage: 10,
        inputSearchTransaction: '',
        isVoidButtonClicked: false,
        selectedTabValue: 0,
        // responseDialogOpen: false,
        selectedTransactionBatch: {},
        transactionDialogOpen: false,
        voidConfirmMessage: ''
    }

    componentDidMount() {
        const { dispatch, match } = this.props;
        const patientId = match.params.patientId;
        dispatch(getPatientProgrammeCodes(patientId));
        dispatch(getPatientTransactions(patientId, {
            page: this.state.currentPage,
            limit: this.state.rowsPerPage
        }));
        dispatch(getPatientRegistrationHistory(patientId));
    }

    handleChangePage = async (page, rowsPerPage) => {
        const patientId = this.props.match.params.patientId;
        await this.props.dispatch(getPatientTransactions(patientId, {
            page,
            limit: rowsPerPage
        }));

        this.setState({ currentPage: page })
    }

    handleSelectTab = (event, value) => this.setState({ selectedTabValue: value }); 
    
    handleTransactionDialogOpen = boolean => this.setState({
        transactionDialogOpen: boolean,
        isVoidButtonClicked: false,
        voidConfirmMessage: ''
    });

    onClickTransactionBatch = ({ transactionDialogOpen, selectedTransactionBatchId }) => {
        const { transactionList } = this.props;
        const transactionBatch = transactionList.find(({ transaction_batch_id }) => transaction_batch_id === selectedTransactionBatchId);
        this.setState({
            selectedTransactionBatch: transactionBatch,
            transactionDialogOpen
        });
    }

    onClickVoid = isVoidButtonClicked => {
        this.setState({
            isVoidButtonClicked,
            voidConfirmMessage: isVoidButtonClicked === true ? 'Are you sure you want to void this transaction?' : ''
        });
    }
    
    onConfirmVoid = async transactionBatchId => {
        const { dispatch, match } = this.props;
        const patientId = match.params.patientId;
        await dispatch(voidTransaction({ transactionBatchId }));
        this.setState({
            transactionDialogOpen: false,
            isVoidButtonClicked: false, 
            voidConfirmMessage: ''
        });
        await dispatch(getPatientTransactions(patientId, {
            page: this.state.currentPage,
            limit: this.state.rowsPerPage
        }));
    }

    onInputSearch = ({ target: { value } }) => {
        const { dispatch, match } = this.props;
        const patientId = match.params.patientId;
        this.setState({ inputSearchTransaction: value });
        value.length > 0
            ? dispatch(getPatientTransactions(patientId, { query: value }))
            : dispatch(getPatientTransactions(patientId, {
                page: this.state.currentPage,
                limit: this.state.rowsPerPage
            }));
    }

    render() {
        // TODO: Convert patient to promotion patient
        let {
            // apiResponse,
            programmeCodes = [], registrationHistory = [],
            transactionCount, transactionList
        } = this.props;
        const {
            inputSearchTransaction,
            // responseDialogOpen,
            selectedTabValue,
            selectedTransactionBatch,
            transactionDialogOpen,
            isVoidButtonClicked, voidConfirmMessage
        } = this.state;

        const programmeCodeHeaders = ['Patient Code ID', 'Promotion Patient ID', 'Program Code', 'Status', 'Expiry Date', 'Created At'];
        const programmeCodeBody = programmeCodes.length > 0
            ? programmeCodes.map(({
                patient_code_id,
                promotion_patient_id,
                promo_code,
                status,
                expiry_date,
                created_at
            }, index) => (
                <TableRow key={index}>
                    <TableCell>{patient_code_id}</TableCell>
                    <TableCell>{promotion_patient_id}</TableCell>
                    <TableCell>{promo_code}</TableCell>
                    <TableCell>{status}</TableCell>
                    <TableCell>{dateTimeFormat(expiry_date)}</TableCell>
                    <TableCell>{dateTimeFormat(created_at)}</TableCell>
                </TableRow>
            ))
            : (
                <TableRow>
                    <TableCell colSpan={programmeCodeHeaders.length}>There are no data available</TableCell>
                </TableRow>
            );

        const registrationHistoryHeaders = ['Promotion Patient ID', 'Locale', 'Status', 'Registration Date'];
        const registrationHistoryBody = registrationHistory.length > 0
            ? registrationHistory.map(({ 
                promotion_patient_created_at: promotionPatientCreatedAt, 
                promotion_patient_id: promotionPatientId, 
                country_locale_id: countryLocaleId, 
                promotion_patient_status: promotionPatientStatus }, 
                index) => (
                <TableRow key={index}>
                    <TableCell>{promotionPatientId}</TableCell>
                    <TableCell>{countryLocaleId}</TableCell>
                    <TableCell>{promotionPatientStatus}</TableCell>
                    <TableCell>{dateTimeFormat(promotionPatientCreatedAt)}</TableCell>
                </TableRow>
            ))
            : (
                <TableRow>
                    <TableCell colSpan={registrationHistoryHeaders.length}>There are no data available</TableCell>
                </TableRow>
            );

        return (
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <h4>Patient Details</h4>
                </Grid>

                <Grid item xs={12}>
                    <AppBar position='relative'>
                        <Tabs value={selectedTabValue} onChange={this.handleSelectTab}>
                            <Tab label='Programme Codes' />
                            <Tab label='Transactions' />
                            <Tab label='Registration History' />
                        </Tabs>
                    </AppBar>
                    {selectedTabValue === 0 && <Fragment>
                        <Table
                            headers={programmeCodeHeaders}
                            body={programmeCodeBody} />
                    </Fragment>}
                    {selectedTabValue === 1 && <Fragment>
                        <TransactionListTable
                            transactionCount={transactionCount}
                            transactionList={transactionList}
                            onSelectTransaction={({ transactionDialogOpen, selectedTransactionBatchId }) => 
                                this.onClickTransactionBatch({ transactionDialogOpen, selectedTransactionBatchId })}
                            onChangePage={this.handleChangePage}
                            searchInputComponent={
                                <Search 
                                    onChange={this.onInputSearch}
                                    value={inputSearchTransaction} />
                            } />
                    </Fragment>}
                    {selectedTabValue === 2 && <Fragment>
                        <Table
                            headers={registrationHistoryHeaders}
                            body={registrationHistoryBody} />
                    </Fragment>}
                </Grid>

                <TransactionDialog
                    open={transactionDialogOpen}
                    title={null}
                    content={<TransactionDetails selectedTransaction={selectedTransactionBatch} />}
                    scroll='paper'
                    actions={
                        <Grid container direction='row' justify='flex-end' alignItems='center'>
                            <Typography>{voidConfirmMessage}</Typography> 
                            <Button size='small'
                                onClick={() => this.handleTransactionDialogOpen(false)}>Cancel</Button>
                            { 
                                isVoidButtonClicked && 
                                    <VoidButton size='small' color='primary'
                                        onClick={() => this.onConfirmVoid(selectedTransactionBatch.transaction_batch_id)}>
                                        Yes
                                    </VoidButton>
                            }
                            {
                                !isVoidButtonClicked && selectedTransactionBatch.deleted_at === null && 
                                    <VoidButton size='small' variant='contained'
                                        onClick={() => this.onClickVoid(true)}>
                                        Void
                                    </VoidButton>
                            }
                        </Grid>
                    } />
            </Grid>
        );
    }
}

const mapStateToProps = ({ apiResponse, patient }) => ({
    apiResponse: apiResponse && apiResponse.default,
    programmeCodes: patient.programmeCodes && patient.programmeCodes.result,
    registrationHistory: patient.registrationHistory && patient.registrationHistory.result,
    transactionCount: patient.transaction.count,
    transactionList: patient.transaction.list,
    transactionPages: patient.transaction.pages
});

export default connect(mapStateToProps)(PatientDetails);