import {
    RETRIEVE_PRODUCT_LIST_SUCCESS, RETRIEVE_PRODUCT_LIST_FAILED
} from '../../constants/actions.contants';

const defaultState = {
    list: []
}

const productReducer = (state = defaultState, action) => {
    switch(action.type) {
        case RETRIEVE_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                list: [...action.payload.result]
            }
        case RETRIEVE_PRODUCT_LIST_FAILED:
            return { ...state }
        default:
            return { ...state };
    }
}

export default productReducer;