import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
    Grid, Typography
} from '@material-ui/core';

// Constants
import {
    CONNECT_LOGO,
    MYTOTALHEALTH_LOGO,
    STAYON_LOGO,
    MYCARE_LOGO
} from '../../constants/logo.constants';

const data = [
    {
        image: MYTOTALHEALTH_LOGO,
        alt: 'MyTotalHealth Logo',
        url: '/mthp'
    }, {
        image: STAYON_LOGO,
        alt: 'Stay On Logo',
        url: '/stayon'
    },
    {
        image: MYCARE_LOGO,
        alt: 'My Care Logo',
        url: '/mycare'
    }
];

const MainPage = () => { 
      
    return (
        <Fragment>
            <img src={CONNECT_LOGO} alt='Connect Logo' style={{ position: 'absolute', height: '100px' }} />
            <Grid container
                direction={'row'}
                alignItems={'center'}
                justify={'center'}
                style={{ background: '#F6F6F6', height: '100%' }}>
                <Grid item xs={12}>
                    <Grid container
                        direction={'row'}
                        alignItems={'center'}
                        justify={'center'}
                        spacing={32}>
                        <Grid item>
                            <Typography variant={'h4'} style={{ color: '#C00000' }}>Choose Platform:</Typography>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction={'row'}
                        alignItems={'center'}
                        justify={'center'}
                        spacing={32}>
                        {
                            data.map(({ image, alt, url }, index) =>
                                <Grid item key={index}>
                                    <Link to={url}>
                                        <div style={{
                                            background: 'white',
                                            borderRadius: 25,
                                            display: 'table-cell',
                                            verticalAlign: 'middle',
                                            padding: 50,
                                            height: 200,
                                            width: 200,
                                            }}>
                                                <img src={image} 
                                                    alt={alt} 
                                                    style={{ width: '100%', objectFit: 'cover' }}
                                                />
                                        </div>
                                    </Link>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default MainPage;