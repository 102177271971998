import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import { IconButton, LinearProgress } from "@material-ui/core";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";

// Custom Components
import Table from "../../components/ReactTable";

// Store
import * as patientStore from "../actionCreators/patient.actionCreator";

// Utilities
import { dateTimeFormat } from "../../utils";

class PatientListPage extends Component {
  state = {
    currentPage: 1,
    previousSearch: "",
    searchInput: ""
  };

  async componentDidMount() {
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== prevProps.history.location.search) {
      this.setState({ previousSearch: prevProps.history.location.search });
      await this._loadData();
    }
  }

  _loadData = async () => {
    const { pathname: currentPath } = this.props.location;
    const params = queryString.parse(this.props.location.search) || {};
    const { searchInput } = this.state;

    this.setState({ searchInput: params.search ? params.search : "" });

    await this.props.getPatientList({ ...params });

    if (params.page <= this.props.promotionPatientPages && params.page > 0) {
      this.setState({ currentPage: params.page });
    } else {
      this.props.history.push(
        `${currentPath}?page=1${searchInput && "&search=" + searchInput}`
      );
    }
  };

  _onInputSearch = e => this.setState({ searchInput: e.target.value });

  _onSubmitSearch = e => {
    e.preventDefault();
    const { pathname: currentPath } = this.props.history.location;
    const { searchInput } = this.state;

    this.props.history.push(
      `${currentPath}?page=1${searchInput && "&search=" + searchInput}`
    );
  };

  onSelectPatient = patientId =>
    this.props.history.push(`${this.props.match.path}/${patientId}`);

  render() {
    const { promotionPatientCount, promotionPatientList, ui } = this.props;
    const { currentPage } = this.state;

    const columns = [
      {
        Header: "ID",
        accessor: "promotion_patient_id"
      },
      {
        Header: "Mobile",
        accessor: "patient_unique_code"
      },
      {
        Header: "Promotion Code",
        accessor: "promotion_code"
      },
      {
        Header: "Locale",
        accessor: "country_locale_id"
      },
      {
        Header: "Status",
        accessor: "promotion_patient_status"
      },
      {
        Header: "Registration Date",
        Cell: props => (
          <span>
            {dateTimeFormat(props.original.promotion_patient_created_at)}
          </span>
        )
      },
      {
        Header: "View Patient",
        Cell: props => (
          <IconButton
            size="small"
            color="primary"
            onClick={() => this.onSelectPatient(props.original.patient_id)}
          >
            <ArrowForwardIcon />
          </IconButton>
        )
      }
    ];

    return (
      <Fragment>
        <h4>Patients</h4>
        {ui.getPatientList.isLoading ? (
            <LinearProgress />
          ) : (
            <Table
              columns={columns}
              count={promotionPatientCount}
              currentPage={currentPage}
              data={promotionPatientList}
              search={{
                onChange: e => this._onInputSearch(e),
                onSubmit: e => this._onSubmitSearch(e),
                value: this.state.searchInput
              }}
            />
          )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ patient, ui }) => ({
  promotionPatientCount: patient.promotionPatient.count,
  promotionPatientList: patient.promotionPatient.list,
  promotionPatientPages: patient.promotionPatient.pages,
  ui
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...patientStore }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientListPage);
