import store from 'store';
import { Request } from '../../utils';

export const serialize = obj => {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  };

export const refreshToken = async () => {
  const headers = {
    'x-connect-project-id': 3,
    'x-connect-promotion-id': 4,
    'x-client-type':'web'
  };

  try {
    const { data } = await Request(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_API_URL_MYCARE}/mycare/core/authenticate/token`,
        data: serialize({
          grant_type: 'refresh_token',
          scope: '*',
          client_id: 'default',
          refresh_token: (store.get('user-mycare') || {}).refreshToken
        })
      },
      headers
    );
    store.set('user-mycare', data);
  } catch (e) {
    store.remove('user-mycare');
    setTimeout(() => {
        window.location.reload();
    }, 2000);
  }
};

export const headersGlobal = () => ({
  Authorization: `Bearer ${store.get('user-mycare').accessToken}`,
  'x-connect-project-id': 3,
  'x-connect-promotion-id': 4,
  'x-client-type':'web'
});