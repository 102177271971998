import {
    RETRIEVE_PROMOTION_DETAILS_SUCCESS, RETRIEVE_PROMOTION_DETAILS_FAILED,
    RETRIEVE_PROMOTION_LIST_SUCCESS, RETRIEVE_PROMOTION_LIST_FAILED
} from '../../constants/actions.contants';

const defaultState = {
    configurations: {},
    details: {},
    promotionList: [],
    promotionCount: 0,
    promotionPages: 0
}

const promotionReducer = (state = defaultState, action) => {
    switch(action.type) {
        case RETRIEVE_PROMOTION_DETAILS_SUCCESS:
            const configurations = action.payload.result ? JSON.parse(action.payload.result.info) : {};
            return {
                ...state,
                details: {...action.payload},
                configurations
            }
        case RETRIEVE_PROMOTION_DETAILS_FAILED:
            return {
                ...state,
                details: {...action.payload}
            }
        case RETRIEVE_PROMOTION_LIST_SUCCESS:
            return {
                ...state,
                promotionList: [...action.payload.result.list],
                promotionCount: action.payload.result.count,
                promotionPages: action.payload.result.pages
            }
        case RETRIEVE_PROMOTION_LIST_FAILED:
            return { ...state }
        default:
            return { ...state };
    }
}

export default promotionReducer;