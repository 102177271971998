import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import store from 'store';

// Pages
import StayOn from '../../stayon/pages/index.page';

class RequireAuthentication extends Component {

    render() {
        const {
            component,
            path
        } = this.props;
        
        return store.get('user')
            ? (
                <StayOn.MainPage>
                    <Route path={path} component={component} />
                </StayOn.MainPage>
            )
            : <Route path={path} component={StayOn.LoginPage} exact /> 
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.session && state.session.isLoggedIn
});

export default connect(mapStateToProps)(RequireAuthentication);