import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, LinearProgress } from "@material-ui/core";
import queryString from 'query-string';

// Actions
import * as messageStore from "../actionCreators/message.actionCreator";

// Components
import Table from "../../components/ReactTable";

//Utilities
import { dateTimeFormat } from "../../utils";

class MessagePage extends Component {
  state = {
    currentPage: 1,
    rowsPerPage: 10,
    searchInput: "",
    previousSearch: ""
  };

  async componentDidMount() {
    await this._loadData();
  }

  async componentDidUpdate(){
    if (this.state.previousSearch !== this.props.history.location.search) {
        this.setState({ previousSearch: this.props.history.location.search });
        await this._loadData();
      }
  }

  _loadData = async () => {
    const { pathname: currentPath } = this.props.history.location;
    let params = queryString.parse(this.props.location.search) || {};
    const { searchInput } = this.state;

    this.setState({ searchInput: params.search ? params.search : '' }); 

    await this.props.getMessageLogs({ ...params, query: searchInput });

    if (params.page <= this.props.smsList && params.page > 0) {
      this.setState({ currentPage: params.page });
    } else {
      this.props.history.push(`${currentPath}?page=1${searchInput && '&search='+searchInput}`);
    }
  };

  _onInputSearch = e => this.setState({ searchInput: e.target.value });

  _onSubmitSearch = e => {
    e.preventDefault();
    const { pathname: currentPath } = this.props.history.location;
    const { searchInput } = this.state;

    this.props.history.push(`${currentPath}?page=1${searchInput && '&search='+searchInput}`);
  };


  render() {
    const { smsCount, smsList, ui } = this.props;
    const { currentPage } = this.state;

    const columns = [
      { Header: "Message ID", accessor: "message_log_id" },
      {
        Header: "Created At",
        accessor: "message_log_created_at",
        Cell: props => (
          <span>{dateTimeFormat(props.original.message_log_created_at)}</span>
        )
      },
      { Header: "Receiver", accessor: "patient_mobile_number" },
      { Header: "Message", accessor: "message" },
      { Header: "Type", accessor: "message_log_type" }
    ];

    return (
      <Fragment>
        <h4>Messages</h4>
    
        <Grid container spacing={16}>
          <Grid item xs={12}>
          {ui.getMessageLogs.isLoading ? (
            <LinearProgress />
          ) : (
                <Table
                  columns={columns}
                  count={smsCount}
                  currentPage={currentPage}
                  data={smsList}
                  search={{
                    onChange: e => this._onInputSearch(e),
                    onSubmit: e => this._onSubmitSearch(e),
                    value: this.state.searchInput
                  }}
                />
          )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ message, ui }) => ({
  smsCount: message.smsCount,
  smsList: message.smsList,
  ui
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...messageStore
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagePage);
