import HomePage from './home.page';
import HCPListPage from './hcp.list.page';
import LoginPage from './login.page';
import MainPage from './main.page';
import HcpListPage from './hcp.list.page';
import HcpDetailsPage from './hcp.details.page';
import PatientListPage from './patient.list.page';
import TransactionListPage from './transaction.list.page';
import UnverifiedEmailListPage from './unverified-email.list.page';

export default {
    HomePage,
    HCPListPage,
    LoginPage,
    MainPage,
    HcpListPage,
    HcpDetailsPage,
    PatientListPage,
    TransactionListPage,
    UnverifiedEmailListPage
}