import React, { Component } from 'react';
import {
    Grid, Paper, Toolbar,
    Table as MaterialTable, TableHead, TableBody, TableRow, TableCell,
    TablePagination,
    IconButton
} from '@material-ui/core';
import {
    CloudDownload as CloudDownloadIcon,
    FilterList as FilterListIcon,
    ViewColumn as ViewColumnIcon,
} from '@material-ui/icons'

class Table extends Component {
    state = {
        page: 0,
        rowsPerPage: 10,
        rowsPerPageOptions: [10] // [10, 50, 100]
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
        this.props.onChangePage(page, this.state.rowsPerPage);
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const {
            headers = [], body,
            searchInputComponent: SearchInputComponent,
            dataLength
        } = this.props;
        const { page, rowsPerPage, rowsPerPageOptions } = this.state;

        let tableHeaders = headers.length > 0 && headers.map((header, index) => (
            <TableCell key={index}>{header}</TableCell>
        ));

        // TODO: Enhance this component since i'm too lazy to this right now.
        // Basically what you need to do is loop the content and loop the objects.
        // const bodyRows =  body.length > 0
        //     ? body.map(item => (
        //         <TableRow>
        //             <TableCell>{item}</TableCell>
        //         </TableRow>
        //     ))
        //     : (
        //         <TableRow>
        //             <TableCell colSpan={headers.length}>There are no data available</TableCell>
        //         </TableRow>
        //     );

        return (
            <Paper style={{ overflowX: 'auto ' }}>
                <Toolbar>
                    <Grid container
                        alignItems='center'
                        direction='row'
                        justify='space-between'>
                        {SearchInputComponent}
                        <Grid item>
                            <IconButton disabled><CloudDownloadIcon /></IconButton>
                            <IconButton disabled><ViewColumnIcon /></IconButton>
                            <IconButton disabled><FilterListIcon /></IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
                <MaterialTable>
                    <TableHead>
                        <TableRow>
                            {tableHeaders}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {body}
                    </TableBody>
                </MaterialTable>
                <TablePagination
                    backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                    nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                    component="div"
                    count={dataLength}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                />
            </Paper>
        )
    }
}

export default Table;