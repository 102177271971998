import {
  RECREATE_TRANSACTION_SUCCESS,
  RECREATE_TRANSACTION_FAILED,
  UPDATE_HCP_DETAILS_SUCCESS,
  UPDATE_HCP_DETAILS_FAILED,
  UPDATE_PROMOTION_ALLOCATION_SUCCESS,
  UPDATE_PROMOTION_ALLOCATION_FAILED,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILED,
  VERIFY_HCP_SUCCESS,
  VERIFY_HCP_FAILED,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  ADD_HCP_SUCCESS,
  ADD_HCP_FAILED,
  VOID_TRANSACTION_SUCCESS,
  VOID_TRANSACTION_FAILED,
  VOID_REDEMPTION_TRANSACTION_SUCCESS,
  VOID_REDEMPTION_TRANSACTION_FAILED,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED
} from "../../constants/actions.contants";

const defaultState = {
    default: { status: '', message: '', result: '' }
}

const apiResponseReducer = (state = defaultState, action) => {
    switch(action.type) {
        case ADD_USER_SUCCESS:
        case RECREATE_TRANSACTION_SUCCESS:
        case UPDATE_HCP_DETAILS_SUCCESS:
        case UPDATE_PROMOTION_ALLOCATION_SUCCESS:
        case UPDATE_USER_STATUS_SUCCESS:
        case UPDATE_PASSWORD_SUCCESS:
        case VOID_TRANSACTION_SUCCESS:
        case VOID_REDEMPTION_TRANSACTION_SUCCESS:
        case VERIFY_HCP_SUCCESS: 
        case FORGOT_PASSWORD_SUCCESS:
        case ADD_HCP_SUCCESS:
            return {
                ...state,
                default: { ...action.payload }
            }
        case ADD_USER_FAILED:
        case UPDATE_HCP_DETAILS_FAILED:
        case RECREATE_TRANSACTION_FAILED:
        case UPDATE_PROMOTION_ALLOCATION_FAILED:
        case UPDATE_USER_STATUS_FAILED:
        case UPDATE_PASSWORD_FAILED:
        case VOID_TRANSACTION_FAILED:
        case VOID_REDEMPTION_TRANSACTION_FAILED:
        case VERIFY_HCP_FAILED:
        case FORGOT_PASSWORD_FAILED:
        case ADD_HCP_FAILED:
            return {
                ...state,
                default: { ...action.payload }
            }
        default:
            return state;
    }
}

export default apiResponseReducer;