// Constants
import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_UNAUTHORIZED,
  LOGOUT_SUCCESS
} from "../constants/actions.contants";
import * as Error from '../constants/error-messages';


export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
  payload: {
    isLoading: false,
    isLoggedIn: true
  }
});

export const loginFailed = ({ status, message, result }) => ({
  type: LOGIN_FAILED,
  payload: {
    status,
    message,
    result
  }
});

export const loginUnauthorized = () => ({
  type: LOGIN_UNAUTHORIZED,
  payload: {
    status: 401,
    message: "Failed",
    result: Error.UNAUTHORIZED_ACCESS
  }
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
  payload: {
    isLoading: false,
    isLoggedIn: false
  }
});
