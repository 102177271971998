import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';
import { SaveAlt as SaveAltIcon } from '@material-ui/icons';

// Actions
import * as paymentStore from '../mthp/actionCreators/payment.actionCreator';

// Utilities
import { ExportClientReport } from '../functions/ExportClientReport.utility';
import { dateTimeFormat } from '../utils';

class ExportClientReportButton extends Component {
    /**
     * @desc Patient Summary List - Table
     */
    paymentSummaryHeader = [
        'Payment Summary User ID', 'User Type', 'Email', 
        'Organization ID', 'Organization Branch', 
        'Payment Entity ID', 'GST Enabled', 
        'Rebate Amount (without GST)', 'GST Amount', 'Total Rebate',
        'Action Key', 'Action Status', 'Action Date'
    ];

    /**
     * @desc Transaction List - Table
     */
    transactionListHeader = [
        'Patient Created At', 'Patient Code', 'Patient ID', 
        'User Type', 'Organization ID', 'Organization Branch',
        'Payment Entity ID', 'GST Enabled',
        'Transaction ID', 
        'Product', 'Quantity',
        'Rebate Amount (without GST)', 'GST Amount', 'Total Rebate',
        'Transaction Date', 'Description'
    ];

    /**
     * @desc Transaction Summary - Table
     */
    transactionSummaryHeader = [
        'Payment Entity ID', 'Organization ID', 'Organization Name', 
        'Product', 'Number of Unique Patients', 'Number of Transactions',
        'GST Enabled',
        'Rebate Amount (without GST)', 'GST Amount', 'Total Rebate',
        'Description'
    ];

    onClick = async e => {
        const {
            beginDate, endDate, selectedMonth
        } = this.props;

        await this.props.exportClientPaymentReport({ beginDate, endDate });
        
        const paymentSummaryReport = this.props.exportPaymentSummaryList.map(({ action_date, ...data }) => ({
            ...data,
            action_date: dateTimeFormat(action_date, null, 'DD/MM/YYYY HH:mm:ss')
        }));

        const paymentTransactionList = this.props.exportPaymentTransactionList.map(({ patient_created_at, tax_enabled, transaction_created_at, ...data }) => ({
            ...data,
            patient_created_at: dateTimeFormat(patient_created_at, null, 'DD/MM/YYYY HH:mm:ss'),
            tax_enabled: tax_enabled === 1 ? 'Yes' : 'No',
            transaction_created_at: dateTimeFormat(transaction_created_at, null, 'DD/MM/YYYY HH:mm:ss')
        }));
        
        const data = {
            paymentSummaryReport: {
                header: this.paymentSummaryHeader,
                body: paymentSummaryReport
            },
            transactionList: {
                header: this.transactionListHeader,
                body: paymentTransactionList
            },
            transactionSummary: {
                header: this.transactionSummaryHeader,
                body: this.props.exportPaymentTransactionSummary
            }
        };

        return ExportClientReport(data, { selectedMonth });
    };

    render() {
        const { isLoading } = this.props;

        return (
            <Button size='small' variant='contained' color='primary'
                disabled={isLoading}
                onClick={this.onClick}>
                {
                    isLoading === true 
                    ? <CircularProgress size={16} thickness={5} />
                    : <SaveAltIcon />
                }
                Export Report
            </Button>
        )
    }
}

const mapStateToProps = ({ payment, ui }) => ({
    exportPaymentSummaryList: payment.exportPaymentSummaryList,
    exportPaymentTransactionList: payment.exportPaymentTransactionList,
    exportPaymentTransactionSummary: payment.exportPaymentTransactionSummary,
    isLoading: ui.exportClientPaymentReport.isLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...paymentStore
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ExportClientReportButton);
