// Constants
import {
  RETRIEVE_USER_SUCCESS,
  RETRIEVE_USER_FAILED,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILED,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED
} from "../constants/actions.contants";

export const addUserSuccess = ({ status, message, result }) => ({
    type: ADD_USER_SUCCESS,
    payload: { status, message, result }
});

export const addUserFailed = ({ status, message, result }) => ({
    type: ADD_USER_FAILED,
    payload: { status, message, result }
});

export const getUserListSuccess = ({ status, message, result }) => ({
    type: GET_USER_LIST_SUCCESS,
    payload: { status, message, result }
});

export const getUserListFailed = ({ status, message, result }) => ({
    type: GET_USER_LIST_FAILED,
    payload: { status, message, result }
});

export const retrieveUserSuccess = ({ currentUser, organizationData, userPromotionData }) => ({
    type: RETRIEVE_USER_SUCCESS,
    payload: {
        currentUser,
        organizationData,
        userPromotionData
    }
});

export const retrieveUserFailed = () => ({
    type: RETRIEVE_USER_FAILED
});

export const updateUserStatusSuccess = ({ status, message, result }) => ({
    type: UPDATE_USER_STATUS_SUCCESS,
    payload: { status, message, result }
});

export const updateUserStatusFailed = error => ({
    type: UPDATE_USER_STATUS_FAILED,
    payload: error
});


export const updateUserPasswordSuccess = ({ status, message, result }) => ({
    type: UPDATE_PASSWORD_SUCCESS,
    payload: { status, message, result }
});

export const updateUserPasswordFailed = ({ status, message, result }) => ({
    type: UPDATE_PASSWORD_FAILED,
    payload: { status, message, result }
});

export const forgotUserPasswordSuccess = ({ status, message, result }) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: { status, message, result }
});

export const forgotUserPasswordFailed = ({ status, message, result }) => ({
    type: FORGOT_PASSWORD_FAILED,
    payload: { status, message, result }
});