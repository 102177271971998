import { Button, withStyles } from '@material-ui/core';

export const VoidButton = withStyles({
    root: {
        backgroundColor: '#E53935',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#E53935', // TODO: Fix contrast
            color: '#fff'
        }
    }
})(Button);