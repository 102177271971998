import { RETRIEVE_UNVERIFIED_EMAIL_LIST_SUCCESS, RETRIEVE_UNVERIFIED_EMAIL_LIST_FAILED } from '../../constants/actions.contants';

const defaultState = {
    count: 0,
    list: [],
    pages: 0
};

const unverifiedEmailReducer = (state = defaultState, action) => {
    switch(action.type) {
        case RETRIEVE_UNVERIFIED_EMAIL_LIST_SUCCESS: {
            const { count, list, pages } = action.payload.result;

            return {
                ...state,
                count,
                list,
                pages
            }
        }
        case RETRIEVE_UNVERIFIED_EMAIL_LIST_FAILED:
        default:
            return {...state};
    }
}

export default unverifiedEmailReducer;