import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmail from 'validator/lib/isEmail';
import {
    Grid, Card, CardContent,
    FormControl, InputAdornment, IconButton, FormHelperText,
    Input, InputLabel, Typography,
    Button, CircularProgress
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {login} from '../actions/oauth.action';

// Actions
// TODO:

// Constants
import { 
    EMAIL_REQUIRED,
    EMAIL_INVALID_FORMAT,
    PASSWORD_REQUIRED,
    PASSWORD_MIN_LENGTH,
    PASSWORD_MAX_LENGTH 
} from '../../constants/error-messages';
import { STAYON_LOGO } from '../../constants/logo.constants';

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            error: {
                email: '',
                password: ''
            }, 
        }
    }

    onInputChange = ({ target: { name, value } }) => this.setState({ [name]: value.trim() });

    handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

    onSubmit = e => {
        e.preventDefault();
        const { dispatch, onSubmit } = this.props;
        const { email, password } = this.state;
        const error = { ...this.state.error };

        error['email'] = '';
        error['password'] = '';

        if (email.length === 0)
            error['email'] = EMAIL_REQUIRED;
        else if (!isEmail(email))
            error['email'] = EMAIL_INVALID_FORMAT;
        else if (password.length === 0)
            error['password'] = PASSWORD_REQUIRED;
        else if (password.length < 8)
            error['password'] = PASSWORD_MIN_LENGTH;
        else if (password.length > 16)
            error['password'] = PASSWORD_MAX_LENGTH;
        else {
            // TODO:
            dispatch(login({ email, password })).then(() => onSubmit());
        }
        
        this.setState({ error });
    }

    render() {
        const { isLoading, session } = this.props;
        const { email, password, error, showPassword } = this.state;

        return (
            <Grid container
                direction={'row'}
                alignItems={'center'}
                justify={'center'}
                style={{ background: '#8b1b3e', height: '100%' }}>
                <Grid item md={3} sm={6} xs={9}>
                    <Card>
                        <div style={{ padding: "50px 50px 0 50px" }}>
                            <img 
                                src={STAYON_LOGO}
                                alt='Stay On Logo' 
                                style={{ width: "100%" }} />
                        </div>
                        <CardContent>
                            <form onSubmit={this.onSubmit}>
                                <FormControl
                                    error={Boolean(error.email)}
                                    fullWidth={true}
                                    margin='dense'>
                                    <InputLabel htmlFor='email'>Email</InputLabel>
                                    <Input autoFocus
                                        type='text'
                                        name='email'
                                        value={email}
                                        onChange={this.onInputChange} />
                                    <FormHelperText>{error.email}</FormHelperText>
                                </FormControl>

                                <FormControl
                                    error={Boolean(error.password)}
                                    fullWidth={true}
                                    margin='dense'>
                                    <InputLabel htmlFor='password'>Password</InputLabel>
                                    <Input
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        value={password}
                                        onChange={this.onInputChange}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    onClick={this.handleClickShowPassword}>
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        } />
                                    <FormHelperText>{error.password}</FormHelperText>
                                </FormControl>

                                <Typography color='error' style={{ marginTop: '10px', textAlign: 'center' }}>
                                    {session}
                                </Typography>

                                <Grid container 
                                    direction='row' 
                                    alignItems='center' 
                                    justify='flex-end' 
                                    style={{marginTop: 12}}>
                                    <Button
                                        color='primary'
                                        disabled={isLoading}
                                        type='submit'
                                        variant='contained'>
                                        {isLoading === true && <CircularProgress size={16} thickness={5} />}
                                        Login
                                    </Button>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    isLoading: state.ui && state.ui.login.isLoading,
    session: state.session && state.session.result
});

export default connect(mapStateToProps)(LoginForm);