import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import * as transactionStore from '../../actions/transaction.action';

// Custom Components
import Table from '../../components/Table3';

// Utilities
import { projectId, promotionId } from '../../utils';
import { transactionMapper } from '../../utils/transaction.utils';

class TransactionListPage extends Component {
    state = {
        currentPage: 0,
        rowsPerPage: 10,
        transactionList: [],
    }

    async componentDidMount() {
        await this.props.getTransactions({
            projectId: projectId(this.props),
            promotionId: promotionId(this.props),
            page: this.state.currentPage,
            limit: this.state.rowsPerPage
        });

        this.setState({
            transactionList: this.props.transactionList
        })
    }
    render() {
        const { transactionCount, transactionList } = this.props;
        const { currentPage } = this.state;
        
        const transactionListHeaders = ['Transaction Batch ID',  'Transaction Date', 'Patient Code', 'Products', 'View Transaction'];
        const transactionListBody = {
            data: transactionMapper(transactionList),
            key: 'transaction_batch_id',
            accessors: [
                'transaction_batch_id',
                'created_at',
                'promo_code',
                'products'
            ]
        }

        return (
            <Fragment>
                <Table
                    headers={transactionListHeaders}
                    body={transactionListBody}
                    hasPagination={true}
                    page={currentPage}
                    dataLength={transactionCount}
                    // onChangePage={onChangePage}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = ({ apiResponse, transaction }) => ({
    apiResponse: apiResponse && apiResponse.default,
    transactionCount: transaction.count,
    transactionList: transaction.list
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...transactionStore
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TransactionListPage);