// Constants
import {
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAILED,
  RECREATE_TRANSACTION_SUCCESS,
  RECREATE_TRANSACTION_FAILED,
  VOID_TRANSACTION_SUCCESS,
  VOID_TRANSACTION_FAILED
} from "../constants/actions.contants";

export const getTransactionsSuccess = ({ status, message, result }) => ({
  type: GET_TRANSACTIONS_SUCCESS,
  payload: { status, message, result }
});

export const getTransactionsFailed = ({ status, message, result }) => ({
  type: GET_TRANSACTIONS_FAILED,
  payload: { status, message, result }
});

export const recreateTransactionSuccess = ({ status, message, result }) => ({
  type: RECREATE_TRANSACTION_SUCCESS,
  payload: { status, message, result }
});

export const recreateTransactionFailed = ({ status, message, result }) => ({
  type: RECREATE_TRANSACTION_FAILED,
  payload: { status, message, result }
});

export const voidTransactionSuccess = ({ status, message, result }) => ({
  type: VOID_TRANSACTION_SUCCESS,
  payload: { status, message, result }
});

export const voidTransactionFailed = ({ status, message, result }) => ({
  type: VOID_TRANSACTION_FAILED,
  payload: { status, message, result }
});
