import { dateTimeFormat } from '../../utils';
import { GET_MESSAGE_LOGS_SUCCESS, GET_MESSAGE_LOGS_FAILED } from '../../constants/actions.contants';

const defaultState = {
    smsCount: 0,
    smsList: [],
    smsPages: 0
};

const messageReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GET_MESSAGE_LOGS_SUCCESS:
            const { count, list, pages } = action.payload.result;
            
            const modifiedList = list.map(({ message_log_created_at, ...data }) => ({
                ...data,
                message_log_created_at: dateTimeFormat(message_log_created_at)
            }));
            
            return {
                ...state,
                smsCount: count,
                smsList: [...modifiedList],
                smsPages: pages
            }
        case GET_MESSAGE_LOGS_FAILED: // TODO: Test this sceneario
            return {
                ...state,
                smsList: {...action.payload}
            }
        default:
            return {...state};
    }
}

export default messageReducer;