import React, { Component, Fragment } from 'react';
import { Grid, FormControl } from '@material-ui/core';
import ClientPaymentReport from '../../containers/ClientPaymentReport.container';
import FinancePaymentReport from '../../containers/FinancePaymentReport.container';
import queryString from "query-string";

/**
 * Custom Components
 */
import { Select } from '../../components';

class PaymentPage extends Component {
    state = {
        selectedReport: { label: 'Client Monthly Payment Reports', value: 'client_monthly_payment_reports' },
        previousSearch: "" 
    }

    async componentDidUpdate(prevProps) {
        if (this.state.previousSearch !== prevProps.history.location.search) {
          this.setState({ previousSearch: prevProps.history.location.search });
        }
    }

    onSelectChange = (selected, props) => {
        this.setState({ selectedReport: selected });
    }
 
    render() {
        const { selectedReport } = this.state;

        const availableReports = [
            { label: 'Client Monthly Payment Reports', value: 'client_monthly_payment_reports' },
            { label: 'Finance Monthly Payment Reports', value: 'finance_monthly_payment_reports' },
        ];
        
        return (
            <Fragment>
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <h4>Payments</h4>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <FormControl fullWidth
                            margin='dense'>
                            <Select isSearchable
                                isLoading={availableReports.length === 0}
                                onChange={this.onSelectChange}
                                options={availableReports}
                                placeholder='Select Report'
                                value={selectedReport}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        {selectedReport.value === "client_monthly_payment_reports" && <ClientPaymentReport previousSearch={this.state.previousSearch}/> }
                        {selectedReport.value === "finance_monthly_payment_reports" && <FinancePaymentReport previousSearch={this.state.previousSearch}/> }
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}
export default PaymentPage;