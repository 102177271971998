import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Grid, IconButton, LinearProgress
} from '@material-ui/core';
import queryString from 'query-string';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';

// Actions
import * as promotionStore from '../actionCreators/promotion.actionCreator';

// Components
import Table from '../../components/ReactTable';

//Utilities
import { dateTimeFormat } from '../../utils';


class ConfigurationPage extends Component {
    state = {
        currentPage: 1,
        rowsPerPage: 10,
        searchInput: "",
        previousSearch: ""
    };

    async componentDidMount() {
        await this._loadData();
    }

    async componentDidUpdate(){
        if (this.state.previousSearch !== this.props.history.location.search) {
            this.setState({ previousSearch: this.props.history.location.search });
            await this._loadData();
        }
    }

    _loadData = async () => {
        const { pathname: currentPath } = this.props.history.location;
        let params = queryString.parse(this.props.location.search) || {};
        const { searchInput } = this.state;
    
        this.setState({ searchInput: params.search ? params.search : '' }); 
    
        await this.props.getPromotionList({ ...params });
    
        if (params.page <= this.props.promotionList && params.page > 0) {
          this.setState({ currentPage: params.page });
        } else {
          this.props.history.push(`${currentPath}?page=1${searchInput && '&search='+searchInput}`);
        }
      };

      _onInputSearch = e => this.setState({ searchInput: e.target.value });

      _onSubmitSearch = e => {
        e.preventDefault();
        const { pathname: currentPath } = this.props.history.location;
        const { searchInput } = this.state;
    
        this.props.history.push(`${currentPath}?page=1${searchInput && '&search='+searchInput}`);
      };
    

    onSelectPromotion = id => this.props.history.push(`${this.props.match.path}/${id}`)

    render() {
        const { promotionList, promotionCount, ui } = this.props;
        const {currentPage} = this.state;

        const columns = [
            { Header: "ID", accessor: "id" },
            { Header: "Name", accessor: "name" },
            { Header: "Code", accessor: "code" },
            { Header: "Promotion Type", accessor: "promotion_type" },
            { 
                Header: "Begin At", 
                accessor: "begin_at", 
                Cell: props => (
                    <span>{dateTimeFormat(props.original.begin_at)}</span>
                )
            },
            { 
                Header: "End At", 
                accessor: "end_at", 
                Cell: props => (
                    <span>{dateTimeFormat(props.original.end_at)}</span>
                )
            },
            { 
                Header: "View Promotion",
                Cell: props => (
                    <IconButton size='small' color='primary'
                    onClick={() => this.onSelectPromotion(props.original.id)}>
                    <ArrowForwardIcon />
                </IconButton>
                )  
            }
        ];


        return (
            <Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        <h4>Promotions</h4>
                    </Grid>
                    <Grid item xs={12}>
                        {ui.getPromotionList.isLoading ? (
                            <LinearProgress />
                        ) : (
                            <Table
                                columns={columns}
                                count={promotionCount}
                                currentPage={currentPage}
                                data={promotionList}
                                search={{
                                    onChange: e => this._onInputSearch(e),
                                    onSubmit: e => this._onSubmitSearch(e),
                                    value: this.state.searchInput
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ promotion, ui }) => ({
    promotionCount: promotion.promotionCount,
    promotionList: promotion.promotionList,
    ui
})

const mapDispatchToProps = dispatch => bindActionCreators({
    ...promotionStore
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationPage);