import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { detect } from 'detect-browser';
import { Link } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import {
    Grid, Card, CardContent,
    FormControl, InputAdornment, IconButton, FormHelperText,
    Input, InputLabel, Typography,
    Button, CircularProgress,
    Snackbar as SnackBar
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

// Actions
import { login } from '../../actionCreators/session.actionCreator';

// Constants
import { 
    EMAIL_REQUIRED,
    EMAIL_INVALID_FORMAT,
    PASSWORD_REQUIRED,
    PASSWORD_MIN_LENGTH,
    PASSWORD_MAX_LENGTH 
} from '../../../constants/error-messages';
import { MYTOTALHEALTH_LOGO } from '../../../constants/logo.constants';

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            error: {
                email: '',
                password: ''
            },
            isSnackBarOpen: false,
            snackBarMessage: ''
        }
    }

    // TODO: Implement this.
    // componentDidMount() {
    //     const { name /*, version, os */ } = detect();
    //     if (name === 'ie')
    //         this.setState({ 
    //             isSnackBarOpen: true, 
    //             snackBarMessage: `Your browser is not supported. To use MyTotalHealth, we recommend using the latest version of Chrome, Firefox, or Safari.`
    //         });
    // }

    onInputChange = ({ target: { name, value } }) => this.setState({ [name]: value.trim() });   
    
    handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

    onSubmit = e => {
        e.preventDefault();
        const { dispatch, onSubmit } = this.props;
        const { email, password } = this.state;
        const error = { ...this.state.error };

        error['email'] = '';
        error['password'] = '';

        if (email.length === 0)
            error['email'] = EMAIL_REQUIRED;
        else if (!isEmail(email))
            error['email'] = EMAIL_INVALID_FORMAT;
        else if (password.length === 0)
            error['password'] = PASSWORD_REQUIRED;
        else if (password.length < 8)
            error['password'] = PASSWORD_MIN_LENGTH;
        else if (password.length > 16)
            error['password'] = PASSWORD_MAX_LENGTH;
        else
            dispatch(login({ email, password })).then(() => onSubmit());

        this.setState({ error });
    }

    render() {
        const { isLoading, session } = this.props;
        const { email, password, error, showPassword, isSnackBarOpen, snackBarMessage } = this.state;

        return (
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justify={"center"}
            style={{ background: "#00539B", height: "100%" }}
          >
            <Grid item md={3} sm={6} xs={9}>
              <Card>
                <div style={{ padding: "50px 50px 0 50px" }}>
                  <img
                    src={MYTOTALHEALTH_LOGO}
                    alt="MyTotalHealth Logo"
                    style={{ width: "100%" }}
                  />
                </div>
                <CardContent>
                  <form onSubmit={this.onSubmit}>
                    <FormControl
                      error={Boolean(error.email)}
                      fullWidth={true}
                      margin="dense"
                    >
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <Input
                        autoFocus
                        type="text"
                        name="email"
                        value={email}
                        onChange={this.onInputChange}
                      />
                      <FormHelperText>{error.email}</FormHelperText>
                    </FormControl>

                    <FormControl
                      error={Boolean(error.password)}
                      fullWidth={true}
                      margin="dense"
                    >
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={this.onInputChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={this.handleClickShowPassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText>{error.password}</FormHelperText>
                    </FormControl>

                    <Typography
                      color="error"
                      style={{ marginTop: "10px", textAlign: "center" }}
                    >
                      {session}
                    </Typography>

                    <Grid
                      container
                      direction="row"
                      alignItems="right"
                      justify="space-between"
                      style={{ marginTop: 12 }}
                    >
                      <Link
                        type="normal"
                        to="/mthp/forgot-password"
                        style={{
                          textDecoration: "none",
                          fontSize: "13px",
                          color: "color: #00539b",
                          marginTop: 12
                        }}
                      >
                        Forgot Password
                      </Link>
                      <Button
                        color="primary"
                        disabled={isLoading}
                        type="submit"
                        variant="contained"
                      >
                        {isLoading === true && (
                          <CircularProgress size={16} thickness={5} />
                        )}
                        Login
                      </Button>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
            <SnackBar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              isOpen={isSnackBarOpen}
              variant="warning"
              message={snackBarMessage}
            />
          </Grid>
        );
    }
}

const mapStateToProps = state => ({
    isLoading: state.ui && state.ui.login.isLoading,
    session: state.session && state.session.result
});

export default connect(mapStateToProps)(LoginForm);