import {
    LOGIN_SUCCESS, LOGIN_FAILED, LOGIN_UNAUTHORIZED,
    LOGOUT_SUCCESS
} from '../../constants/actions.contants';

const defaultState = {
    isLoading: false,
    isLoggedIn: false
}

const sessionReducer = (state = defaultState, action) => {

    switch (action.type) {
        case LOGIN_SUCCESS:
            return { ...state, ...action.payload }
        case LOGIN_FAILED:
            return { ...state, ...action.payload }
        case LOGIN_UNAUTHORIZED:
            return { ...state, ...action.payload }
        case LOGOUT_SUCCESS:
            return { ...state, ...action.payload }
        default:
            return state;
    }
};

export default sessionReducer;