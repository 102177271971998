import React, { Component } from "react";
import { Grid, Toolbar } from "@material-ui/core";

// Custom Compnents
import Search from "./Search.Input";

class TableToolBar extends Component {
  render() {
    return (
      <Toolbar>
        <Grid>
          {this.props.search && (
            <Search
              onChange={this.props.search.onChange}
              onSubmit={this.props.search.onSubmit}
              value={this.props.search.value}
            />
          )}
        </Grid>
      </Toolbar>
    );
  }
}

export default TableToolBar;
