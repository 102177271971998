const BASE = process.env.REACT_APP_BASE_API_URL_MYCARE;
const SERVICE_PATH = '/admin';
const HCPS = 'hcps';
const MESSAGES = 'messages';
const PATIENTS = 'patients';
const PAYMENTS = 'payments';
const PRODUCTS = 'products';
const PROMOTIONS = 'promotions';
const TRANSACTIONS = 'transactions';
const USERS = 'users';

// Healthcare Professionals
export const GET_HCP_DETAILS = userId => `${BASE}/mycare${SERVICE_PATH}/${HCPS}/${userId}`;
export const GET_HCP_LIST = `${BASE}/mycare${SERVICE_PATH}/${HCPS}`;
export const GET_HCP_NUMBER_OF_TRANSACTIONS = userId => `${BASE}/mycare${SERVICE_PATH}/${HCPS}/number-of-transactions/${userId}`;
export const GET_HCP_PATIENT_LIST = userId => `${BASE}/mycare${SERVICE_PATH}/${HCPS}/${PATIENTS}/${userId}`;
export const GET_HCP_TRANSACTIONS = userId => `${BASE}/mycare${SERVICE_PATH}/${HCPS}/transactions/${userId}`;
export const UPDATE_HCP_DETAILS = userId => `${BASE}/mycare${SERVICE_PATH}/${HCPS}/${userId}`;
export const PUT_VERIFY_HCP = userId => `${BASE}/mycare${SERVICE_PATH}/${HCPS}/verify/${userId}`;
export const UPDATE_USER_STATUS = userId => `${BASE}/mycare${SERVICE_PATH}/hcps/update-status/${userId ? userId : USERS}`;

// Messages
export const GET_MESSAGE_LOGS = `${BASE}/${SERVICE_PATH}/${MESSAGES}/get-message-logs`;

// Patients
export const GET_PATIENT_LIST = `${BASE}/${SERVICE_PATH}/${PATIENTS}`;
export const GET_PATIENT_PROGRAMME_CODES = patientId => `${BASE}/${SERVICE_PATH}/${PATIENTS}/${patientId}/codes`;
export const GET_PATIENT_TRANSACTIONS = patientId => `${BASE}/${SERVICE_PATH}/${PATIENTS}/${patientId}/transactions`;
export const GET_PATIENT_REGISTRATION_HISTORY = patientId => `${BASE}/${SERVICE_PATH}/${PATIENTS}/${patientId}/registration-history`;

// Payments
export const EXPORT_CLIENT_PAYMENT_REPORT = `${BASE}/${SERVICE_PATH}/${PAYMENTS}/export-client-report`;
export const GET_CLIENT_PAYMENT_SUMMARY_LIST = `${BASE}/${SERVICE_PATH}/${PAYMENTS}/payment-summary/client`;
export const GET_CLIENT_PAYMENT_TRANSACTION_DETAILS = `${BASE}/${SERVICE_PATH}/${PAYMENTS}/transaction-details/client`;
export const GET_FINANCE_PAYMENT_SUMMARY_LIST = `${BASE}/${SERVICE_PATH}/${PAYMENTS}/payment-summary/finance`;
export const GET_FINANCE_PAYMENT_TRANSACTION_DETAILS = `${BASE}/${SERVICE_PATH}/${PAYMENTS}/transaction-details/finance`;
// Promotions
export const GET_PROMOTION_DETAILS = promotionId => `${BASE}/${SERVICE_PATH}/${PROMOTIONS}/${promotionId}`;
export const GET_PROMOTION_LIST = `${BASE}/${SERVICE_PATH}/${PROMOTIONS}`;

// Products
export const GET_PRODUCT_LIST = `${BASE}/${SERVICE_PATH}/${PRODUCTS}/list`;

// Transactions
export const GET_TRANSACTIONS = `${BASE}/${SERVICE_PATH}/${TRANSACTIONS}/list`;
export const RECREATE_TRANSACTION = `${BASE}/${SERVICE_PATH}/${TRANSACTIONS}/add`;
export const UPDATE_PROMOTION_ALLOCATION = promotionId => `${BASE}/${SERVICE_PATH}/${PROMOTIONS}/${promotionId}/update-allocation`;
export const VOID_TRANSACTION = `${BASE}/mycare${SERVICE_PATH}/${TRANSACTIONS}`;
export const VOID_REDEMPTION_TRANSACTION = `${BASE}/mycare${SERVICE_PATH}/${TRANSACTIONS}/redeem`

// Users
export const GET_USER_LIST = `${BASE}/${SERVICE_PATH}/${USERS}/list`;