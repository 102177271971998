import HomePage from './home.page';
import LoginPage from './login.page';
import MainPage from './main.page';
import HcpListPage from './hcp.list.page';
import HcpDetailPage from './hcp.details.page';
export default {
    HomePage,
    LoginPage,
    MainPage,
    HcpListPage,
    HcpDetailPage
}