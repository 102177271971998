import { dateTimeFormat } from '../utils';

export const transactionMapper = transactionList => {
    /**
     * @desc Lit of Transactions - Logic
     */
    return transactionList.map(({ transaction_campaigns, created_at, ...others }) => {
        const products = transaction_campaigns.reduce((accumulator, currentValue) => {
            accumulator.push(`${currentValue.product_full_name} ${currentValue.campaign_product_quantity_packaging}`);

            return accumulator;
        }, []).sort().join(', ');

        return {
            ...others,
            products,
            created_at: dateTimeFormat(created_at)
        }
    });
}