import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

/**
 * Export Client Report creates an Excel file containing the Payment Summary, Transaction List, and Transaction Report.
 * @param {array} data - A collection of data that will be inserted in the Excel file.
 * @param {object} options - Any related configurations that are not included in the data parameter.
 */
export const ExportClientReport = (data, options) => {
    const fileName = `(${options.selectedMonth} Client) Payment Summary, Transaction Summary and Report`;
    const { paymentSummaryReport, transactionList, transactionSummary } = data;
    const newWorkBook = XLSX.utils.book_new();
    newWorkBook.Props = {
        Title: fileName,
        Subject: fileName,
        Author: 'IQVIA Solutions Asia Pte. Ltd.'
    };

    // PREPARE Payment Summary Data.
    let paymentSummaryData = paymentSummaryReport.body.reduce((accumulator, iterator) => {
        accumulator.push([
            iterator['payment_summary_user_id'],
            iterator['user_type'],
            iterator['email'],
            iterator['organization_id'],
            iterator['organization_branch'],
            iterator['payment_entity_id'],
            iterator['tax_enabled'],
            iterator['rebate_amount'],
            iterator['tax_amount'],
            iterator['total_rebate_amount'],
            iterator['action_key'],
            iterator['action_status'],
            iterator['action_date']
        ]);

        return accumulator;
    }, []);

    // PREPARE Transaction Report Data.
    let transactionReportData = transactionList.body.reduce((accumulator, iterator) => {
        accumulator.push([
            iterator['patient_created_at'],
            iterator['patient_code'],
            iterator['patient_id'],
            iterator['user_type'],
            iterator['organization_id'],
            iterator['organization_branch'],
            iterator['payment_entity_id'],
            iterator['tax_enabled'],
            iterator['transaction_campaign_id'],
            iterator['product'],
            iterator['quantity_display'],
            iterator['transaction_discount_amount'],
            iterator['tax_amount'],
            iterator['total_discount_amount'],
            iterator['transaction_created_at'],
            iterator['description']
        ]);

        return accumulator;
    }, []);

    // PREPARE Transaction Summary Data.
    let transactionSummaryData = transactionSummary.body.reduce((accumulator, iterator) => {
        accumulator.push([
            iterator['payment_entity_id'],
            iterator['organization_id'],
            iterator['organization_branch'],
            iterator['product'],
            iterator['patient_count'],
            iterator['transaction_count'],
            iterator['tax_enabled'],
            iterator['transaction_discount_amount'],
            iterator['tax_amount'],
            iterator['total_discount_amount'],
            iterator['description']
        ]);

        return accumulator;
    }, []);

    // ADD Headers
    paymentSummaryData.unshift(paymentSummaryReport.header);
    transactionReportData.unshift(transactionList.header);
    transactionSummaryData.unshift(transactionSummary.header);

    newWorkBook.SheetNames.push('Payment Summary Report');
    newWorkBook.SheetNames.push('Transaction Report');
    newWorkBook.SheetNames.push('Transaction Summary');

    // APPEND data to sheets.
    const paymentSummarySheet = XLSX.utils.aoa_to_sheet(paymentSummaryData);
    const transactionListSheet = XLSX.utils.aoa_to_sheet(transactionReportData);
    const transactionSummarySheet = XLSX.utils.aoa_to_sheet(transactionSummaryData);

    // APPEND sheet to file.
    newWorkBook.Sheets['Payment Summary Report'] = paymentSummarySheet;
    newWorkBook.Sheets['Transaction Report'] = transactionListSheet;
    newWorkBook.Sheets['Transaction Summary'] = transactionSummarySheet;

    const workBookOut = XLSX.write(newWorkBook, { bookType: 'xlsx', type: 'array' });
    
    return saveAs(new Blob([workBookOut], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
}