// Actions
import * as PaymentAction from "../../actions/payment.action";
import { apiRequestStart, apiRequestEnd } from "../../actions/ui.action";

// Endpoints
import {
  EXPORT_CLIENT_PAYMENT_REPORT,
  GET_CLIENT_PAYMENT_SUMMARY_LIST,
  GET_CLIENT_PAYMENT_TRANSACTION_DETAILS,
  GET_FINANCE_PAYMENT_SUMMARY_LIST,
  GET_FINANCE_PAYMENT_TRANSACTION_DETAILS
} from "../endpoints/mthp.endpoints";

// Utilities
import { headers } from "../utilities/headers.utility";
import { Request } from "../../utils";

export function exportClientPaymentReport({ beginDate, endDate }) {
  return async dispatch => {
    const trigger = "exportClientPaymentReport";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "POST",
          url: EXPORT_CLIENT_PAYMENT_REPORT,
          data: {
            begin_date: beginDate,
            end_date: endDate
          }
        },
        headers()
      );
      dispatch(PaymentAction.exportClientPaymentReportSuccess(data));
    } catch (error) {
      dispatch(
        PaymentAction.exportClientPaymentReportFailed(error.response.data)
      );
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

/**
 * Retrieves the Client's Payment Summary List
 * @param {string} beginDate - only accepts a valid 'moment' date and time
 * @param {string} endDate - only accepts a valid 'moment' date and time
 */
export function getClientPaymentSummaryList({ search, page = 1, limit = 10, beginDate, endDate } = {}) {
  return async dispatch => {
    const trigger = "getClientPaymentSummaryList";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_CLIENT_PAYMENT_SUMMARY_LIST,
          params: {
            search,
            page,
            limit,
            begin_date: beginDate,
            end_date: endDate
          }
        },
        headers()
      );
      dispatch(PaymentAction.getClientPaymentSummaryListSuccess(data));
    } catch (error) {
      dispatch(
        PaymentAction.getClientPaymentSummaryListFailed(error.response.data)
      );
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

/**
 * Retrieves the Client's Payment Transaction List
 * @param {string} beginDate - only accepts a valid 'moment' date and time
 * @param {string} endDate - only accepts a valid 'moment' date and time
 */
export function getClientPaymentTransactionDetails({search, page = 1, limit = 10, beginDate, endDate} = {}) {
  return async dispatch => {
    const trigger = "getClientPaymentTransactionDetails";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_CLIENT_PAYMENT_TRANSACTION_DETAILS,
          params: {
            search,
            page,
            limit,
            begin_date: beginDate,
            end_date: endDate,
          }
        },
        headers()
      );
      dispatch(PaymentAction.getClientTransactionDetailsSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(
          PaymentAction.getClientTransactionDetailsFailed(error.response.data)
        );
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

/**
 * Retrieves the Finance Department's Payment Summary List
 * @param {string} beginDate - only accepts a valid 'moment' date and time
 * @param {string} endDate - only accepts a valid 'moment' date and time
 */
export function getFinancePaymentSummaryList({ search, page = 1, limit = 10, beginDate, endDate } = {}) {
  return async dispatch => {
    const trigger = "getFinancePaymentSummaryList";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_FINANCE_PAYMENT_SUMMARY_LIST,
          params: {
            search,
            page,
            limit,
            begin_date: beginDate,
            end_date: endDate
          }
        },
        headers()
      );
      dispatch(PaymentAction.getFinancePaymentSummaryListSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(
          PaymentAction.getFinancePaymentSummaryListFailed(error.response.data)
        );
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

/**
 * Retrieves the Finance Department's Transaction List
 * @param {string} beginDate - only accepts a valid 'moment' date and time
 * @param {string} endDate - only accepts a valid 'moment' date and time
 */
export function getFinancePaymentTransactionDetails({search, page = 1, limit = 10, beginDate, endDate} = {}) {
  return async dispatch => {
    const trigger = "getFinancePaymentTransactionDetails";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_FINANCE_PAYMENT_TRANSACTION_DETAILS,
          params: {
            search,
            page,
            limit,
            begin_date: beginDate,
            end_date: endDate,
          }
        },
        headers()
      );
      dispatch(PaymentAction.getFinanceTransactionDetailsSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(
          PaymentAction.getFinanceTransactionDetailsFailed(error.response.data)
        );
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}
