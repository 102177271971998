import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';

// Material UI - Core
import {
    MenuList, 
    ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';

// Material UI - Icons
import {
    ExitToApp as ExitToAppIcon,
    FolderSpecial as FolderSpecialIcon,
    Home as HomeIcon,
    Info as InfoIcon,
    ListAlt as ListAltIcon,
    LocalHospital as LocalHospitalIcon,
    Message as MessageIcon,
    Payment as PaymentIcon,
    People as PeopleIcon,
    Person as PersonIcon
} from '@material-ui/icons'

// Actions
import { logout } from '../actionCreators/session.actionCreator';

// Custom Components
import NavList from '../../common/components/NavList.component';

// Styled Components
const CustomMenuList = styled(MenuList)`
    .is-active {
        background-color: #ebebeb;
        font-weight: bold;
    }
`;

const NavItems = [
    { label: 'Home', path: '/mthp', icon: <HomeIcon /> },
    { label: 'Admin/Dashboard Users', path: '/mthp/users', icon: <PersonIcon /> },
    { label: 'Transactions', path: '/mthp/transactions', icon: <ListAltIcon /> },
    { label: 'Patients', path: '/mthp/patients', icon: <PeopleIcon /> },
    { label: 'HCPs', path: '/mthp/hcps', icon: <LocalHospitalIcon /> },
    { label: 'Promotions', path: '/mthp/promotions', icon: <FolderSpecialIcon /> },
    { label: 'Payments', path: '/mthp/payments', icon: <PaymentIcon /> },
    { label: 'Messages', path: '/mthp/messages', icon: <MessageIcon /> },
    { label: 'About', path: '/mthp/about', icon: <InfoIcon /> }
];

const Nav = ({ dispatch }) => (
    <CustomMenuList>
        <NavList items={NavItems} />
        <ListItem button component={NavLink} to='/' onClick={() => dispatch(logout())}>
            <ListItemIcon>
                <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary='Logout' />
        </ListItem>
    </CustomMenuList>
);

export default withRouter(connect()(Nav));