import { dateTimeFormat } from '../../utils';

import {
    EXPORT_CLIENT_PAYMENT_REPORT_SUCCESS,
    EXPORT_CLIENT_PAYMENT_REPORT_FAILED,
    GET_CLIENT_PAYMENT_SUMMARY_LIST_SUCCESS,
    GET_CLIENT_PAYMENT_SUMMARY_LIST_FAILED,
    GET_CLIENT_TRANSACTION_DETAILS_SUCCESS,
    GET_CLIENT_TRANSACTION_DETAILS_FAILED,
    GET_FINANCE_PAYMENT_SUMMARY_LIST_SUCCESS,
    GET_FINANCE_PAYMENT_SUMMARY_LIST_FAILED,
    GET_FINANCE_TRANSACTION_DETAILS_SUCCESS,
    GET_FINANCE_TRANSACTION_DETAILS_FAILED,
} from '../../constants/actions.contants';

const defaultState = {
    exportPaymentSummaryList: [],
    exportPaymentTransactionList: [],
    exportPaymentTransactionSummary: [],
    clientPaymentSummaryList: [],
    financePaymentSummaryList: [],
    transactionCount: 0,
    transactionPages: 0,
    transactionList: [],
    transactionSummaryCount: 0,
    transactionSummaryPages: 0,
    transactionSummary: [],
};

const paymentReducer = (state = defaultState, action) => {
    switch(action.type) {
        case EXPORT_CLIENT_PAYMENT_REPORT_SUCCESS: {
            const { paymentSummary, transactionList, transactionSummary } = action.payload.result;
            return {
                ...state,
                exportPaymentSummaryList: paymentSummary,
                exportPaymentTransactionList: transactionList,
                exportPaymentTransactionSummary: transactionSummary
            }
        }
        case GET_CLIENT_PAYMENT_SUMMARY_LIST_SUCCESS:

            let { clientPaymentSummaryList, clientPaymentSummaryCount, clientPaymentSummaryPages } = action.payload.result

            clientPaymentSummaryList = clientPaymentSummaryList.map(({ action_date, ...data }) => {
                return {
                    ...data,
                    action_date: dateTimeFormat(action_date)
                }
            });

            return {
                ...state,
                clientPaymentSummaryList,
                clientPaymentSummaryCount,
                clientPaymentSummaryPages
            }
        case GET_CLIENT_TRANSACTION_DETAILS_SUCCESS: {
                const {
                    transactionCount, transactionList, transactionPages,
                    transactionSummaryCount, transactionSummary, transactionSummaryPages
                } = action.payload.result;
                
                const modifiedTransactionList = transactionList.map(({ tax_enabled, transaction_created_at, patient_created_at, ...data }) => ({
                    ...data,
                    tax_enabled: tax_enabled === 1 ? 'Yes' : 'No',
                    transaction_created_at: dateTimeFormat(transaction_created_at),
                    patient_created_at: dateTimeFormat(patient_created_at)
                }));
    
                const modifiedTransactionSummary = transactionSummary.map(({ tax_enabled, organization_id, product_id, ...data }) => ({
                    ...data,
                    id: `${organization_id}_${product_id}`,
                    organization_id,
                    tax_enabled: tax_enabled === 1 ? 'Yes' : 'No'
                }));
    
                return {
                    ...state,
                    transactionCount,
                    transactionPages,
                    transactionList: modifiedTransactionList,
                    transactionSummaryCount,
                    transactionSummaryPages,
                    transactionSummary: modifiedTransactionSummary
                }
            }
        case GET_FINANCE_PAYMENT_SUMMARY_LIST_SUCCESS:

            let { financePaymentSummaryList, financePaymentSummaryCount, financePaymentSummaryPages } = action.payload.result

            financePaymentSummaryList = financePaymentSummaryList.map(({ action_date, tax_enabled, ...data }) => {
                return {
                    ...data,
                    tax_enabled: tax_enabled === 1 ? 'Yes' : 'No',
                    action_date: dateTimeFormat(action_date)
                }
            });

            return {
                ...state,
                financePaymentSummaryList,
                financePaymentSummaryCount,
                financePaymentSummaryPages
            }
        case GET_FINANCE_TRANSACTION_DETAILS_SUCCESS:
            const { transactionCount, transactionList, transactionPages } = action.payload.result;

            const modifiedTransactionList = transactionList.map(({ tax_enabled, transaction_created_at, patient_created_at, ...data }) => ({
                ...data,
                tax_enabled: tax_enabled === 1 ? 'Yes' : 'No',
                transaction_created_at: dateTimeFormat(transaction_created_at),
                patient_created_at: dateTimeFormat(patient_created_at)
            }));

            return {
                ...state,
                transactionCount,
                transactionList: modifiedTransactionList,
                transactionPages
            }
        case EXPORT_CLIENT_PAYMENT_REPORT_FAILED: // TODO: Test this scenario.
        case GET_CLIENT_PAYMENT_SUMMARY_LIST_FAILED: // TODO: Test this scenario.
        case GET_CLIENT_TRANSACTION_DETAILS_FAILED: // TODO: Test this scenario.
        case GET_FINANCE_PAYMENT_SUMMARY_LIST_FAILED: // TODO: Test this scenario.
        case GET_FINANCE_TRANSACTION_DETAILS_FAILED: // TODO: Test this scenario.
            return {
                ...state
            }
        default:
            return {...state};
    }
}

export default paymentReducer;