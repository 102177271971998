import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import store from 'store';

// Pages
import MyCare from '../../mycare/pages/index.page';

class RequireAuthentication extends Component {

    render() {
        const {
            component,
            path
        } = this.props;
        
        return store.get('user-mycare')
            ? (
                <MyCare.MainPage>
                    <Route path={path} component={component} />
                </MyCare.MainPage>
            )
            :  <Route path={path} component={MyCare.LoginPage} exact /> 
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.session && state.session.isLoggedIn
});

export default connect(mapStateToProps)(RequireAuthentication);