import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import LoginForm from '../components/forms/Login.form.component';

const LoginPage = ({ history }) =>
  localStorage.getItem(
    `${process.env.REACT_APP_WARP_API_URL}:x-warp-session-token`
  ) &&
  localStorage.getItem(
    `${process.env.REACT_APP_WARP_API_URL}:x-warp-user-current`
  ) ?
   <Redirect to= '/mthp' />
   : (
    <LoginForm onSubmit={() => history.push("/mthp")} />
  );

export default withRouter(LoginPage);