import React from 'react';
import {
    Dialog as MaterialDialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';

const Dialog = ({ open, onClose, scroll, title, subtitle, content, actions, ...props }) => (
    <MaterialDialog maxWidth='lg' open={open} onClose={onClose} scroll={scroll} {...props}>
        {title ? <DialogTitle>{title}</DialogTitle> : null}
        <DialogContent>
            <DialogContentText>
                {subtitle}
            </DialogContentText>
            {content}
        </DialogContent>
        <DialogActions>
            {actions}
        </DialogActions>
    </MaterialDialog>
)

export default Dialog;