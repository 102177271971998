export const API_REQUEST = 'API_REQUEST';
export const EXPORT_CLIENT_PAYMENT_REPORT_SUCCESS = 'EXPORT_CLIENT_PAYMENT_REPORT_SUCCESS';
export const EXPORT_CLIENT_PAYMENT_REPORT_FAILED = 'EXPORT_CLIENT_PAYMENT_REPORT_FAILED';
export const GET_MESSAGE_LOGS_SUCCESS = 'GET_MESSAGE_LOGS_SUCCESS';
export const GET_MESSAGE_LOGS_FAILED = 'GET_MESSAGE_LOGS_FAILED';

// Payment
export const GET_CLIENT_PAYMENT_SUMMARY_LIST_SUCCESS = 'GET_CLIENT_PAYMENT_SUMMARY_LIST_SUCCESS';
export const GET_CLIENT_PAYMENT_SUMMARY_LIST_FAILED = 'GET_CLIENT_PAYMENT_SUMMARY_LIST_FAILED';
export const GET_CLIENT_TRANSACTION_DETAILS_SUCCESS = 'GET_CLIENT_TRANSACTION_DETAILS_SUCCESS';
export const GET_CLIENT_TRANSACTION_DETAILS_FAILED = 'GET_CLIENT_TRANSACTION_DETAILS_FAILED';
export const GET_FINANCE_PAYMENT_SUMMARY_LIST_SUCCESS = 'GET_FINANCE_PAYMENT_SUMMARY_LIST_SUCCESS';
export const GET_FINANCE_PAYMENT_SUMMARY_LIST_FAILED = 'GET_FINANCE_PAYMENT_SUMMARY_LIST_FAILED';
export const GET_FINANCE_TRANSACTION_DETAILS_SUCCESS = 'GET_FINANCE_TRANSACTION_DETAILS_SUCCESS';
export const GET_FINANCE_TRANSACTION_DETAILS_FAILED = 'GET_FINANCE_TRANSACTION_DETAILS_FAILED';

// Transactions
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILED = 'GET_TRANSACTIONS_FAILED';

// User
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILED = 'GET_USER_LIST_FAILED';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';


// Authentication
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_UNAUTHORIZED = 'LOGIN_UNAUTHORIZED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';


export const RECREATE_TRANSACTION_SUCCESS = 'RECREATE_TRANSACTION_SUCCESS';
export const RECREATE_TRANSACTION_FAILED = 'RECREATE_TRANSACTION_FAILED';
export const RETRIEVE_HCP_DETAILS_SUCCESS = 'RETRIEVE_HCP_DETAILS_SUCCESS';
export const RETRIEVE_HCP_DETAILS_FAILED = 'RETRIEVE_HCP_DETAILS_FAILED';
export const RETRIEVE_HCP_LIST_SUCCESS = 'RETRIEVE_HCP_LIST_SUCCESS';
export const RETRIEVE_HCP_LIST_FAILED = 'RETRIEVE_HCP_LIST_FAILED';
export const ADD_HCP_SUCCESS = 'ADD_HCP_SUCCESS';
export const ADD_HCP_FAILED = 'ADD_HCP_FAILED';
export const RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_SUCCESS = 'RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_SUCCESS';
export const RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_FAILED = 'RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_FAILED';
export const RETRIEVE_HCP_PATIENT_LIST_SUCCESS = 'RETRIEVE_HCP_PATIENT_LIST_SUCCESS';
export const RETRIEVE_HCP_PATIENT_LIST_FAILED = 'RETRIEVE_HCP_PATIENT_LIST_FAILED';
export const RETRIEVE_HCP_TRANSACTIONS_SUCCESS = 'RETRIEVE_HCP_TRANSACTIONS_SUCCESS';
export const RETRIEVE_HCP_TRANSACTIONS_FAILED = 'RETRIEVE_HCP_TRANSACTIONS_FAILED';
export const RETRIEVE_PATIENT_DETAILS_SUCCESS = 'RETRIEVE_PATIENT_DETAILS_SUCCESS';
export const RETRIEVE_PATIENT_DETAILS_FAILED = 'RETRIEVE_HCP_DETAILS_FAILED';
export const RETRIEVE_PATIENT_LIST_SUCCESS = 'RETRIEVE_PATIENT_LIST_SUCCESS';
export const RETRIEVE_PATIENT_LIST_FAILED = 'RETRIEVE_PATIENT_LIST_FAILED';
export const RETRIEVE_PATIENT_PROGRAMME_CODES_SUCCESS = 'RETRIEVE_PATIENT_PROGRAMME_CODES_SUCCESS';
export const RETRIEVE_PATIENT_PROGRAMME_CODES_FAILED = 'RETRIEVE_PATIENT_PROGRAMME_CODES_FAILED';
export const RETRIEVE_PATIENT_TRANSACTIONS_SUCCESS = 'RETRIEVE_PATIENT_TRANSACTIONS_SUCCESS';
export const RETRIEVE_PATIENT_TRANSACTIONS_FAILED = 'RETRIEVE_PATIENT_TRANSACTIONS_FAILED';
export const RETRIEVE_PATIENT_REGISTRATION_HISTORY_SUCCESS = 'RETRIEVE_PATIENT_REGISTRATION_HISTORY_SUCCESS';
export const RETRIEVE_PATIENT_REGISTRATION_HISTORY_FAILED = 'RETRIEVE_PATIENT_REGISTRATION_HISTORY_FAILED';
export const RETRIEVE_PRODUCT_LIST_SUCCESS = 'RETRIEVE_PRODUCT_LIST_SUCCESS';
export const RETRIEVE_PRODUCT_LIST_FAILED = 'RETRIEVE_PRODUCT_LIST_FAILED';
export const RETRIEVE_PROMOTION_DETAILS_SUCCESS = 'RETRIEVE_PROMOTION_DETAILS_SUCCESS';
export const RETRIEVE_PROMOTION_DETAILS_FAILED = 'RETRIEVE_PROMOTION_DETAILS_FAILED';
export const RETRIEVE_PROMOTION_LIST_SUCCESS = 'RETRIEVE_PROMOTION_LIST_SUCCESS';
export const RETRIEVE_PROMOTION_LIST_FAILED = 'RETRIEVE_PROMOTION_LIST_FAIELD';
export const RETRIEVE_USER_SUCCESS = 'RETRIEVE_USER_SUCCESS';
export const RETRIEVE_USER_FAILED = 'RETRIEVE_USER_FAILED';
export const UPDATE_HCP_DETAILS_SUCCESS = 'UPDATE_HCP_DETAILS_SUCCESS';
export const UPDATE_HCP_DETAILS_FAILED = 'UPDATE_HCP_DETAILS_FAILED';
export const VERIFY_HCP_SUCCESS = 'VERIFY_HCP_SUCCESS';
export const VERIFY_HCP_FAILED = 'VERIFY_HCP_FAILED';
export const UPDATE_PROMOTION_ALLOCATION_SUCCESS = 'UPDATE_PROMOTION_ALLOCATION_SUCCESS';
export const UPDATE_PROMOTION_ALLOCATION_FAILED = 'UPDATE_PROMOTION_ALLOCATION_FAILED';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_FAILED = 'UPDATE_USER_STATUS_FAILED';
export const VOID_TRANSACTION_SUCCESS = 'VOID_TRANSACTION_SUCCESS';
export const VOID_TRANSACTION_FAILED = 'VOID_TRANSACTION_FAILED';
export const VOID_REDEMPTION_TRANSACTION_SUCCESS = 'VOID_REDEMPTION_TRANSACTION_SUCCESS';
export const VOID_REDEMPTION_TRANSACTION_FAILED = 'VOID_REDEMPTION_TRANSACTION_FAILED';
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';


// Unverified Emails
export const RETRIEVE_UNVERIFIED_EMAIL_LIST_SUCCESS = 'RETRIEVE_UNVERIFIED_EMAIL_LIST_SUCCESS';
export const RETRIEVE_UNVERIFIED_EMAIL_LIST_FAILED = 'RETRIEVE_UNVERIFIED_EMAIL_LIST_FAILED';