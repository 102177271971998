import {
    GET_USER_LIST_SUCCESS, GET_USER_LIST_FAILED,
    RETRIEVE_USER_SUCCESS, RETRIEVE_USER_FAILED
} from '../../constants/actions.contants';

const defaultState = {
    count: 0,
    list: [],
    pages: 0,
    profile: {},
};

const userReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GET_USER_LIST_SUCCESS: {
            const { count, list, pages } = action.payload.result;

            return {
                ...state,
                count,
                list,
                pages
            }
        }
        case RETRIEVE_USER_SUCCESS:
            const { currentUser, organizationData, userPromotionData } = action.payload;

            return {
                ...state,
                profile: {
                    id: currentUser.id,
                    firstName: currentUser.get('first_name'),
                    middleName: currentUser.get('middle_name'),
                    lastName: currentUser.get('last_name'),
                    email: currentUser.get('email'),
                    mobile: currentUser.get('mobile'),
                    organizationId: currentUser.get('organization').id,
                    organizationBranch: organizationData ? organizationData.get('organization_branch') : null,
                    locationId: currentUser.get('location').id,
                    userPromotionId: currentUser.get('user_promotion').id,
                    promotionCode: userPromotionData ? userPromotionData.get('promotion').get('code') : null
                }
            }
        case GET_USER_LIST_FAILED:
        case RETRIEVE_USER_FAILED:
            return { ...state };
        default:
            return { ...state };
    }
}

export default userReducer;