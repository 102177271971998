import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

/**
 * Material UI - Core
 */
import { 
    Grid, FormControl, InputLabel, Input, FormHelperText ,Button, Card, CardContent
} from '@material-ui/core';

import { MYTOTALHEALTH_LOGO } from '../../constants/logo.constants';
/**
 * Custom Components
 */
import Dialog from '../../components/Dialog';
// import { CircularProgress } from '../../../ui/FormComponents';

/**
 * Actions
 */
import { forgotPassword } from '../actionCreators/user.actionCreator';


import {
    EMAIL_REQUIRED,
    EMAIL_INVALID_FORMAT,
  } from "../../constants/error-messages";

  import { isEmail } from "validator";

class forgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      error: {
        email: "",
      },
      responseDialogOpen: false
    };
  }

  onInputChange = e => this.setState({ [e.target.name]: e.target.value });

  handleResponseDialogClose = () => {
    const { status } = this.props.apiResponse;

    if (status === 200) {
      this.setState({ responseDialogOpen: false });
      this.props.history.push("/mthp/reset-password");
    } else this.setState({ responseDialogOpen: false });
  };

  onSubmit = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { email } = this.state;
    const error = { ...this.state.error };

    error["email"] = "";
    error["password"] = "";
    error["confirmPassword"] = "";

    if (email.length === 0) error["email"] = EMAIL_REQUIRED;
    else if (!isEmail(email)) error["email"] = EMAIL_INVALID_FORMAT;
    else {
      dispatch(forgotPassword({ email })).then(() =>
        this.setState({ responseDialogOpen: true })
      );
    }
    // this.props.history.push('m/reset-password');
    this.setState({ error });
  };

  render() {
    const { isLoading, apiResponse } = this.props;
    const {
      email,
      error,
      responseDialogOpen
    } = this.state;

    return (
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justify={"center"}
        style={{ background: "#00539b", height: "100%" }}
      >
        <Grid item md={3} sm={6} xs={9}>
          <Card>
            <CardContent>
              <div style={{ padding: "50px 50px 0 50px" }}>
                <img
                  src={MYTOTALHEALTH_LOGO}
                  alt="MyTotalHealth Logo"
                  style={{ width: "100%" }}
                />
              </div>

              <Fragment>
                <FormControl
                  error={Boolean(error.email)}
                  fullWidth={true}
                  margin="normal"
                >
                  <InputLabel htmlFor="email">Email Address</InputLabel>
                  <Input
                    type="text"
                    name="email"
                    value={email}
                    onChange={this.onInputChange}
                    autoFocus
                  />
                  <FormHelperText>{error.email}</FormHelperText>
                </FormControl>

                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justify="flex-end"
                  style={{ marginTop: 12 }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.onSubmit}
                    disabled={isLoading}
                  >
                    {/* {isLoading === true && <CircularProgress size={16} thickness={5} />} */}
                    Submit
                  </Button>
                </Grid>

                <Dialog
                  open={responseDialogOpen}
                  title={apiResponse.message}
                  content={apiResponse.result}
                  actions={
                    <Button
                      color="primary"
                      onClick={this.handleResponseDialogClose}
                    >
                      Close
                    </Button>
                  }
                ></Dialog>
              </Fragment>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ ui, apiResponse}) => ({
    isLoading: ui && ui.forgotUserPassword.isLoading,
    apiResponse: apiResponse && apiResponse.default
});

export default connect(mapStateToProps)(forgotPasswordForm);