import React from 'react';
import { Drawer, withStyles } from '@material-ui/core';
import Nav from '../../stayon/components/Nav.component';
import { STAYON_LOGO } from '../../constants/logo.constants';

const drawerWidth = 260;

const styles = theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%',
        position: 'relative',
        zIndex: 1
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    content: {
        backgroundColor: theme.palette.background.default,
        flexGrow: 1,
        minWidth: 0, // So the Typography noWrap works
        padding: theme.spacing.unit * 3
    },
    toolbar: theme.mixins.toolbar
});

const MainPage = ({ classes, children }) => (
    <div className={classes.root}>
        <Drawer
            variant='permanent'
            classes={{ paper: classes.drawerPaper }}>
            <div style={{ margin: '20px 20px 10px' }}>
                <img src={STAYON_LOGO} alt='StayOn Logo' width='100%' />
            </div>
            <Nav />
        </Drawer>

        <main className={classes.content}>
            {/* <div className={classes.toolbar} /> */}
            {children}
        </main>
    </div>
);

export default withStyles(styles, { withTheme: true })(MainPage);