import React, { Component, Fragment } from 'react';
import {
    Grid, Card, CardActionArea,
    Button, Typography
} from '@material-ui/core';

/**
 * Custom Components
 */
import Dialog from '../../components/Dialog';

class ConfigurationPage extends Component {
    state = {
        editHCPCountDialogOpen: false,
    }

    onClickEditHCPCount = () => this.setState({ editHCPCountDialogOpen: true });

    render() {
        const { editHCPCountDialogOpen } = this.state;

        return (
            <Fragment>
                <Grid container spacing={16}>
                    <Grid item xs={4}>
                        <Card onClick={this.onClickEditHCPCount}>
                            <CardActionArea>
                                <Typography>Modify Total Accounts</Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>

                <Dialog 
                    open={editHCPCountDialogOpen}
                    title={`Set a new value?`}
                    subtitle={`Check if the promotion has a user segment...`}
                    content={'This is a test message'}
                    actions={<Button>Update</Button>}
                    />
            </Fragment>
        )
    }
}

export default ConfigurationPage;