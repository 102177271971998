import { Request, toSnakeCase } from '../../utils';

// Actions
import { apiRequestStart, apiRequestEnd } from './ui.action';

import { refreshToken, headersGlobal } from '../services/utilities.services';

// Constants
import {
    RETRIEVE_HCP_DETAILS_SUCCESS, RETRIEVE_HCP_DETAILS_FAILED,
    RETRIEVE_HCP_LIST_SUCCESS, RETRIEVE_HCP_LIST_FAILED,
    RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_SUCCESS, RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_FAILED,
    RETRIEVE_HCP_PATIENT_LIST_SUCCESS, RETRIEVE_HCP_PATIENT_LIST_FAILED,
    RETRIEVE_HCP_TRANSACTIONS_SUCCESS, RETRIEVE_HCP_TRANSACTIONS_FAILED,
    UPDATE_HCP_DETAILS_SUCCESS, UPDATE_HCP_DETAILS_FAILED, VERIFY_HCP_SUCCESS, VERIFY_HCP_FAILED 
} from '../../constants/actions.contants';
import {
    GET_HCP_DETAILS, GET_HCP_LIST,
    GET_HCP_NUMBER_OF_TRANSACTIONS, GET_HCP_PATIENT_LIST,
    GET_HCP_TRANSACTIONS, UPDATE_HCP_DETAILS, PUT_VERIFY_HCP
} from '../../constants/url-collection-mycare';

const getHCPDetailsSuccess = ({ status, message, result }) => ({
    type: RETRIEVE_HCP_DETAILS_SUCCESS,
    payload: { status, message, result }
});

const getHCPDetailsFailed = ({ status, message, result }) => ({
    type: RETRIEVE_HCP_DETAILS_FAILED,
    payload: { status, message, result }
});

const getHCPListSuccess = ({ status, message, result }) => ({
    type: RETRIEVE_HCP_LIST_SUCCESS,
    payload: { status, message, result }
});

const getHCPListFailed = ({ status, message, result }) => ({
    type: RETRIEVE_HCP_LIST_FAILED,
    payload: { status, message, result }
});

const getHCPNumberOfTransactionsSuccess = ({ status, message, result }) => ({
    type: RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_SUCCESS,
    payload: { status, message, result }
});

const getHCPNumberOfTransactionsFailed = ({ status, message, result }) => ({
    type: RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_FAILED,
    payload: { status, message, result }
});

const getHCPPatientListSuccess = ({ status, message, result }) => ({
    type: RETRIEVE_HCP_PATIENT_LIST_SUCCESS,
    payload: { status, message, result }
});

const getHCPPatientListFailed = ({ status, message, result }) => ({
    type: RETRIEVE_HCP_PATIENT_LIST_FAILED,
    payload: { status, message, result }
});

const getHCPTransactionsSuccess = ({ status, message, result }) => ({
    type: RETRIEVE_HCP_TRANSACTIONS_SUCCESS,
    payload: { status, message, result }
});

const getHCPTransactionsFailed = ({ status, message, result }) => ({
    type: RETRIEVE_HCP_TRANSACTIONS_FAILED,
    paylaod: { status, message, result }
});

const updateHCPDetailsSuccess = ({ status, message, result }) => ({
    type: UPDATE_HCP_DETAILS_SUCCESS,
    payload: { status, message, result }
});

const updateHCPDetailsFailed = ({ status, message, result }) => ({
    type: UPDATE_HCP_DETAILS_FAILED,
    payload: { status, message, result }
});

const verifyHcpSuccess = ({ status, message, result }) => ({
    type: VERIFY_HCP_SUCCESS,
    payload: { status, message, result }
});

const verifyHcpFailed = ({ status, message, result }) => ({
    type: VERIFY_HCP_FAILED,
    payload: { status, message, result }
});

export function getHCPDetails(userId) {
    return async dispatch => {
        const trigger = 'getHCPDetails';
        try {
            dispatch(apiRequestStart(trigger));
            
            const { data } = await Request({
                method: 'GET',
                url: GET_HCP_DETAILS(userId)
            },
            headersGlobal()
            );
            dispatch(getHCPDetailsSuccess(data));
        }
        catch(error) {
            try{
                if(error.response && error.response.status === 401){
                    await refreshToken();

                    const result = await Request({
                        method: 'GET',
                        url: GET_HCP_DETAILS(userId)
                    },
                     headersGlobal()
                    );

                    dispatch(getHCPDetailsSuccess(result.data));
                }
                else{
                    throw error;
                }
            }
            catch(e){
                if(e.response)
                dispatch(getHCPDetailsFailed(e.response.data));
            }
        }
        finally {
            dispatch(apiRequestEnd(trigger));
        }
    }
}

export function getHCPList({ search, page = 1, limit = 10 } = {}) {
    return async dispatch => {
        const trigger = 'getHCPList';

        try {
            dispatch(apiRequestStart(trigger));
            const { data } = await Request({
                method: 'GET',
                url: GET_HCP_LIST,
                params: {
                    search,
                    page,
                    limit
                }
            },
            headersGlobal());
            dispatch(getHCPListSuccess(data));
        }
        catch(error) {
            try{
                if(error.response && error.response.status === 401){ 
                    await refreshToken();

                    const result = await Request({
                        method: 'GET',
                        url: GET_HCP_LIST,
                        params: {
                            skip: 0,
                            limit: 100
                        }
                    },
                    headersGlobal());
                    dispatch(getHCPListSuccess(result.data));

                }
                else{
                    throw error;
                }
            }
            catch(e){
                if(e.response)
                dispatch(getHCPListFailed(e.response.data));
            }
        }
        finally {
            dispatch(apiRequestEnd(trigger));
        }
    }
}

export function getHCPNumberOfTransactions(userId) {
    return async dispatch => {
        const trigger = 'getHCPNumberOfTransactions';
        try {
            dispatch(apiRequestStart(trigger));
            const { data } = await Request({
                method: 'GET',
                url: GET_HCP_NUMBER_OF_TRANSACTIONS(userId)
            },
            headersGlobal()
            );
            dispatch(getHCPNumberOfTransactionsSuccess(data));
        }
        catch(error) {
            try{
                if(error.response && error.response.status === 401){
                    await refreshToken();

                    const result = await Request({
                        method: 'GET',
                        url: GET_HCP_NUMBER_OF_TRANSACTIONS(userId)
                    },
                    headersGlobal()
                    );
                    dispatch(getHCPNumberOfTransactionsSuccess(result.data));
                }
                else{
                    throw error;
                }
            }
            catch(e){
                if(e.response)
                dispatch(getHCPNumberOfTransactionsFailed(e.response.data));
            }
        }
        finally {
            dispatch(apiRequestEnd(trigger));
        }
    }
}

export function getHCPPatientList(userId, promotionId) {
    return async dispatch => {
        const trigger = 'getHCPPatientList';
        try {
            dispatch(apiRequestStart(trigger));
            const { data } = await Request({
                method: 'GET',
                url: GET_HCP_PATIENT_LIST(userId),
                params: {
                    promotion_id: promotionId
                }
            },
            headersGlobal()
            );
            dispatch(getHCPPatientListSuccess(data));
        }
        catch(error) {
            try{
                if(error.response && error.response.status === 401){
                    await refreshToken();

                    const result = await Request({
                        method: 'GET',
                        url: GET_HCP_PATIENT_LIST(userId),
                        params: {
                            promotion_id: promotionId
                        }
                    },
                    headersGlobal()
                    );
                    dispatch(getHCPPatientListSuccess(result.data));
                }
                else{
                    throw error;
                }
            }
            catch(e){
                if(e.response)
                dispatch(getHCPPatientListFailed(e.response.data));
            }
        }
        finally {
            dispatch(apiRequestEnd(trigger));
        }
    }
}

export function getHCPTransactions(userId) {
    return async dispatch => {
        const trigger = 'getHCPTransactions';
        try {
            dispatch(apiRequestStart(trigger));
            const { data } = await Request({
                method: 'GET',
                url: GET_HCP_TRANSACTIONS(userId)
            },
            headersGlobal()
            );
            dispatch(getHCPTransactionsSuccess(data));
        }
        catch(error) {
            try{
                if(error.response && error.response.status === 401){
                    await refreshToken();

                    const result = await Request({
                        method: 'GET',
                        url: GET_HCP_TRANSACTIONS(userId)
                    },
                    headersGlobal()
                    );
                    dispatch(getHCPTransactionsSuccess(result.data));

                }
                else{
                    throw error;
                }
            }catch(e){
                if(e.response)
                dispatch(getHCPTransactionsFailed(e.response.data));
            }
        }
        finally {
            dispatch(apiRequestEnd(trigger));
        }
    }
}

export function updateHCPDetails(userId, userDetails) {
    let postData = {};
    Object.entries(userDetails).forEach(([key, value]) => postData[toSnakeCase(key)] = value);

    return async dispatch => {
        const trigger = 'updateHCPDetails';
        try {
            dispatch(apiRequestStart(trigger));
 
            // Valiate userId with the userId in the userDetails for data integrity.
            const { data } = await Request({
                method: 'PUT',
                url: UPDATE_HCP_DETAILS(userId),
                data: postData
            },
            headersGlobal());
            dispatch(updateHCPDetailsSuccess(data));
        }
        catch(error) {
            try{
                if(error.response && error.response.status === 401){
                    await refreshToken();
                    const result = await Request({
                        method: 'PUT',
                        url: UPDATE_HCP_DETAILS(userId),
                        data: postData
                    },
                    headersGlobal()
                    );

                    dispatch(updateHCPDetailsSuccess(result.data));
                }
                else{
                    throw error;
                }
            }
            catch(e){
                if(e.response)
                dispatch(updateHCPDetailsFailed(e.response.data));
            }
        }
        finally {
            dispatch(apiRequestEnd(trigger))
        }
    }
}

export function verifyHcpUser(userId){
    return async dispatch => {
        const trigger = 'verifyHcpUser';
        try{
            dispatch(apiRequestStart(trigger));
 
            // Valiate userId with the userId in the userDetails for data integrity.
            const { data } = await Request({
                method: 'PUT',
                url: PUT_VERIFY_HCP(userId),
            },
            headersGlobal());
            dispatch(verifyHcpSuccess(data));
        }
        catch(e){
            try{
             if(e.response && e.response.status === 401){
                await refreshToken();

                const { data } = await Request({
                    method: 'PUT',
                    url: PUT_VERIFY_HCP(userId),
                },
                headersGlobal());
                
                dispatch(verifyHcpSuccess(data));
             }
             else{
                 throw e;
             }
            }
            catch(error){
                if(error.response)
                dispatch(verifyHcpFailed(e.response.data));
            }
        }
        finally {
            dispatch(apiRequestEnd(trigger))
        }
    }
}