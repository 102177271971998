// Constants
import {
  RETRIEVE_PROMOTION_DETAILS_SUCCESS,
  RETRIEVE_PROMOTION_DETAILS_FAILED,
  RETRIEVE_PROMOTION_LIST_SUCCESS,
  RETRIEVE_PROMOTION_LIST_FAILED,
  UPDATE_PROMOTION_ALLOCATION_SUCCESS,
  UPDATE_PROMOTION_ALLOCATION_FAILED
} from "../constants/actions.contants";

export const getPromotionDetailsSuccess = ({ status, message, result }) => ({
  type: RETRIEVE_PROMOTION_DETAILS_SUCCESS,
  payload: { status, message, result }
});

export const getPromotionDetailsFailed = ({ status, message, result }) => ({
  type: RETRIEVE_PROMOTION_DETAILS_FAILED,
  payload: { status, message, result }
});

export const getPromotionListSuccess = ({ status, message, result }) => ({
  type: RETRIEVE_PROMOTION_LIST_SUCCESS,
  payload: { status, message, result }
});

export const getPromotionListFailed = ({ status, message, result }) => ({
  type: RETRIEVE_PROMOTION_LIST_FAILED,
  payload: { status, message, result }
});

export const updatePromotionAllocationSucces = ({
  status,
  message,
  result
}) => ({
  type: UPDATE_PROMOTION_ALLOCATION_SUCCESS,
  payload: { status, message, result }
});

export const updatePromotionAllocationFailed = ({
  status,
  message,
  result
}) => ({
  type: UPDATE_PROMOTION_ALLOCATION_FAILED,
  payload: { status, message, result }
});
