// Actions
import * as HcpAction from "../../actions/hcp.action";
import { apiRequestStart, apiRequestEnd } from "../../actions/ui.action";

// Endpoints
import {
  ADD_HCP,
  GET_HCP_DETAILS,
  GET_HCP_LIST,
  GET_HCP_NUMBER_OF_TRANSACTIONS,
  GET_HCP_PATIENT_LIST,
  GET_HCP_TRANSACTIONS,
  UPDATE_HCP_DETAILS
} from "../endpoints/mthp.endpoints";

// Utilities
import { headers } from "../utilities/headers.utility";
import { Request, toSnakeCase } from "../../utils";

export function addHCP(HCPData){
  return async dispatch => {
    const trigger = "addHCP";
    try{
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "POST",
          url: ADD_HCP,
          data: HCPData
        },
        headers()
      );
      dispatch(HcpAction.addHCPSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(HcpAction.addHCPFailed(error.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  }
}

export function getHCPDetails(userId) {
  return async dispatch => {
    const trigger = "getHCPDetails";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_HCP_DETAILS(userId)
        },
        headers()
      );
      dispatch(HcpAction.getHCPDetailsSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(HcpAction.getHCPDetailsFailed(error.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function getHCPList({ search, page = 1, limit = 10 } = {}) {
  return async dispatch => {
    const trigger = "getHCPList";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_HCP_LIST,
          params: {
            search,
            page,
            limit
          }
        },
        headers()
      );
      dispatch(HcpAction.getHCPListSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(HcpAction.getHCPListFailed(error.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function getHCPNumberOfTransactions(userId) {
  return async dispatch => {
    const trigger = "getHCPNumberOfTransactions";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_HCP_NUMBER_OF_TRANSACTIONS(userId)
        },
        headers()
      );
      dispatch(HcpAction.getHCPNumberOfTransactionsSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(
          HcpAction.getHCPNumberOfTransactionsFailed(error.response.data)
        );
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function getHCPPatientList(userId, promotionId) {
  return async dispatch => {
    const trigger = "getHCPPatientList";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_HCP_PATIENT_LIST(userId),
          params: {
            promotion_id: promotionId
          }
        },
        headers()
      );
      dispatch(HcpAction.getHCPPatientListSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(HcpAction.getHCPPatientListFailed(error.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function getHCPTransactions(userId) {
  return async dispatch => {
    const trigger = "getHCPTransactions";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_HCP_TRANSACTIONS(userId)
        },
        headers()
      );
      dispatch(HcpAction.getHCPTransactionsSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(HcpAction.getHCPTransactionsFailed(error.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function updateHCPDetails(userId, userDetails) {
  let postData = {};
  Object.entries(userDetails).forEach(
    ([key, value]) => (postData[toSnakeCase(key)] = value)
  );

  return async dispatch => {
    const trigger = "updateHCPDetails";
    try {
      dispatch(apiRequestStart(trigger));

      // Valiate userId with the userId in the userDetails for data integrity.
      const { data } = await Request(
        {
          method: "POST",
          url: UPDATE_HCP_DETAILS(userId),
          data: postData
        },
        headers()
      );
      dispatch(HcpAction.updateHCPDetailsSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(HcpAction.updateHCPDetailsFailed(error.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}
