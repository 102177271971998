// Constants
import {
    /*RETRIEVE_PATIENT_DETAILS_SUCCESS, RETRIEVE_PATIENT_DETAILS_FAILED,*/
    RETRIEVE_PATIENT_LIST_SUCCESS, RETRIEVE_PATIENT_LIST_FAILED,
    RETRIEVE_PATIENT_PROGRAMME_CODES_SUCCESS, RETRIEVE_PATIENT_PROGRAMME_CODES_FAILED,
    RETRIEVE_PATIENT_TRANSACTIONS_SUCCESS, RETRIEVE_PATIENT_TRANSACTIONS_FAILED,
    RETRIEVE_PATIENT_REGISTRATION_HISTORY_SUCCESS, RETRIEVE_PATIENT_REGISTRATION_HISTORY_FAILED
} from '../constants/actions.contants';

/*
export const getPatientDetailsSuccess = ({ status, message, result }) => ({
    type: RETRIEVE_PATIENT_DETAILS_SUCCESS,
    payload: { status, message, result }
});

export const getPatientDetailsFailed = ({ status, message, result }) => ({
    type: RETRIEVE_PATIENT_DETAILS_FAILED,
    payload: { status, message, result }
});
*/

export const getPatientListSuccess = ({ status, message, result }) => ({
    type: RETRIEVE_PATIENT_LIST_SUCCESS,
    payload: { status, message, result }
});

export const getPatientListFailed = ({ status, message, result }) => ({
    type: RETRIEVE_PATIENT_LIST_FAILED,
    payload: { status, message, result }
});

export const getPatientProgrammeCodesSuccess = ({ status, message, result }) => ({
    type: RETRIEVE_PATIENT_PROGRAMME_CODES_SUCCESS,
    payload: { status, message, result }
});

export const getPatientProgrammeCodesFailed = ({ status, message, result }) => ({
    type: RETRIEVE_PATIENT_PROGRAMME_CODES_FAILED,
    payload: { status, message, result }
});

export const getPatientTransactionSuccess = ({ status, message, result }) => ({
    type: RETRIEVE_PATIENT_TRANSACTIONS_SUCCESS,
    payload: { status, message, result }
});

export const getPatientTransactionFailed = ({ status, message, result }) => ({
    type: RETRIEVE_PATIENT_TRANSACTIONS_FAILED,
    payload: { status, message, result }
});

export const getPatientRegistrationHistorySuccess = ({ status, message, result }) => ({
    type: RETRIEVE_PATIENT_REGISTRATION_HISTORY_SUCCESS,
    payload: { status, message, result }
});

export const getPatientRegistrationHistoryFailed = ({ status, message, result }) => ({
    type: RETRIEVE_PATIENT_REGISTRATION_HISTORY_FAILED,
    payload: { status, message, result }
});