import {
    /*RETRIEVE_PATIENT_DETAILS_SUCCESS, RETRIEVE_PATIENT_DETAILS_FAILED,*/
    RETRIEVE_PATIENT_LIST_SUCCESS, RETRIEVE_PATIENT_LIST_FAILED,
    RETRIEVE_PATIENT_PROGRAMME_CODES_SUCCESS, RETRIEVE_PATIENT_PROGRAMME_CODES_FAILED,
    RETRIEVE_PATIENT_TRANSACTIONS_SUCCESS, RETRIEVE_PATIENT_TRANSACTIONS_FAILED,
    RETRIEVE_PATIENT_REGISTRATION_HISTORY_SUCCESS, RETRIEVE_PATIENT_REGISTRATION_HISTORY_FAILED
} from '../../constants/actions.contants';

const defaultState = {
    promotionPatient: {
        count: 0,
        list: [],
        pages: 0
    },
    programmeCodes: {},
    registrationHistory: {},
    transaction: {
        count: 0,
        list: [],
        pages: 0
    }
};

const patientReducer = (state = defaultState, action) => {
    switch(action.type) {
        /*
        case RETRIEVE_PATIENT_DETAILS_SUCCESS:
        case RETRIEVE_PATIENT_DETAILS_FAILED:
            return {
                ...state,
                registrationHistory: {...action.payload}
            };
        */
        case RETRIEVE_PATIENT_LIST_SUCCESS: {
            const { count, list, pages } = action.payload.result;

            return {
                ...state, 
                promotionPatient: {
                    count,
                    list,
                    pages
                }
            }
        }
        case RETRIEVE_PATIENT_LIST_FAILED:
            return { ...state };
        case RETRIEVE_PATIENT_PROGRAMME_CODES_SUCCESS:
        case RETRIEVE_PATIENT_PROGRAMME_CODES_FAILED:
            return {
                ...state,
                programmeCodes: {...action.payload}
            }
        case RETRIEVE_PATIENT_REGISTRATION_HISTORY_SUCCESS:
        case RETRIEVE_PATIENT_REGISTRATION_HISTORY_FAILED:
            return {
                ...state,
                registrationHistory: {...action.payload}
            }
        case RETRIEVE_PATIENT_TRANSACTIONS_SUCCESS:
            const { count, list, pages } = action.payload.result;
            const transactionBatches = {};
                
            const transactionBatchCollection = list.reduce((accumulator, currentValue) => {
                const transactionBatchId = currentValue.transaction_batch_id;
                
                const transactionBatchInitial = {
                    transaction_batch_id: transactionBatchId, 
                    patient_id: currentValue.patient_id,
                    patient_mobile: currentValue.patient_mobile, 
                    promo_code: currentValue.promo_code,
                    transaction_campaigns: [],
                    user_id: currentValue.user_id,
                    organization_id: currentValue.organization_id,
                    organization_branch: currentValue.organization_branch,
                    created_at: currentValue.created_at, 
                    updated_at: currentValue.updated_at, 
                    deleted_at: currentValue.deleted_at
                }

                let transactionBatch = transactionBatches[transactionBatchId] || transactionBatchInitial;

                transactionBatch.transaction_campaigns.push({
                    transaction_campaign_id: currentValue.transaction_campaign_id,
                    product_id: currentValue.product_id,
                    product_full_name: currentValue.product_full_name,
                    campaign_product_quantity_packaging: currentValue.campaign_product_quantity_packaging,
                    quantity: currentValue.quantity
                });
                
                transactionBatches[transactionBatchId] = transactionBatch;

                return transactionBatches;
            }, []);

            const transactionList = Object.values(transactionBatchCollection).reverse();

            return {
                ...state,
                transaction: {
                    count,
                    list: transactionList,
                    pages
                }
            };
        case RETRIEVE_PATIENT_TRANSACTIONS_FAILED:
            return {
                ...state,
                transactions: {...action.payload}
            };
        default:
            return {...state};
    }
}

export default patientReducer;