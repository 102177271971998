// Constants
import {
  ADD_HCP_SUCCESS,
  ADD_HCP_FAILED,
  RETRIEVE_HCP_DETAILS_SUCCESS,
  RETRIEVE_HCP_DETAILS_FAILED,
  RETRIEVE_HCP_LIST_SUCCESS,
  RETRIEVE_HCP_LIST_FAILED,
  RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_SUCCESS,
  RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_FAILED,
  RETRIEVE_HCP_PATIENT_LIST_SUCCESS,
  RETRIEVE_HCP_PATIENT_LIST_FAILED,
  RETRIEVE_HCP_TRANSACTIONS_SUCCESS,
  RETRIEVE_HCP_TRANSACTIONS_FAILED,
  UPDATE_HCP_DETAILS_SUCCESS,
  UPDATE_HCP_DETAILS_FAILED
} from "../constants/actions.contants";

export const addHCPSuccess = ({ status, message, result }) => ({
  type: ADD_HCP_SUCCESS,
  payload: { status, message, result }
});

export const addHCPFailed = ({ status, message, result }) => ({
  type: ADD_HCP_FAILED,
  payload: { status, message, result }
});

export const getHCPDetailsSuccess = ({ status, message, result }) => ({
  type: RETRIEVE_HCP_DETAILS_SUCCESS,
  payload: { status, message, result }
});

export const getHCPDetailsFailed = ({ status, message, result }) => ({
  type: RETRIEVE_HCP_DETAILS_FAILED,
  payload: { status, message, result }
});

export const getHCPListSuccess = ({ status, message, result }) => ({
  type: RETRIEVE_HCP_LIST_SUCCESS,
  payload: { status, message, result }
});

export const getHCPListFailed = ({ status, message, result }) => ({
  type: RETRIEVE_HCP_LIST_FAILED,
  payload: { status, message, result }
});

export const getHCPNumberOfTransactionsSuccess = ({
  status,
  message,
  result
}) => ({
  type: RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_SUCCESS,
  payload: { status, message, result }
});

export const getHCPNumberOfTransactionsFailed = ({
  status,
  message,
  result
}) => ({
  type: RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_FAILED,
  payload: { status, message, result }
});

export const getHCPPatientListSuccess = ({ status, message, result }) => ({
  type: RETRIEVE_HCP_PATIENT_LIST_SUCCESS,
  payload: { status, message, result }
});

export const getHCPPatientListFailed = ({ status, message, result }) => ({
  type: RETRIEVE_HCP_PATIENT_LIST_FAILED,
  payload: { status, message, result }
});

export const getHCPTransactionsSuccess = ({ status, message, result }) => ({
  type: RETRIEVE_HCP_TRANSACTIONS_SUCCESS,
  payload: { status, message, result }
});

export const getHCPTransactionsFailed = ({ status, message, result }) => ({
  type: RETRIEVE_HCP_TRANSACTIONS_FAILED,
  paylaod: { status, message, result }
});

export const updateHCPDetailsSuccess = ({ status, message, result }) => ({
  type: UPDATE_HCP_DETAILS_SUCCESS,
  payload: { status, message, result }
});

export const updateHCPDetailsFailed = ({ status, message, result }) => ({
  type: UPDATE_HCP_DETAILS_FAILED,
  payload: { status, message, result }
});
