// Actions
import * as PatientAction from "../../actions/patient.action";
import { apiRequestStart, apiRequestEnd } from "../../actions/ui.action";

// Endpoints
import {
  GET_PATIENT_LIST,
  GET_PATIENT_PROGRAMME_CODES,
  GET_PATIENT_TRANSACTIONS,
  GET_PATIENT_REGISTRATION_HISTORY
} from "../endpoints/mthp.endpoints";

// Utilities
import { headers } from "../utilities/headers.utility";
import { Request } from "../../utils";

export function getPatientList({ search, page = 1, limit = 10 } = {}) {
  return async dispatch => {
    const trigger = "getPatientList";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_PATIENT_LIST,
          params: {
            search,
            page,
            limit
          }
        },
        headers()
      );
      dispatch(PatientAction.getPatientListSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(PatientAction.getPatientListFailed(error.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function getPatientProgrammeCodes(patientId) {
  return async dispatch => {
    const trigger = "getPatientProgrammeCodes";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_PATIENT_PROGRAMME_CODES(patientId)
        },
        headers()
      );
      dispatch(PatientAction.getPatientProgrammeCodesSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(
          PatientAction.getPatientProgrammeCodesFailed(error.response.data)
        );
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function getPatientTransactions(
  patientId,
  { query, page = 0, limit = 10 }
) {
  return async dispatch => {
    const trigger = "getPatientTransactions";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_PATIENT_TRANSACTIONS(patientId),
          params: {
            query,
            page,
            limit
          }
        },
        headers()
      );
      dispatch(PatientAction.getPatientTransactionSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(
          PatientAction.getPatientTransactionFailed(error.response.data)
        );
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function getPatientRegistrationHistory(patientId) {
  return async dispatch => {
    const trigger = "getPatientRegistrationHistory";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_PATIENT_REGISTRATION_HISTORY(patientId)
        },
        headers()
      );
      dispatch(PatientAction.getPatientRegistrationHistorySuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(
          PatientAction.getPatientRegistrationHistoryFailed(error.response.data)
        );
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}
