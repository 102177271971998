import AboutPage from './about.page';
import ConfigurationPage from './configuration.page';
import HCPDetailsPage from './hcp.details.page';
import HCPListPage from './hcp.list.page';
import HomePage from './home.page';
import LoginPage from './login.page';
import MainPage from './main.page';
import MessagePage from './message.page';
import PatientDetailsPage from './patient.details.page';
import PatientListPage from './patient.list.page';
import PaymentPage from './payment.page';
import PromotionDetailsPage from './promotion.details.page';
import PromotionListPage from './promotion.list.page';
import TransactionListPage from './transaction.list.page';
import UserListPage from './user.accounts.page';
import ResetPasswordPage from './reset.password.page';
import ForgotPasswordPage from './forgot.password.page';

export default {
    AboutPage,
    ConfigurationPage,
    HCPDetailsPage,
    HCPListPage,
    HomePage,
    LoginPage,
    MainPage,
    MessagePage,
    PatientDetailsPage,
    PatientListPage,
    PaymentPage,
    PromotionDetailsPage,
    PromotionListPage,
    TransactionListPage,
    UserListPage,
    ResetPasswordPage,
    ForgotPasswordPage
}