import {
    ADD_HCP_SUCCESS, ADD_HCP_FAILED,
    RETRIEVE_HCP_DETAILS_SUCCESS, RETRIEVE_HCP_DETAILS_FAILED,
    RETRIEVE_HCP_LIST_SUCCESS, RETRIEVE_HCP_LIST_FAILED,
    RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_SUCCESS, RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_FAILED,
    RETRIEVE_HCP_PATIENT_LIST_SUCCESS, RETRIEVE_HCP_PATIENT_LIST_FAILED,
    RETRIEVE_HCP_TRANSACTIONS_SUCCESS, RETRIEVE_HCP_TRANSACTIONS_FAILED
} from '../../constants/actions.contants';

const defaultState = {
    count: 0,
    list: [],
    pages: 0,
    details: {},
    numberOfTransactions: 0,
    patientList: [],
    transactionList: []
};

const hcpReducer = (state = defaultState, action) => {
    switch(action.type) {
        case RETRIEVE_HCP_DETAILS_SUCCESS:
            return {
                ...state,
                details: {
                    ...action.payload.result.length > 0 
                        ? action.payload.result[0] 
                        : defaultState.details
                }
            }
        case RETRIEVE_HCP_LIST_SUCCESS: {
            const { count, list, pages } = action.payload.result;

            return {
                ...state,
                count,
                list,
                pages
            }
        }
        case RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                numberOfTransactions: action.payload.result[0]['total_transactions']
            };
        case RETRIEVE_HCP_PATIENT_LIST_SUCCESS:
            return {
                ...state,
                patientList: action.payload.result
            }
        case RETRIEVE_HCP_TRANSACTIONS_SUCCESS:
            // TODO: move this to API. front-end should only consume the data provided by the API.
            const transactionBatches = {};
                    
            const transactionBatchCollection = action.payload.result.reduce((accumulator, currentValue) => {
                const transactionBatchId = currentValue.transaction_batch_id;
                const transactionBatchInitial = {
                    transactionBatchId: transactionBatchId, 
                    patientId: currentValue.patient_id,
                    patientMobile: currentValue.patient_mobile, 
                    transactionCampaigns: [],
                    type: currentValue.type,
                    createdAt: currentValue.created_at, 
                    updatedAt: currentValue.updated_at, 
                    deletedAt: currentValue.deleted_at
                }

                let transactionBatch = transactionBatches[transactionBatchId] || transactionBatchInitial;

                transactionBatch.transactionCampaigns.push({
                    transactionCampaignId: currentValue.transaction_campaign_id,
                    productId: currentValue.product_id,
                    productFullName: currentValue.product_full_name,
                    campaignProductQuantityPackaging: currentValue.campaign_product_quantity_packaging,
                    quantity: currentValue.quantity
                });
                
                transactionBatches[transactionBatchId] = transactionBatch;

                return transactionBatches;
            }, []);

            return {
                ...state,
                transactionList: Object.values(transactionBatchCollection).sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
            };
        case RETRIEVE_HCP_DETAILS_FAILED:
        case RETRIEVE_HCP_LIST_FAILED:
        case RETRIEVE_HCP_NUMBER_OF_TRANSACTIONS_FAILED:
        case RETRIEVE_HCP_PATIENT_LIST_FAILED:
        case RETRIEVE_HCP_TRANSACTIONS_FAILED:
        case ADD_HCP_SUCCESS:
        case ADD_HCP_FAILED:
        default:
            return {...state};
    }
}

export default hcpReducer;