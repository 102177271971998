import React from 'react';

/**
 * Material UI - Core
 */
import {
    Checkbox as MaterialCheckbox,
    FormControl as MaterialFormControl,
    FormControlLabel,
    FormHelperText as MaterialFormHelperText,
    Input as MaterialInput,
    InputLabel as MaterialInputLabel,
    Typography
} from '@material-ui/core';

/**
 * Custom Components
 */
export const Checkbox = props => {
    const { name, checked, onChange, value, label } = props;
    return (
        <FormControlLabel
            control={<MaterialCheckbox {...props}
                name={name}
                checked={checked}
                onChange={onChange}
                value={value} />
            }
            label={<Typography>{label}</Typography>}
        />);
}

export const InputControl = ({ 
    error = '', fullWidth = true, margin = 'dense', 
    inputLabelFor, inputLabel, 
    inputName, inputType = 'text', onChange, 
    disabled = false, multiline = false,
    value,
    ...otherProps
}) => (
    <MaterialFormControl
        error={Boolean(error)}
        fullWidth={fullWidth}
        margin={margin}>
        <MaterialInputLabel htmlFor={inputLabelFor}>{inputLabel}</MaterialInputLabel>
        <MaterialInput {...otherProps}
            disabled={disabled}
            id={inputLabelFor}
            multiline={multiline}
            name={inputName}
            onChange={onChange}
            type={inputType}
            value={value} />
        <MaterialFormHelperText>{error}</MaterialFormHelperText>
    </MaterialFormControl>
);