import React, { Component } from 'react';
import {
    IconButton, TableRow, TableCell
} from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';

/**
 * Custom Components
 */
import Table from './Table2';

/**
 * Utilities
 */
import { transactionMapper } from '../utils/transaction.utils';

// TODO: Add code documentations
/**
 * Transaction List Component 
 * @param {number} transactionCount - total transactions retrieved
 * @param {array} transactionList - array of transactions
 * @returns a React table component
 */
class TransactionListComponent extends Component {
    onSelectTransaction = ({ transactionDialogOpen, transactionBatchId }) => {
        this.props.onSelectTransaction({
            transactionDialogOpen,
            selectedTransactionBatchId: transactionBatchId
        });
    }

    render() { 
        const {
            transactionCount, transactionList,
            onChangePage,
            searchInputComponent
        } = this.props;
        
        const transactions = transactionMapper(transactionList)
        
        const transactionsHeader = ['Transaction Batch ID',  'Transaction Date', 'Patient Code', 'Products', 'Status', 'View Transaction'];
        const transactionsBody = transactions.length > 0
            ? transactions.map(({ transaction_batch_id, created_at, promo_code, products, deleted_at }) => (
                <TableRow key={transaction_batch_id}>
                    <TableCell>{transaction_batch_id}</TableCell>
                    <TableCell>{created_at}</TableCell>
                    <TableCell>{promo_code}</TableCell>
                    <TableCell>{products}</TableCell>
                    <TableCell>{deleted_at !== null ? 'Voided' : 'Approved'}</TableCell>
                    <TableCell align='center'>
                        <IconButton size='small' color='primary'
                            onClick={() => this.onSelectTransaction({ transactionDialogOpen: true, transactionBatchId: transaction_batch_id })}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))
            : (
                <TableRow>
                    <TableCell colSpan={transactionsHeader.length}>There are no data available</TableCell>
                </TableRow>
            );

        return (
            <Table
                dataLength={transactionCount}
                headers={transactionsHeader}
                body={transactionsBody}
                onChangePage={onChangePage}
                searchInputComponent={searchInputComponent} />
        )
    }
}

export default TransactionListComponent;