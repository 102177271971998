import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

/**
 * Material UI - Core
 */
import { 
    Grid, FormControl, InputLabel, Input, FormHelperText ,Button, Card, CardContent
} from '@material-ui/core';

import { MYTOTALHEALTH_LOGO } from '../../constants/logo.constants';
/**
 * Custom Components
 */
import Dialog from '../../components/Dialog';
// import { CircularProgress } from '../../../ui/FormComponents';

/**
 * Actions
 */
import { updatePassword } from '../actionCreators/user.actionCreator';


import {
    PASSWORD_REQUIRED,
    PASSWORD_MIN_LENGTH,
    PASSWORD_MAX_LENGTH,
    RECOVERY_CODE_REQUIRED,
    PASSWORD_AND_CONFIRM_PASSWORD_NOT_MATCH
  } from "../../constants/error-messages";


class UpdatePasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recoveryCode: '',
            password: '',
            confirmPassword: '',
            error: {
                recoveryCode: '',
                password: '',
                confirmPassword: ''
            },
            responseDialogOpen: false
        }
    }

    onInputChange = e => this.setState({ [e.target.name]: e.target.value });

    handleResponseDialogClose = () => {
        const { status } = this.props.apiResponse;
        
        if (status === 200) {
            this.setState({ responseDialogOpen: false });
            this.props.history.push('/mthp');
        }
        else 
            this.setState({ responseDialogOpen: false });
    };

    onSubmit = e => {
        e.preventDefault();
        const { dispatch } = this.props;
        const { recoveryCode, password, confirmPassword } = this.state;
        const error = { ...this.state.error };

        error['recoveryCode'] = ''; 
        error['password'] = '';
        error['confirmPassword'] = '';

        if (recoveryCode.length === 0)
            error['recoveryCode'] = RECOVERY_CODE_REQUIRED;
        else if (password.length === 0)
            error['password'] = PASSWORD_REQUIRED;
        else if (password.length < 8)
            error['password'] = PASSWORD_MIN_LENGTH;
        else if (password.length > 16)
            error['password'] = PASSWORD_MAX_LENGTH;
        else if (confirmPassword.length === 0)
            error['confirmPassword'] = PASSWORD_AND_CONFIRM_PASSWORD_NOT_MATCH;
        else if (password !== confirmPassword)
            error['confirmPassword'] = PASSWORD_AND_CONFIRM_PASSWORD_NOT_MATCH;            
        else {
            dispatch(updatePassword({ recoveryCode, new_password: password, confirm_password: confirmPassword }))
            .then(() => this.setState({ responseDialogOpen: true }));
        }

        this.setState({ error });
    }

    render() {
        const { isLoading, apiResponse } = this.props;
        const { recoveryCode, password, confirmPassword, error, responseDialogOpen } = this.state;

        return (
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justify={"center"}
            style={{ background: "#00539b", height: "100%" }}
          >
            <Grid item md={3} sm={6} xs={9}>
              <Card>
                <CardContent>
                  <div style={{ padding: "50px 50px 0 50px" }}>
                    <img
                      src={MYTOTALHEALTH_LOGO}
                      alt="MyTotalHealth Logo"
                      style={{ width: "100%" }}
                    />
                  </div>

                  <Fragment>
                    <FormControl
                      error={Boolean(error.recoveryCode)}
                      fullWidth={true}
                      margin="normal"
                    >
                      <InputLabel htmlFor="email">Recovery Code</InputLabel>
                      <Input
                        type="text"
                        name="recoveryCode"
                        value={recoveryCode}
                        onChange={this.onInputChange}
                        autoFocus
                      />
                      <FormHelperText>{error.recoveryCode}</FormHelperText>
                    </FormControl>

                    <FormControl
                      error={Boolean(error.password)}
                      fullWidth={true}
                      margin="normal"
                    >
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <Input
                        type="password"
                        name="password"
                        value={password}
                        onChange={this.onInputChange}
                      />
                      <FormHelperText>{error.password}</FormHelperText>
                    </FormControl>

                    <FormControl
                      error={Boolean(error.confirmPassword)}
                      fullWidth={true}
                      margin="normal"
                    >
                      <InputLabel htmlFor="confirmPassword">
                        Confirm Password
                      </InputLabel>
                      <Input
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={this.onInputChange}
                      />
                      <FormHelperText>{error.confirmPassword}</FormHelperText>
                    </FormControl>

                    <Grid
                      container
                      direction="row"
                      alignItems="flex-start"
                      justify="flex-end"
                      style={{ marginTop: 12 }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.onSubmit}
                        disabled={isLoading}
                      >
                        {/* {isLoading === true && <CircularProgress size={16} thickness={5} />} */}
                        Submit
                      </Button>
                    </Grid>

                    <Dialog
                      open={responseDialogOpen}
                      title={apiResponse.message}
                      content={apiResponse.result}
                      actions={
                        <Button
                          color="primary"
                          onClick={this.handleResponseDialogClose}
                        >
                          Close
                        </Button>
                      }
                    ></Dialog>
                  </Fragment>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
    }
}

const mapStateToProps = ({ ui, apiResponse}) => ({
    isLoading: ui && ui.updateUserPassword.isLoading,
    apiResponse: apiResponse && apiResponse.default
});

export default connect(mapStateToProps)(UpdatePasswordForm);