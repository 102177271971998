// Actions
import * as ProductAction from "../../actions/product.action";
import { apiRequestStart, apiRequestEnd } from "../../actions/ui.action";

// Endpoints
import { GET_PRODUCT_LIST } from "../endpoints/mthp.endpoints";

// Utilities
import { headers } from "../utilities/headers.utility";
import { Request } from "../../utils";

export function getProductList() {
  return async dispatch => {
    const trigger = "getProductList";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_PRODUCT_LIST
        },
        headers()
      );
      dispatch(ProductAction.getProductListSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(ProductAction.getProductListFailed(error.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}
