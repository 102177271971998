import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Warp from 'warp-sdk-js';

import AppRouter from './routers/AppRouter';
import configureStore from './store/configure-store';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from './components/Theme';
import * as serviceWorker from './serviceWorker';

// Initialize Warp API Connection
Warp.initialize({
    baseURL: process.env.REACT_APP_WARP_API_URL,
    apiKey: process.env.REACT_APP_WARP_API_KEY,
    timeout: 60
});

// Initialize Redux Store
const store = configureStore();

const jsx = (
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <AppRouter />
        </MuiThemeProvider> 
    </Provider>
);

ReactDOM.render(jsx, document.getElementById('app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();