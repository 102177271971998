// Actions
import * as PromotionAction from "../../actions/promotion.action";
import { apiRequestStart, apiRequestEnd } from "../../actions/ui.action";

// Endpoints
import {
  GET_PROMOTION_DETAILS,
  GET_PROMOTION_LIST,
  UPDATE_PROMOTION_ALLOCATION
} from "../endpoints/mthp.endpoints";

// Utilities
import { headers } from "../utilities/headers.utility";
import { Request } from "../../utils";

export function getPromotionDetails(promotionId) {
  return async dispatch => {
    const trigger = "getPromotionDetails";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_PROMOTION_DETAILS(promotionId)
        },
        headers()
      );
      dispatch(PromotionAction.getPromotionDetailsSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(
          PromotionAction.getPromotionDetailsFailed(error.response.data)
        );
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function getPromotionList({search, page = 1, limit = 10} = {}) {
  return async dispatch => {
    const trigger = "getPromotionList";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_PROMOTION_LIST,
          params: {
            search,
            page,
            limit
          }
        },
        headers()
      );
      dispatch(PromotionAction.getPromotionListSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(PromotionAction.getPromotionListFailed(error));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function updatePromotionAllocation({ promotionId, updateValues }) {
  return async dispatch => {
    const trigger = "updatePromotionAllocation";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "POST",
          url: UPDATE_PROMOTION_ALLOCATION(promotionId),
          data: {
            promotion_id: promotionId,
            allowed_users: updateValues.allowed_users,
            has_user_segment: updateValues.info.has_user_segment,
            user_segment: updateValues.info.user_segment
          }
        },
        headers()
      );
      dispatch(PromotionAction.updatePromotionAllocationSucces(data));
    } catch (error) {
      if (error.response)
        dispatch(
          PromotionAction.updatePromotionAllocationFailed(error.response.data)
        );
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}
