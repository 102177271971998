import axios from "axios";
import moment from "moment-timezone";

// axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
const defaultDateTimeFormat = "MMM DD, YYYY hh:mm:ss A";
const defaultTimeZone = process.env.DEFAULT_TIMEZONE || "Asia/Kuala_Lumpur";

/**
 * Date and Time Formatter
 * @param {string} dateTime - date and time string
 * @param {string} timezone - only accepts 'moment' timezone
 * @param {string} format - only accepts 'moment' format
 */
export const dateTimeFormat = (
  dateTime,
  timezone = defaultTimeZone,
  format = defaultDateTimeFormat
) => {
  // VERIFY if timezone is null or undefind ('==' is recommended).
  if (timezone == null) timezone = defaultTimeZone;
  // VERIFY if format is null or undefind ('==' is recommended).
  else if (defaultDateTimeFormat == null) format = defaultDateTimeFormat;

  return dateTime
    ? moment(dateTime)
        .tz(timezone)
        .format(format)
    : "N/A";
};

/**
 * Request utility using axios
 */
export const Request = async ({ method, url, params, data }, header) => {
  return await axios({
    headers: {
      ...(header ? header : "")
    },
    method,
    url,
    params, // get
    data // post
  });
};

/**
 * Conversion of camelCase to snake_case
 */
export const toSnakeCase = string =>
  string
    .replace(/\.?([A-Z]+)/g, word => "_" + word.toLowerCase())
    .replace(/^_/, "");

/**
 * Converting Array to Objects
 */
export const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));

/**
 * Determine the Project ID
 */
export const projectId = props => {
  const pathName = props.location.pathname.split("/")[1];
  switch (pathName) {
    case "mthp":
      return 1;
    case "stayon":
      return 2;
    case "mycare":
      return 3;
    default:
      return 0;
  }
};

/**
 * Determine the Promotion ID
 */
export const promotionId = props => {
  const pathName = props.location.pathname.split("/")[1];
  switch (pathName) {
    case "mthp":
      return 1;
    case "stayon":
      return 3;
    case "mycare":
      return 4;
    default:
      return 0;
  }
};
