import React from 'react';
import { NavLink } from 'react-router-dom';

// Material UI - Core
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'; 

const NavList = props => {
    const { items } = props;
    return items.map(({ label, path, icon }, index) => (
        <ListItem key={index} component={NavLink} to={path} activeClassName='is-active' button exact>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label} />
        </ListItem>
    ))
}

export default NavList;