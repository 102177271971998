import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import { Grid, Button, IconButton, LinearProgress } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import AddHCPForm from "../../components/forms/add-hcp.form";

// Custom Components
import Dialog from "../../components/Dialog";
import Table from "../../components/ReactTable";

// Store
import * as hcpStore from "../actionCreators/hcp.actionCreator";

class HCPListPage extends Component {
  state = {
    currentPage: 1,
    previousSearch: "",
    searchInput: "",
    isAddDialogOpen: false,
    isResponseDialogOpen: false
  };

  async componentDidMount() {
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== prevProps.history.location.search) {
      this.setState({ previousSearch: prevProps.history.location.search });
      await this._loadData();
    }
  }

  _loadData = async () => {
    const { pathname: currentPath } = this.props.location;
    const params = queryString.parse(this.props.location.search) || {};
    const { searchInput } = this.state;

    this.setState({ searchInput: params.search ? params.search : "" });

    await this.props.getHCPList({ ...params });

    if (params.page <= this.props.hcpPages && params.page > 0) {
      this.setState({ currentPage: params.page });

    } else if (this.props.hcpList.length > 0) {
      this.props.history.push(
        `${currentPath}?page=1${searchInput && "&search=" + searchInput}`
      );
    }
  };

  onAddHCP = async data => {
    const {
      addHCP,
      getHCPList
    } = this.props;
    const params = queryString.parse(this.props.location.search) || {};

    this.setState({ isAddDialogOpen: false });
    await addHCP(data);
    await getHCPList({ ...params });
    
    this.setState({ isResponseDialogOpen: true });
  }

  _onInputSearch = e => this.setState({ searchInput: e.target.value });

  _onSubmitSearch = e => {
    e.preventDefault();
    const { pathname: currentPath } = this.props.history.location;
    const { searchInput } = this.state;

    this.props.history.push(
      `${currentPath}?page=1${searchInput && "&search=" + searchInput}`
    );
  };

  _onSelectHCP = userId =>
    this.props.history.push(`${this.props.match.path}/${userId}`);

  handleAddDialogVisibility = boolean => this.setState({ isAddDialogOpen: boolean });
  
  handleResponseDialogVisibility = boolean => {
    if (this.props.apiResponse && this.props.apiResponse.status === 200) {
      this.setState({ isResponseDialogOpen: boolean });
    } else {
      this.setState({ isResponseDialogOpen: boolean, isAddDialogOpen: true });
    }
  };

  render() {
    const { hcpCount, hcpList, ui, apiResponse } = this.props;
    const { currentPage, isAddDialogOpen, isResponseDialogOpen } = this.state;

    const columns = [
      {
        Header: "ID",
        accessor: "user_id"
      },
      { Header: "Name", accessor: "full_name" },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Mobile",
        accessor: "mobile"
      },
      {
        Header: "Status",
        accessor: "status"
      },
      {
        Header: "View HCP",
        Cell: props => (
          <IconButton
            size="small"
            color="primary"
            onClick={() => this._onSelectHCP(props.original.user_id)}
          >
            <ArrowForwardIcon />
          </IconButton>
        )
      }
    ];

    return (
      <Grid container>
        <Grid item xs={12}
          container
          direction="row"
          alignItems="center"
          justify="space-between">
          <h4>Healthcare Professionals</h4>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              this.handleAddDialogVisibility(true);
            }}
          >
            <AddIcon />
                        Add HCP
                    </Button>
        </Grid>

        <AddHCPForm
          open={isAddDialogOpen}
          title='Add Healthcare Professional'
          onClick={this.onAddHCP}
          onClose={() => this.handleAddDialogVisibility(false)}
        />

        <Dialog
          open={isResponseDialogOpen}
          title={apiResponse.message}
          content={apiResponse.result}
          actions={
            <Button
              color="primary"
              onClick={() => this.handleResponseDialogVisibility(false)}
            >
              Ok
            </Button>
          }
        />

        <Grid item xs={12}>
          {ui.getHCPList.isLoading ? (
            <LinearProgress />
          ) : (
              <Table
                columns={columns}
                count={hcpCount}
                currentPage={currentPage}
                data={hcpList}
                search={{
                  onChange: e => this._onInputSearch(e),
                  onSubmit: e => this._onSubmitSearch(e),
                  value: this.state.searchInput
                }}
              />
            )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ hcp, ui, apiResponse }) => ({
  hcpCount: hcp.count,
  hcpList: hcp.list,
  hcpPages: hcp.pages,
  ui,
  apiResponse: apiResponse.default
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...hcpStore }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HCPListPage);
