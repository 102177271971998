import moment from 'moment-timezone';
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from "query-string";
import {
    Grid, /* Checkbox,*/ Typography,
    AppBar, Tabs, Tab,
    FormControl,
    LinearProgress
} from '@material-ui/core';

// Custom Components
import ExportClientReportButton from '../components/ExportClientReport.Button';
import { Select } from '../components';
import Table from "./../components/ReactTable";

// Store
import * as paymentStore from '../mthp/actionCreators/payment.actionCreator';

// TODO: Create/convert this to a custom component.
const TabContainer = props => (
    <Typography component="div">
        {props.children}
    </Typography>
);

// FIXME: Fix bug in pagination that affects other tables.
class ClientPaymentReport extends Component {
    state = {
        report: "client_monthly_payment_reports",
        selectedTab: "payment_summary", // available tabs - ["payment_summary", "transaction_list", "transaction_summary"]
        currentPage: {
            payment_summary: 1,
            transaction_list: 1,
            transaction_summary: 1
        },
        rowsPerPage: 10,
        searchInput: "",
        beginDate: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        availableMonths: [],
        selectedMonth: {
            label: moment().local().format('YYYY-MMMM'),
            value: moment().local().format('YYYY-MM')
        },
        previousSearch: "",
        isEndOfPage: {
            payment_summary: 'paymentSummaryPages',
            transaction_list: 'transactionPages',
            transaction_summary: 'transactionSummaryPages'
        },
        lengthOfData: {
            payment_summary: 'paymentSummaryList',
            transaction_list: 'transactionList',
            transaction_summary: 'transactionSummary'
        }
    }


    async componentDidMount() {
        await this._loadData();
    }

    async componentDidUpdate() {
        if (this.props.previousSearch !== this.state.previousSearch) {
          this.setState({ previousSearch: this.props.previousSearch });
          await this._loadData();
        }
    }

    _loadData = async () => {
        const { pathname: currentPath } = this.props.history.location;
        let params = queryString.parse(this.props.location.search) || {};

        const { report, selectedTab, currentPage, rowsPerPage, searchInput, beginDate, endDate, availableMonths, isEndOfPage, lengthOfData } = this.state;

        
        if(availableMonths.length === 0){
            // Initialize Dropdown for Months.
            // Initial and current date should be in UTC.
            let initialDate = moment('2017-10-01 00:00:00').utc();
            const currentDate = moment().utc();
            const availableMonths = [];

            while (initialDate < currentDate) {
                // Format to React-Select notation.
                availableMonths.push({
                    label: moment(initialDate).local().format('YYYY-MMMM'),
                    value: moment(initialDate).local().format('YYYY-MM')
                });
                
                // Convert dates from UTC to local.
                initialDate = moment(initialDate).local().add(1, 'months');
            }

            // Reverse sort the array.
            availableMonths.reverse();

            this.setState({ availableMonths });
        }
        
        // Retrieve Table Contents.
        if(selectedTab === "payment_summary"){
            await this.props.getClientPaymentSummaryList({ 
                page: params.page,
                limit: rowsPerPage,
                search: searchInput, 
                beginDate, 
                endDate 
            });
    
        } else {
            await this.props.getClientPaymentTransactionDetails({
                page: params.page,
                limit: rowsPerPage,
                search: searchInput,
                beginDate,
                endDate
            });
        }

        if (params.page <= this.props[isEndOfPage[selectedTab]] && params.page > 0) {
            this.setState({
                currentPage: {
                    ...this.state.currentPage,
                    [params.tab]: params.page,
                },
            });
            
        } else if(this.props[lengthOfData[selectedTab]].length > 0) {
            this.props.history.push(
                `${currentPath}?report=${report}&tab=${selectedTab}&page=${currentPage[selectedTab]}${searchInput && "&search=" + searchInput}`
            );
        } else {
            this.props.history.push(
                `${currentPath}?report=${report}&tab=${selectedTab}&page=1${searchInput && "&search=" + searchInput}`
            );
        }

    };

    handleSelectTab = (event, value) => {
        this.setState({ 
            selectedTab: value,
            currentPage: {
                payment_summary: 1,
                transaction_list: 1,
                transaction_summary: 1
            } 
        })

        const { pathname: currentPath } = this.props.history.location;
        const { report, searchInput } = this.state;

        this.props.history.push(
            `${currentPath}?report=${report}&tab=${value}&page=1${searchInput && "&search=" + searchInput}`
        );
    };

    onSelectChange = async (selected, props) => {
        const beginDate =  moment(selected.value).startOf('month').format('YYYY-MM:DD HH:mm:ss');
        const endDate = moment(selected.value).endOf('month').format('YYYY-MM:DD HH:mm:ss');

        const { selectedTab, currentPage, searchInput, rowsPerPage } = this.state;
        
        this.setState({
            [props.name]: selected,
            beginDate,
            endDate,
            currentPage: {
                payment_summary: 1,
                transaction_list: 1,
                transaction_summary: 1
            },
            rowsPerPage
        });

        // Retrieve Table Contents.
        if(selectedTab === "payment_summary"){
            await this.props.getClientPaymentSummaryList({ 
                page: 1,
                limit: rowsPerPage,
                search: searchInput, 
                beginDate, 
                endDate 
            });
        } else {
            await this.props.getClientPaymentTransactionDetails({
                page: 1,
                limit: rowsPerPage,
                search: searchInput,
                beginDate,
                endDate
            });
        }
    
        
    }

    _onInputSearch = e => this.setState({ searchInput: e.target.value });

    _onSubmitSearch = e => {
      e.preventDefault();
      const { pathname: currentPath } = this.props.history.location;
      const { report, selectedTab, searchInput } = this.state;
  
      this.props.history.push(
        `${currentPath}?report=${report}&tab=${selectedTab}&page=1${searchInput && "&search=" + searchInput}`
    );
    };

    render() {
        const paymentSummaryColumns = [
            { Header: "Payment Summary User ID", accessor: "id" },
            { Header: "User Type", accessor: "user_type" },
            { Header: "Email", accessor: "email" },
            { Header: "Organization ID", accessor: "organization_id" },
            { Header: "Organization Branch", accessor: "organization_branch" },
            { Header: "Payment Entity ID", accessor: "payment_entity_id" },
            { Header: "GST Enabled", accessor: "tax_enabled" },
            { Header: "Rebate Amount (w/o GST)", accessor: "rebate_amount" },
            { Header: "GST Amount", accessor: "tax_amount" },
            { Header: "Total Rebate", accessor: "total_rebate_amount" },
            { Header: "Action Key", accessor: "action_key" },
            { Header: "Action Status", accessor: "action_status" },
            { Header: "Action Date", accessor: "action_date" }
        ];

        const transactionListColumns = [
            { Header: "Transaction ID", accessor: "transaction_campaign_id" },
            { Header: "Patient Created At", accessor: "patient_created_at" },
            { Header: "Patient Code", accessor: "patient_code" },
            { Header: "Patient ID", accessor: "patient_id" },
            { Header: "User Type", accessor: "user_type" },
            { Header: "Organization ID", accessor: "organization_id" },
            { Header: "Organization Branch", accessor: "organization_branch" },
            { Header: "Payment Entity ID", accessor: "payment_entity_id" },
            { Header: "GST Enabled", accessor: "tax_enabled" },
            { Header: "Product", accessor: "product" },
            { Header: "Quantity", accessor: "quantity_display" },
            { Header: "Rebate Amount (w/o GST)", accessor: "transaction_discount_amount" },
            { Header: "GST Amount", accessor: "tax_amount" },
            { Header: "Total Rebate", accessor: "total_discount_amount" },
            { Header: "Transaction Date", accessor: "transaction_created_at" },
            { Header: "Description", accessor: "description" },
        ];

        const transactionSummaryColumns = [
            { Header: "Payment Entity ID", accessor: "payment_entity_id" },
            { Header: "Organization ID", accessor: "organization_id" },
            { Header: "Organization Name", accessor: "organization_branch" },
            { Header: "Product", accessor: "product" },
            { Header: "Number of Unique Patients", accessor: "patient_count" },
            { Header: "Number of Transactions", accessor: "transaction_count" },
            { Header: "GST Enabled", accessor: "tax_enabled" },
            { Header: "Rebate Amount (w/o GST)", accessor: "transaction_discount_amount" },
            { Header: "GST Amount", accessor: "tax_amount" },
            { Header: "Total Rebate", accessor: "total_discount_amount" },
            { Header: "Description", accessor: "description" }
        ];

        const {
            paymentSummaryList, paymentSummaryCount, paymentSummaryPages,
            transactionCount, transactionList,
            transactionSummaryCount, transactionSummary, ui
        } = this.props;
        const {
            availableMonths, beginDate, endDate, selectedMonth, // Dropdown for available months
            currentPage, selectedTab
        } = this.state;

        return (
            <Fragment>
                <Grid container spacing={16}>
                    <Grid item sm={6} xs={12}>
                        <FormControl fullWidth
                            margin='dense'>
                            <Select isSearchable
                                isLoading={availableMonths.length === 0}
                                name='selectedMonth'
                                onChange={this.onSelectChange}
                                options={availableMonths}
                                placeholder='Select Month'
                                value={selectedMonth} />
                        </FormControl>
                    </Grid>

                    <Grid item sm={3} xs={12}>
                        <ExportClientReportButton
                            selectedMonth={selectedMonth.value}
                            beginDate={beginDate}
                            endDate={endDate} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppBar position='relative' style={{ zIndex: 1 }}>
                            <Tabs value={selectedTab} onChange={this.handleSelectTab}>
                                <Tab label='Payment Summary' value="payment_summary"/>
                                <Tab label='Transaction List' value="transaction_list"/>
                                <Tab label='Transaction Summary' value="transaction_summary"/>
                            </Tabs>
                        </AppBar>
                        {selectedTab === "payment_summary" && (
                            <TabContainer>
                                {ui.getClientPaymentSummaryList.isLoading ? (
                                    <LinearProgress />
                                ) : (

                                        <Table
                                            columns={paymentSummaryColumns}
                                            count={paymentSummaryCount}
                                            currentPage={currentPage.payment_summary}
                                            data={paymentSummaryList}
                                            search={{
                                                onChange: e => this._onInputSearch(e),
                                                onSubmit: e => this._onSubmitSearch(e),
                                                value: this.state.searchInput
                                            }}
                                        />

                                    )}
                            </TabContainer>
                        )}
                        {selectedTab === "transaction_list" && (
                            <TabContainer>
                                {ui.getClientPaymentTransactionDetails.isLoading ? (
                                    <LinearProgress />
                                ) : (
                                    <Table
                                        columns={transactionListColumns}
                                        count={transactionCount}
                                        currentPage={currentPage.transaction_list}
                                        data={transactionList}
                                        search={{
                                            onChange: e => this._onInputSearch(e),
                                            onSubmit: e => this._onSubmitSearch(e),
                                            value: this.state.searchInput
                                        }}
                                    />

                                    )}
                            </TabContainer>
                        )}
                        {selectedTab === "transaction_summary" && (
                            <TabContainer>
                                {ui.getClientPaymentTransactionDetails.isLoading ? (
                                    <LinearProgress />
                                ) : (
                                    <Table
                                        columns={transactionSummaryColumns}
                                        count={transactionSummaryCount}
                                        currentPage={currentPage.transaction_summary}
                                        data={transactionSummary}
                                        search={{
                                            onChange: e => this._onInputSearch(e),
                                            onSubmit: e => this._onSubmitSearch(e),
                                            value: this.state.searchInput
                                        }}
                                    />

                                    )}
                            </TabContainer>
                        )}
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ payment, ui }) => ({
    paymentSummaryList: payment.clientPaymentSummaryList,
    paymentSummaryCount: payment.clientPaymentSummaryCount,
    paymentSummaryPages: payment.clientPaymentSummaryPages,
    transactionList: payment.transactionList,
    transactionCount: payment.transactionCount,
    transactionPages: payment.transactionPages,
    transactionSummary: payment.transactionSummary,
    transactionSummaryCount: payment.transactionSummaryCount,
    transactionSummaryPages: payment.transactionSummaryPages,
    ui
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...paymentStore
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientPaymentReport));