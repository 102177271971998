import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    Grid, Card, CardContent, Typography, Button, LinearProgress
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { dateTimeFormat } from '../../utils';

// Actions
import {
    getPromotionDetails,
    updatePromotionAllocation
} from '../actionCreators/promotion.actionCreator';

// Custom Components
import Dialog from '../../components/Dialog';
import { HeaderTypography, LabelTypography } from '../../components/Typography';
import PromotionAllocationForm from '../../components/forms/promotion-allocation.form';

class PromotionDetails extends Component {
    state = {
        editUserSegmentDialogOpen: false,
        responseDialogOpen: false
    }

    componentDidMount() {
        const { dispatch, match } = this.props;
        const promotionId = match.params.promotionId;
        dispatch(getPromotionDetails(promotionId));
    }

    handleEditUserSegment = boolean => this.setState({ editUserSegmentDialogOpen: boolean });
    handleResponseDialogOpen = boolean => this.setState({ responseDialogOpen: boolean });
    
    onSubmitUpdateUserSegment = ({ allowedUsers, hasUserSegment, userSegment }) => {
        const { dispatch, match } = this.props;
        const promotionId = match.params.promotionId;
        let updateValues = {};
        updateValues = {
            ...{ allowed_users: allowedUsers },
            info: {
                ...this.props.configurations,
                ...{
                    has_user_segment: hasUserSegment,
                    user_segment: userSegment
                }
            }
        }

        dispatch(updatePromotionAllocation({ promotionId, updateValues }))
        .then(() => {
            this.setState({ editUserSegmentDialogOpen: false, responseDialogOpen: true });
            dispatch(getPromotionDetails(promotionId));
        });
    };

    render() {
        const { apiResponse, configurations, details, ui } = this.props;
        const { editUserSegmentDialogOpen, responseDialogOpen } = this.state;
        let content = null;
        
        /**
         * @desc Promotion Validation
         */
        if (!details)
            content = (
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Typography>Promotion does not exist.</Typography>
                    </Grid>
                </Grid>
            )
        else {
            const {
                id, name, code: promotionCode, 
                allowed_users: allowedUsers, 
                promotion_type: promotionType, 
                begin_at: beginAt, end_at: endAt,
                payment_required: paymentRequired,
                created_at: createdAt, updated_at: updatedAt, deleted_at: deletedAt
            } = details;

            /**
             * @desc Promotion Details (Additional Configuration)
             */
            const promotionConfiguration = configurations => {
                if (configurations) {
                    const { 
                        available_forms: availableForms = [],
                        discount_option: discountOption = {},
                        has_user_segment: hasUserSegment = false,
                        messages_available: messagesAvailable = {},
                        terms_and_conditions_link: termsAndConditionsLink = {},
                        user_segment: userSegment = {}
                    } = configurations;

                    const availableFormsContent = availableForms && availableForms.length > 0 
                        ? <Typography>{availableForms.toString()}</Typography>
                        : <Typography>N/A</Typography>;

                    const discountOptionContent = discountOption
                        ? Object.entries(discountOption).map(([type, value]) => (
                            <Fragment key={type}>
                                <Grid item xs={3}>
                                    <Typography>{type}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography>{value.toString()}</Typography>
                                </Grid>
                            </Fragment>
                        ))
                        : <Typography>N/A</Typography>

                    const messagesAvailableContent = messagesAvailable
                        ? Object.entries(messagesAvailable).map(([type, value]) => (
                            <Fragment key={type}>
                                <Grid item xs={3}>
                                    <Typography>{type}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography>{value.toString()}</Typography>
                                </Grid>
                            </Fragment>
                        ))
                        : <Typography>N/A</Typography>

                    const termsAndConditionsLinkContent = termsAndConditionsLink
                        ? Object.entries(termsAndConditionsLink).map(([type, value]) => (
                            <Fragment key={type}>
                                <Grid item xs={3}>
                                    <Typography>{type}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography><a href={value['url']} target='_blank' rel="noopener noreferrer">{value['url']}</a></Typography>
                                </Grid>
                            </Fragment>
                        ))
                        : <Typography>N/A</Typography>

                    const userSegmentContent = userSegment
                        ? Object.entries(userSegment).map(([type, value]) => (
                            <Fragment key={type}>
                                <Grid item xs={3}>
                                    <Typography>{type}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography>{value.toString()}</Typography>
                                </Grid>
                            </Fragment>
                        ))
                        : <Typography>N/A</Typography>

                    return (
                        <Fragment>
                            <Grid item xs={3}>
                                <LabelTypography>Available Forms:</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                {availableFormsContent}
                            </Grid>
                            <Grid item xs={12}>
                                <LabelTypography>Discount Options:</LabelTypography>
                            </Grid>
                            {discountOptionContent}
                            <Grid item xs={3}>
                                <LabelTypography>Has User Segment:</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{hasUserSegment.toString()}</Typography>
                            </Grid><Grid item xs={12}>
                                <LabelTypography>Messages Available:</LabelTypography>
                            </Grid>
                            {messagesAvailableContent}
                            <Grid item xs={12}>
                                <LabelTypography>Terms and Conditions Link</LabelTypography>
                            </Grid>
                            {termsAndConditionsLinkContent}
                            <Grid item xs={12}>
                                <LabelTypography>User Segment:</LabelTypography>
                            </Grid>
                            {userSegmentContent}
                        </Fragment>
                    )
                }
                else
                    return <Typography>There are no configurations for this promotion.</Typography>;
            }
            
            /**
             * @desc Promotion Details
             */
            const promotionDetails = (
                <Card>
                    <CardContent>
                        <Grid container direction='row' spacing={16}>
                            <Grid item xs={12}>
                                <HeaderTypography color='primary'>Promotion Details</HeaderTypography>
                            </Grid>
                            <Grid item xs={3}>
                                <LabelTypography>Promotion ID:</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{id}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LabelTypography>Name:</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LabelTypography>Code:</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{promotionCode}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LabelTypography>Allowed Users:</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{allowedUsers}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LabelTypography>Promotion Type:</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{promotionType}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LabelTypography>Begin At:</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{beginAt}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LabelTypography>End At:</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{endAt}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LabelTypography>Payment Required</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{paymentRequired ? paymentRequired.toString() : 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LabelTypography>Created At:</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{dateTimeFormat(createdAt)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LabelTypography>Updated At:</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{dateTimeFormat(updatedAt)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LabelTypography>Deleted At:</LabelTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{dateTimeFormat(deletedAt)}</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <HeaderTypography color='primary'>Promotion Configurations</HeaderTypography>
                            </Grid>
                            {promotionConfiguration(configurations)}
                        </Grid> 
                    </CardContent>
                </Card>
            );

            content = (
                <Fragment>
                    <Grid container
                        direction='row'
                        alignItems='center'
                        justify='space-between'>
                        <h4>{name} ({promotionCode})</h4>
                        <Button size='small' variant="contained" color="primary"
                            onClick={() => this.handleEditUserSegment(true)}>
                            <EditIcon /> Edit User Segment
                        </Button>
                    </Grid>

                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            {promotionDetails}
                        </Grid>
                    </Grid>

                    { editUserSegmentDialogOpen ?

                    <PromotionAllocationForm 
                        configurations={{
                            allowed_users: allowedUsers,
                            ...configurations
                        }}
                        open={editUserSegmentDialogOpen}
                        title='Update User Segment Allocation'
                        onClick={this.onSubmitUpdateUserSegment}
                        onClose={() => this.handleEditUserSegment(false)}/> : null
                    }

                    <Dialog
                        open={responseDialogOpen}
                        title={apiResponse.message}
                        content={apiResponse.result}
                        actions={<Button onClick={() => this.handleResponseDialogOpen(false)}>Ok</Button>} />
                </Fragment>
            )
        }

        return (
            <Fragment>
                { ui.getPromotionDetails.isLoading ? 
                    <LinearProgress /> :
                    content }
            </Fragment>
        )
    }
}

const mapStateToProps = ({ apiResponse, promotion, ui }) => ({
    apiResponse: apiResponse && apiResponse.default,
    configurations: promotion.configurations,
    details: promotion.details && promotion.details.result,
    ui
});

export default connect(mapStateToProps)(PromotionDetails);