import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, Switch, Route } from "react-router-dom";
import {
    Grid, IconButton, LinearProgress, Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import queryString from 'query-string';

// Actions
import * as hcpStore from '../actions/hcp.action';

// Components
import HcpCreationForm from "./hcp.create.form";
import Table from '../../components/ReactTable';
import { bindActionCreators } from 'redux';

const styles = theme => ({
    addHcpButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
});

class HCPListPage extends Component {
    state = {
        currentPage: 1,
        rowsPerPage: 10,
        searchInput: "",
        previousSearch: ""
    };

    async componentDidMount() {
        await this._loadData();
    }


    async componentDidUpdate(){
        if (this.state.previousSearch !== this.props.history.location.search) {
            this.setState({ previousSearch: this.props.history.location.search });
            await this._loadData();
        }
    }

    _loadData = async () => {
        const {pathname: currentPath} = this.props.history.location;
        let params = queryString.parse(this.props.location.search) || {};
    
        this.setState({ searchInput: params.search ? params.search : '' });

        await this.props.getHCPList({...params});
        
        if (params.page <= this.props.hcpList && params.page > 0) {
            this.setState({ currentPage: params.page });
        } else if(this.props.hcpList.length > 0){
            this.props.history.push(`${currentPath}?page=1`);
        }
    }

    _onInputSearch = e => this.setState({ searchInput: e.target.value });

    _onSubmitSearch = e => {
        e.preventDefault();
        const { pathname: currentPath } = this.props.history.location;
        const { searchInput } = this.state;

        this.props.history.push(`${currentPath}?page=1${searchInput && '&search='+searchInput}`);
    };

    onSelectHCP = userId => this.props.history.push(`${this.props.match.path}/${userId}`);

    render() {
        const { hcpCount, hcpList, ui, classes } = this.props;
        const {currentPage} = this.state;

        const columns = [
            { Header: "ID", accessor: "user_id" },
            { Header: "Name", accessor: "full_name" },
            { Header: "Email", accessor: "email" },
            { Header: "Mobile", accessor: "mobile" },
            { Header: "Status", accessor: "status" },
            { 
                Header: "View HCP",
                Cell: props => (
                    <IconButton 
                        size='small' 
                        color='primary'
                        onClick={() => this.onSelectHCP(props.original.user_id)}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                ) 
            }
        ]; 

        return (
            <Fragment>
                <Grid container>
                    <Grid item xs={6}>
                        <h4>Healthcare Professionals</h4>
                    </Grid>
                    <Grid item xs={6} className={classes.addHcpButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            component={(props) => <Link to='/stayon/hcps/add' {...props} />}>
                            Add HCP
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            ui.getHCPList.isLoading
                                ? <LinearProgress />
                                : (
                                    <Table
                                        columns={columns}
                                        count={hcpCount}
                                        currentPage={currentPage}
                                        data={hcpList}
                                        search={{
                                            onChange: e => this._onInputSearch(e),
                                            onSubmit: e => this._onSubmitSearch(e),
                                            value: this.state.searchInput
                                        }}
                                    />
                                )
                        }
                    </Grid>
                </Grid>
                <Switch>
                    <Route path="/stayon/hcps/add" component={HcpCreationForm} />
                </Switch>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ hcp, ui }) => ({
    hcpCount: hcp.count,
    hcpList: hcp.list,
    ui
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...hcpStore
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(HCPListPage));
