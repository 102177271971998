import React from 'react';
import moment from 'moment-timezone';
import MuiDatePicker from 'material-ui-pickers/DatePicker';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';

const DatePicker = props => { 
    const { 
        autoOk = true,
        clearable = true,
        // defaultValue = moment().format('YYYY-MM-DD'),
        format = 'YYYY-MM-DD',
        fullWidth = false, 
        label = 'Date',
        maxDateMessage = 'Date should not be after the current date',
        name, 
        onChange,
        showTodayButton = false,
        value = moment().format('YYYY-MM-DD')
    } = props;

    //NOTE: Always import moment-timezone library when using this MUI Picker
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiDatePicker
                animateYearScrolling
                autoOk={autoOk}
                clearable={clearable}
                format={format}
                fullWidth={fullWidth}
                keyboard
                label={label}
                maxDateMessage={maxDateMessage}
                name={name}
                onChange={onChange}
                showTodayButton={showTodayButton}
                value={value}
                {...props} />
        </MuiPickersUtilsProvider>
    );
}

export default DatePicker;