import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

// Material UI
import {
  TablePagination as MuiTablePagination,
  Paper
} from "@material-ui/core";

// Custom Components
import TableToolbar from "./TableToolbar";

// React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

// Mui Pagination's Page beging with 0 index reason why the currentPage is reduced by 1.
const muiCurrentPage = currentPage => currentPage - 1;

class Table extends Component {
  state = {
    page: this.props.currentPage - 1, // decremented by 1 since Material UI pagination starts with index 0.
    rowsPerPage: 10,
    rowsPerPageOptions: [10] // [10, 50, 100]
  };

  _onChangePage = (event, page) => {
    const { pathname: currentPath } = this.props.location;
    let params = queryString.parse(this.props.history.location.search);

    let locationString = this.props.history.location.search;
    let locationSearchString = locationString.substring(0, locationString.indexOf('page='));

    let checkedSearch = locationSearchString.includes('?') > -1 && locationSearchString !== "";

    if("search" in params){
      if(this.props.data.length > 0) {
        this.props.history.push(`${currentPath}${checkedSearch ? locationSearchString : "?"}page=1&search=${params.search}`);
      }
      
    } else {
      const nextPage = parseInt(page, 10) + 1;
      this.props.history.push(`${currentPath}${checkedSearch ? locationSearchString : "?"}page=${nextPage}`);
    }

  };

  _onChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  pagination = () => {
    // add current page params here
    let params = queryString.parse(this.props.history.location.search);
    let currentPage = muiCurrentPage(this.props.currentPage || params.page);

    const { rowsPerPage, rowsPerPageOptions } = this.state;

    return (
      <MuiTablePagination
        backIconButtonProps={{ "aria-label": "Previous Page" }}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        component="div"
        count={this.props.count}
        onChangePage={this._onChangePage}
        onChangeRowsPerPage={this._onChangeRowsPerPage}
        page={currentPage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    );
  };

  render() {
    return (
      <Paper>
        <TableToolbar search={this.props.search} />
        <ReactTable
          data={this.props.data}
          columns={this.props.columns}
          defaultPageSize={10}
          showPageSizeOptions={false}
          PaginationComponent={this.pagination}
        />
      </Paper>
    );
  }
}

export default withRouter(connect()(Table));
