import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isJSON } from 'validator';
import {
    Grid, Card, CardContent, Button, Typography,
    TableRow, TableCell,
    AppBar, Tabs, Tab, LinearProgress, Chip
} from '@material-ui/core';
import { dateTimeFormat } from '../../utils';

// Custom Components
import Dialog from '../../components/Dialog';
import Table from '../../components/Table';
import { HeaderTypography, LabelTypography } from '../../components/Typography';
import HCPDetailsForm from '../../components/forms/hcp-details.form';

// Store
import * as hcpStore from '../actionCreators/hcp.actionCreator';
import * as transactionStore from '../actionCreators/transaction.actionCreator';
import * as userStore from '../actionCreators/user.actionCreator';

// TODO: Create/convert this to a custom component.
const TabContainer = props => (
    <Typography component="div">
        {props.children}
    </Typography>
);

const isOrganizationEmailJSON = organizationEmail => organizationEmail && typeof organizationEmail === 'string' && isJSON(organizationEmail);
const parsedOrganizationEmail = organizationEmail => isOrganizationEmailJSON(organizationEmail) && Array.isArray(JSON.parse(organizationEmail)) 
    ? JSON.parse(organizationEmail).toString() 
    : organizationEmail
    || 'N/A';

const ChipStatus = props => {
    switch (props.status) {
        case 'active':
            return <Chip label='Active' style={{ background: '#388e3c', color: '#fff' }} />
        case 'cancelled':
            return <Chip label='Cancelled' style={{ background: '#0288d1', color: '#fff' }} />
        case 'deactivated': 
            return <Chip label='Deactivated' style={{ background: '#d32f2f', color: '#fff' }} />
        default:
            break;
    }
}

class HCPDetails extends Component {
    state = {
        selectedTabValue: 0,
        transactionBatchId: 0,
        userStatus: null,
        patientList: [],
        transactionList: [],
        isEditDialogOpen: false,
        editUserStatusDialogOpen: false,
        isResponseDialogOpen: false,
        voidDialogOpen: false
    };

    async componentDidMount() {
        const {
            getHCPDetails, getHCPNumberOfTransactions, getHCPPatientList, getHCPTransactions,
            match
        } = this.props;
        const userId = match.params.userId;

        await getHCPDetails(userId);
        await getHCPNumberOfTransactions(userId);
        await getHCPPatientList(userId, 2); // FIXME: Remove hard-coded value. There should be a page that will show the promotions where the user is enrolled.
        await getHCPTransactions(userId);

        /**
         * @desc Transaction List
         */
        const transactions = this.props.transactionList.map(transaction => {
                const products = transaction.transactionCampaigns.reduce((accumulator, currentValue) => {
                    accumulator.push(`${currentValue.productFullName} ${currentValue.campaignProductQuantityPackaging}`);

                    return accumulator;
                }, []).join(', ');

                return {
                    ...transaction,
                    products,
                    createdAt: dateTimeFormat(transaction.createdAt)
                }
            });

 
        this.setState({ transactionList: transactions });
    }

    handleSelectTab = (event, value) => this.setState({ selectedTabValue: value });

    handleEditDialogVisibility = boolean => this.setState({ isEditDialogOpen: boolean });
    handleEditUserStatusDialogOpen = boolean => this.setState({ editUserStatusDialogOpen: boolean });
    handleResponseDialogVisibility = boolean => this.setState({ isResponseDialogOpen: boolean });
    handleVoidDialogOpen = boolean => this.setState({ voidDialogOpen: boolean });

    onClickUpdateUserStatus = userStatus => this.setState({ userStatus, editUserStatusDialogOpen: true });
    onClickVoid = ({ boolean, transactionBatchId }) => this.setState({ transactionBatchId, voidDialogOpen: boolean });
    
    onConfirmVoid = async () => {
        const {
            getHCPTransactions, voidTransaction,
            match
        } = this.props;
        const { transactionBatchId } = this.state;
        const userId = match.params.userId;
        
        await voidTransaction({ transactionBatchId });
        await getHCPTransactions(userId);
        const transactions = this.props.transactionList.map(transaction => {
            const products = transaction.transactionCampaigns.reduce((accumulator, currentValue) => {
                accumulator.push(`${currentValue.productFullName} ${currentValue.campaignProductQuantityPackaging}`);

                return accumulator;
            }, []).join(', ');

            return {
                ...transaction,
                products,
                createdAt: dateTimeFormat(transaction.createdAt)
            }
        });
        this.setState({ voidDialogOpen: false, isResponseDialogOpen: true, transactionList: transactions });
    }

    onSubmitUpdateUserDetails = async data => {
        const { 
            getHCPDetails, updateHCPDetails,
            match
        } = this.props;
        const userId = match.params.userId;
        
        this.setState({ isEditDialogOpen: false });
        await updateHCPDetails(userId, data);
        await getHCPDetails(userId);
        this.setState({ isResponseDialogOpen: true });
    }
    
    onSubmitUpdateUserStatus = async () => {
        const {
            getHCPDetails, updateUserStatus,
            numberOfTransactions, match
        } = this.props;
        const { userStatus } = this.state;
        const userId = match.params.userId;
        let status = null;

        if (userStatus === 'Cancelled' || userStatus === 'Deactivated')
            status = 'Active';
        else if (userStatus === 'Active' && numberOfTransactions > 0)
            status = 'Cancelled';
        else if (userStatus === 'Active' && numberOfTransactions === 0)
            status = 'Deactivated';

        //Fix: temporary fix for MTH-1121
        this.setState({ editUserStatusDialogOpen: false});

        await updateUserStatus({ userId, status });
        await getHCPDetails(userId);
        this.setState({ isResponseDialogOpen: true });

    };
    
    render() {
        const { apiResponse, ui } = this.props;
        const {
            transactionList,
            selectedTabValue, 
            isEditDialogOpen, editUserStatusDialogOpen, isResponseDialogOpen, voidDialogOpen
        } = this.state; 
 
        const Content = props => {
            const { details, numberOfTransactions, patientList } = props;
            
            if (Object.entries(details).length === 0)
                return (
                    <Grid container direction='row' spacing={16}>
                        <Grid item xs={12}>
                            <Typography>User does not exist.</Typography>
                        </Grid>
                    </Grid>
                )
            else {
                const {
                    user_id: userId, first_name: firstName, middle_name: middleName = '', last_name: lastName, 
                    email, mobile: mobileNumber, user_type: userType, status,
                    created_at: createdAt, updated_at: updatedAt,
                    organization_code: organizationCode,
                    organization_branch: organizationBranch, 
                    organization_email: organizationEmail, 
                    address, city, 
                    bank_name: bankName, account_name: accountName, account_number: accountNumber, 
                    tax_enabled: taxEnabled, tax_identification_number: taxIdentificationNumber
                } = details;

                /**
                 * @desc User information
                 */
                const hcpDetails = (
                    <Grid container direction='row' spacing={16}>
                        <Grid item xs={12}>
                            <HeaderTypography color='primary'>User Details</HeaderTypography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>User ID:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{userId}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Full Name:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{firstName} {middleName} {lastName}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Email:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{email}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Mobile:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{mobileNumber}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>User Type:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{userType}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Registration Date:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{dateTimeFormat(createdAt)}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Last Update Date:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{dateTimeFormat(updatedAt)}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <HeaderTypography color='primary'>Organization Details</HeaderTypography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Organization Branch:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{organizationBranch}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Zuellig Code:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{organizationCode || 'N/A'}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Address:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{address}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>City:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{city}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Organization Email:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{parsedOrganizationEmail(organizationEmail)}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <HeaderTypography color='primary'>Account Details</HeaderTypography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Bank Name:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{bankName}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Account Name:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{accountName}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Account Number:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{accountNumber}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Tax Enabled:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{taxEnabled || 'N/A'}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <LabelTypography>Tax Identification Number:</LabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{taxIdentificationNumber || 'N/A'}</Typography>
                        </Grid>
                    </Grid>
                );

                /**
                 * @desc List of Patients - Table
                 */
                const patientListHeader = ['Promotion Patient ID', 'Mobile Number', 'Registration Date', 'Status', 'Updated At'];
                const patientListBody = patientList.length > 0
                    ? patientList.map(({ 
                        promotion_patient_id: promotionPatientId, 
                        patient_mobile: patientMobile, promotion_patient_status: status, 
                        promotion_patient_created_at: createdAt, promotion_patient_updated_at: updatedAt 
                    }) => (
                        <TableRow key={promotionPatientId}>
                            <TableCell>{promotionPatientId}</TableCell>
                            <TableCell>{patientMobile}</TableCell>
                            <TableCell>{dateTimeFormat(createdAt)}</TableCell>
                            <TableCell>{status}</TableCell>
                            <TableCell>{dateTimeFormat(updatedAt)}</TableCell>
                        </TableRow>
                    ))
                    : (
                        <TableRow>
                            <TableCell colSpan={patientListHeader.length}>There are no data available</TableCell>
                        </TableRow>
                    );

                /**
                 * @desc List of Transactions - Table
                 */
                const transactionsHeader = ['Transaction Batch ID', 'Transaction Date', 'Products', 'Action'];
                const transactionsBody = transactionList.length > 0
                    ? transactionList.map(({ createdAt, transactionBatchId, products, deletedAt }, index) => (
                        <TableRow key={index}>
                            <TableCell>{transactionBatchId}</TableCell>
                            <TableCell>{createdAt}</TableCell>
                            <TableCell>{products}</TableCell>
                            <TableCell>
                                <Button size='small' variant='contained' color='secondary'
                                    disabled={deletedAt !== null}
                                    onClick={() => this.onClickVoid({ boolean: true, transactionBatchId })} >
                                    {deletedAt === null ? 'Void' : 'Voided'}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))
                    : (
                        <TableRow>
                            <TableCell colSpan={transactionsHeader.length}>There are no data available</TableCell>
                        </TableRow>
                    );

                let userStatusButtonLabel, userStatusHeader, userStatusActionWord = null;
                
                if (status === 'Cancelled' || status === 'Deactivated') {
                    userStatusButtonLabel = 'Activate';
                    userStatusHeader = 'Activation';
                    userStatusActionWord = 'Active'
                }
                else if (status === 'Active' && numberOfTransactions > 0) {
                    userStatusButtonLabel = 'Cancel';
                    userStatusHeader = 'Cancellation';
                    userStatusActionWord = 'Cancelled';
                }
                else if (status === 'Active' && numberOfTransactions === 0) {
                    userStatusButtonLabel = 'Deactivate';
                    userStatusHeader = 'Deactivation';
                    userStatusActionWord = 'Deactivated';
                }

                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <Grid container direction='row' alignItems='center' justify='space-between'>
                                <Grid item xs={7}>
                                    <ChipStatus status={status && status.toLowerCase()} /> {organizationBranch}
                                </Grid>
                                <Grid item>
                                    <Button size='small' variant='contained' color='primary'
                                        onClick={() => this.handleEditDialogVisibility(true)}>
                                        Edit
                                    </Button>
                                    <Button size='small' variant='contained' color='secondary'
                                        onClick={() => this.onClickUpdateUserStatus(status)}>
                                        {userStatusButtonLabel}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <div style={{ backgroundColor: 'white' }}>
                                <AppBar position='relative'>
                                    <Tabs value={selectedTabValue} onChange={this.handleSelectTab}>
                                        <Tab label='Details' />
                                        <Tab label='Patients' />
                                        <Tab label='Transactions' />      
                                        <Tab label='Statistics' />
                                    </Tabs>
                                </AppBar>
                                {selectedTabValue === 0 && <TabContainer>
                                    <Card>
                                        <CardContent>
                                            {hcpDetails}
                                        </CardContent>
                                    </Card>
                                </TabContainer>}
                                {selectedTabValue === 1 && <TabContainer>
                                    <Table
                                        headers={patientListHeader}
                                        body={patientListBody} />
                                </TabContainer>}
                                {selectedTabValue === 2 && <TabContainer>
                                    <Table
                                        headers={transactionsHeader}
                                        body={transactionsBody} />
                                </TabContainer>}
                                {selectedTabValue === 3 && <TabContainer>
                                    <Grid container direction='row' alignItems='center' justify='space-between' spacing={16}>
                                        <Grid item xs={6}>
                                            <Card>
                                                <CardContent>
                                                    <h4>Number of Transactions</h4>
                                                    <Typography>{numberOfTransactions}</Typography>                        
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Card>
                                                <CardContent>
                                                    <h4>Transaction Value (Current Month)</h4>
                                                    <Typography>Coming Soon</Typography>                        
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </TabContainer>}
                            </div>
                        </Grid>

                        <HCPDetailsForm 
                            open={isEditDialogOpen}
                            title='Update Healthcare Professional Details'
                            onClick={this.onSubmitUpdateUserDetails}
                            onClose={() => this.handleEditDialogVisibility(false)}
                            {...details} />

                        <Dialog
                            open={editUserStatusDialogOpen}
                            title={`Account ${userStatusHeader}`} 
                            subtitle={`Do you want to set the status of this account to ${userStatusActionWord}?`}
                            actions={
                                <div>
                                    <Button size='small'
                                        onClick={() => this.handleEditUserStatusDialogOpen(false)}>No</Button>
                                    <Button size='small' variant='contained' color='primary'
                                        onClick={this.onSubmitUpdateUserStatus}>Yes</Button>
                                </div>
                            } />

                        <Dialog 
                            open={voidDialogOpen}
                            title={'Void Transaction'}
                            subtitle={'Are you sure you want to void this transaction?'}
                            actions={
                                <div>
                                    <Button size='small'
                                        onClick={() => this.handleVoidDialogOpen(false)}>No</Button>
                                    <Button size='small' variant='contained' color='primary'
                                        onClick={this.onConfirmVoid}>Yes</Button>
                                </div>
                            } />
                    </Grid>
                )
            }
        }

        return (
            <Grid container>
                <Grid item xs={12}>
                    <h4>Healthcare Professional Details</h4>
                </Grid>
                <Grid item xs={12}>
                    {
                        ui.getHCPDetails.isLoading
                            ? <LinearProgress />
                            : <Content {...this.props} />
                    }
                </Grid>
                <Dialog
                    open={isResponseDialogOpen}
                    title={apiResponse.message}
                    content={apiResponse.result}
                    actions={
                        <Button color='primary'
                            onClick={() => this.handleResponseDialogVisibility(false)}>Ok</Button>
                    } />
            </Grid>
        );
    }
}

const mapStateToProps = ({ apiResponse, hcp, ui }) => ({
    apiResponse: apiResponse.default,
    details: hcp.details,
    numberOfTransactions: hcp.numberOfTransactions,
    patientList: hcp.patientList,
    transactionList: hcp.transactionList,
    ui
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...hcpStore,
    ...transactionStore,
    ...userStore
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HCPDetails);