// Actions
import { apiRequestStart, apiRequestEnd } from '../actions/ui.action';

import store from 'store';

//Services
import { serialize, headersGlobal, refreshToken } from '../services/utilities.services'

import { Request } from '../../utils';

// Constants
import { LOGIN_SUCCESS, LOGIN_FAILED, LOGIN_UNAUTHORIZED, LOGOUT_SUCCESS } from '../../constants/actions.contants';
import { EMAIL_AND_PASSWORD_INVALID, UNAUTHORIZED_ACCESS } from '../../constants/error-messages';

export const loginSuccess = () => ({
    type: LOGIN_SUCCESS,
    payload: {
        isLoading: false,
        isLoggedIn: true
    }
});

export const loginFailed = ({ status, message, result }) => ({
    type: LOGIN_FAILED,
    payload: {
        status,
        message,
        result
    }
});

export const loginUnauthorized = () => ({
    type: LOGIN_UNAUTHORIZED,
    payload: {
        status: 401,
        message: 'Failed',
        result: UNAUTHORIZED_ACCESS
    }
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
    payload: {
        isLoading: false,
        isLoggedIn: false
    }
})

/**
 * Actions
 */
export function login({ email, password }) {
    return async dispatch => {
        const trigger = 'login';
        try {
            const header = {
                'Content-Type' : 'application/x-www-form-urlencoded'
            };
            dispatch(apiRequestStart(trigger));
    
            const {data} =  await Request({
                method: 'POST',
                url: `${process.env.REACT_APP_BASE_API_URL_STAYON}/stayon/core/authenticate/token`, // todo
                data: serialize({
                    grant_type: "password",
                    client_id: "default",
                    scope: "*",
                    username: email,
                    password
                })
            },
                header
            );

            if(data && data.user.type !== 'admin')
            throw {
              status: 401,
              message: "Failed",
              result: "You are not authorized to access this system"
            };

            store.set('user', data);
            dispatch(loginSuccess());
            dispatch(apiRequestEnd(trigger));
        }
        catch(e){
            // dispatch(loginUnauthorized());
            if(e.response)
            dispatch(loginFailed({ status: e.response.status, message: 'Failed', result: EMAIL_AND_PASSWORD_INVALID }));
            else if(e)
            dispatch(loginFailed({ status: e.status, message: 'Failed', result: e.result }));

            dispatch(apiRequestEnd(trigger));
        }

    }
}

export function logout()  {
    return async dispatch => {
        try{
            await Request({
                method: 'POST',
                url:`${process.env.REACT_APP_BASE_API_URL_STAYON}/stayon/core/authenticate/logout`
            },
            headersGlobal() );
            store.remove('user');
            dispatch(logoutSuccess());
        }
        catch(e){
            try{   
                if(e.response && e.response.status === 401){
                    await refreshToken();

                    await Request({
                        method: 'POST',
                        url:`${process.env.REACT_APP_BASE_API_URL_STAYON}/stayon/core/authenticate/logout`
                    },
                    headersGlobal() );
                    store.remove('user');
                    dispatch(logoutSuccess());
                }
                else{
                    throw e;
                }

            }
            catch(error){
                
            }
        }
    }
}