import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import moment from 'moment-timezone';

// Actions
import * as unverifiedEmailStore from '../actions/unverified-email.action';

// Components
import Table from '../../components/ReactTable';
import { bindActionCreators } from 'redux';

const styles = theme => ({
    addHcpButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
});

class UnverifiedEmailListPage extends Component {
    state = {
        currentPage: 1,
        rowsPerPage: 10,
        searchInput: "",
        previousSearch: ""
    };

    async componentDidMount() {
        await this._loadData();
    }


    async componentDidUpdate(){
        if (this.state.previousSearch !== this.props.history.location.search) {
            this.setState({ previousSearch: this.props.history.location.search });
            await this._loadData();
        }
    }

    _loadData = async () => {
        const {pathname: currentPath} = this.props.history.location;
        let params = queryString.parse(this.props.location.search) || {};
    
        this.setState({ searchInput: params.search ? params.search : '' });

        await this.props.getUnverifiedEmailList({...params});
        
        if (params.page <= this.props.unverifiedEmailList && params.page > 0) {
            this.setState({ currentPage: params.page });
        } else if(this.props.unverifiedEmailList.length > 0){
            this.props.history.push(`${currentPath}?page=1`);
        }
    }

    _onInputSearch = e => this.setState({ searchInput: e.target.value });

    _onSubmitSearch = e => {
        e.preventDefault();
        const { pathname: currentPath } = this.props.history.location;
        const { searchInput } = this.state;

        this.props.history.push(`${currentPath}?page=1${searchInput && '&search='+searchInput}`);
    };

    onSelectHCP = userId => this.props.history.push(`${this.props.match.path}/${userId}`);

    render() {
        const { unverifiedEmailCount, unverifiedEmailList, ui, classes } = this.props;
        const {currentPage} = this.state;

        const columns = [
            { Header: "ID", accessor: "user_id" },
            { Header: "Name", accessor: "full_name" },
            { Header: "Clinic Name", accessor: "clinic_name" },
            { Header: "Zuellig Code", accessor: "organization_code" },
            { Header: "Mobile", accessor: "mobile" },
            { Header: "Email", accessor: "email" },
            { Header: "Verification Code", accessor: "verification_code" },
            { Header: "Registration Date", Cell: props => moment(props.original["registration_date"]).format("YYYY-MM-DD") }
        ]; 

        return (
            <Fragment>
                <Grid container>
                    <Grid item xs={6}>
                        <h4>Unverified Emails</h4>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            ui.getUnverifiedEmailList.isLoading
                                ? <LinearProgress />
                                : (
                                    <Table
                                        columns={columns}
                                        count={unverifiedEmailCount}
                                        currentPage={currentPage}
                                        data={unverifiedEmailList}
                                        search={{
                                            onChange: e => this._onInputSearch(e),
                                            onSubmit: e => this._onSubmitSearch(e),
                                            value: this.state.searchInput
                                        }}
                                    />
                                )
                        }
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ unverifiedEmail, ui }) => ({
    unverifiedEmailCount: unverifiedEmail.count,
    unverifiedEmailList: unverifiedEmail.list,
    ui
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...unverifiedEmailStore
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(UnverifiedEmailListPage));
