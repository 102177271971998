import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  Button,
  CircularProgress
} from "@material-ui/core";
import { isEmail} from "validator";

/**
 * Custom Components
 */
import { InputControl } from "../components/FormComponents";
import { Select as ReactSelect } from "../components";
import { HeaderTypography } from "../components/Typography";

/**
 * Error Messages
 */
import {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  EMAIL_REQUIRED,
  EMAIL_INVALID_FORMAT,
  PASSWORD_REQUIRED,
  USER_TYPE_REQUIRED,
  PROMOTION_IS_REQUIRED
} from "../constants/error-messages";

import { getPromotionList } from "../mthp/actionCreators/promotion.actionCreator";

class UserDetailsForm extends Component {
  constructor(props) {
    super(props);

    /**
     * This is an anti-pattern set-up and is acceptable in this use case.
     * Although, find a better solution if necessary.
     */

    this.state = {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      promotion: "",
      password: "",
      userType: "",
      promotionList: [],
      userTypeList: [],
      error: {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        password: "",
        promotion: "",
        userType: ""
      }
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(getPromotionList());

    const userTypeList = [
      {
        label: "Admin",
        value: "admin"
      },
      {
        label: "Dashboard",
        value: "dashboard"
      }
    ];

    const promotions = this.props.promotionList.map(({ id, name, code }) => ({
      label: `${code}`,
      value: id
    }));

    this.setState({
      promotionList: promotions,
      userTypeList
    });
  }

  onSelectChange = name => value => {
    if (name === "userType")
      this.setState({ [name]: value, promotion: "", error: { promotion: "" } });
    else this.setState({ [name]: value });
  };

  onInputChange = e => {
    const { name, value } = e.target;
    const error = { ...this.state.error };

    // TODO: Find a way to optimize this like -- [error[name]]: ''
    error["firstName"] = "";
    error["lastName"] = "";
    error["email"] = "";
    error["promotion"] = "";
    error["userType"] = "";
    error["password"] = "";

    // TODO: Tax identification number is currently not set
    if (name === "mobileNumber")
      value.match(/^[0-9]*$/) && this.setState({ [name]: value });
    else if (name === "accountNumber" || name === "taxIdentificationNumber")
      value.match(/^[0-9]*$/) && this.setState({ [name]: value });
    else this.setState({ [name]: value });

    this.setState({ error });
  };

  resetState = () => ({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    promotion: "",
    password: "",
    userType: "",
    error: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      password: "",
      promotion: "",
      userType: ""
    }
  });
  onSubmit = () => {
    const {
      onClick,
    } = this.props;
    const {
      firstName,
      lastName,
      email,
      promotion,
      userType,
      password /*taxEnabled, taxIdentificationNumber*/
    } = this.state;
    const error = { ...this.state.error };

    // TODO: Move this to a validator class.
    if (firstName.length === 0) error["firstName"] = FIRST_NAME_REQUIRED || "";
    else if (lastName.length === 0) error["lastName"] = LAST_NAME_REQUIRED;
    else if (email.length === 0) error["email"] = EMAIL_REQUIRED;
    else if (!isEmail(email)) error["email"] = EMAIL_INVALID_FORMAT;
    else if (password.length === 0) error["password"] = PASSWORD_REQUIRED;
    else if (userType.length === 0) error["userType"] = USER_TYPE_REQUIRED;
    else if (userType.value === "dashboard" && promotion.length === 0)
      error["promotion"] = PROMOTION_IS_REQUIRED;
    else {
      onClick({
        ...this.state,
        // organizationEmail: formattedOrganizationEmail(organizationEmail),
        userType: userType.value,
        promotion: userType.value === "admin" ? "" : promotion.value,
        error: undefined
      }).then(() => {
        if (this.props.apiResponse && this.props.apiResponse.status === 200)
          this.setState(this.resetState());
      });
    }
    this.setState({ error });
  };

  render() {
    const { open, title, subtitle, isLoading, onClose } = this.props;
    let {
      error,
      userType,
      promotion,
      firstName,
      middleName,
      lastName,
      email,
      password,
      promotionList,
      userTypeList
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={() => {
          this.setState(this.resetState());
          onClose();
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subtitle}</DialogContentText>
          <HeaderTypography color="primary">User Details</HeaderTypography>
          <Grid container justify="space-between" spacing={24}>
            <Grid item xs={4}>
              <InputControl
                fullWidth
                error={error.firstName}
                margin="dense"
                inputLabelFor="firstName"
                inputLabel="First Name"
                inputName="firstName"
                value={firstName}
                onChange={this.onInputChange}
              />
            </Grid>

            <Grid item xs={4}>
              <InputControl
                fullWidth
                margin="dense"
                inputLabelFor="middleName"
                inputLabel="Middle Name"
                inputName="middleName"
                value={middleName}
                onChange={this.onInputChange}
              />
            </Grid>

            <Grid item xs={4}>
              <InputControl
                fullWidth
                error={error.lastName}
                margin="dense"
                inputLabelFor="lastName"
                inputLabel="Last Name"
                inputName="lastName"
                value={lastName}
                onChange={this.onInputChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={24}>
            <Grid item xs={6}>
              <InputControl
                fullWidth
                error={error.email}
                margin="dense"
                inputLabelFor="email"
                inputLabel="Email"
                inputName="email"
                value={email}
                onChange={this.onInputChange}
              />
            </Grid>

            <Grid item xs={6}>
              <InputControl
                fullWidth
                error={error.password}
                margin="dense"
                value={password}
                inputLabelFor="password"
                inputLabel="Password"
                inputName="password"
                inputType="password"
                onChange={this.onInputChange}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl
                error={Boolean(error.userType)}
                fullWidth={true}
                margin="normal"
              >
                <ReactSelect
                  name="userType"
                  value={userType}
                  placeholder="User Type"
                  options={userTypeList}
                  onChange={this.onSelectChange("userType")}
                />
                <FormHelperText>{error.userType}</FormHelperText>
              </FormControl>
            </Grid>

            {userType && userType.value === "dashboard" ? (
              <Grid item xs={6}>
                <FormControl
                  error={Boolean(error.promotion)}
                  fullWidth={true}
                  margin="normal"
                >
                  <ReactSelect
                    options={promotionList}
                    name="promotion"
                    value={promotion}
                    placeholder="Promotion"
                    onChange={this.onSelectChange("promotion")}
                  />
                  <FormHelperText>{error.promotion}</FormHelperText>
                </FormControl>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            size="small"
            onClick={() => {
              this.setState(this.resetState());
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={this.onSubmit}
          >
            {" "}
            {isLoading === true && (
              <CircularProgress size={16} thickness={5} />
            )}{" "}
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.ui && state.ui.addUser.isLoading,
  apiResponse: state.apiResponse && state.apiResponse.default,
  promotionList: state.promotion.promotionList
});

export default connect(mapStateToProps)(UserDetailsForm);
