import React, { Component } from 'react';
import {
    Paper, 
    Table, TableHead, TableBody, TableRow, TableCell,
    TablePagination,
    Checkbox
} from '@material-ui/core';

const HeaderCheckBox = ({ onSelectAll, selectedCount, rowCount }) => (
    <TableCell padding='checkbox'>
        <Checkbox
            color='primary'
            checked={selectedCount === rowCount}
            indeterminate={selectedCount > 0 && selectedCount < rowCount}
            onChange={onSelectAll}
        />
    </TableCell>
);

const RowCheckBox = ({ checked, onChange, value }) => (
    <TableCell padding='checkbox'>
        <Checkbox
            color='primary'
            checked={checked}
            onChange={onChange}
            value={value.toString()}
        />
    </TableCell>
)

// TODO: Add action buttons at the end of a table cell.

class TableComponent extends Component {
    state = {
        page: 0,
        rowsPerPage: 10,
        rowsPerPageOptions: [10],
        selected: []
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
        this.props.onChangePage(page, this.state.rowsPerPage);
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleSelectAllClick = event => {
        if (event.target.checked)
            this.setState(({ selected: this.props.body.data.map(item => item.id) }));
        else
            this.setState({ selected: [] });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const {
            headers, body, multiSelect = false,
            dataLength, hasPagination = false,
        } = this.props;
        const { accessors = [], data = [], key = 0 } = body;
        const {
            page, rowsPerPage, rowsPerPageOptions,
            selected
        } = this.state;

        // RENDER table headers.
        const tableHeaders = headers.length > 0 && headers.map((header, index) => (
            <TableCell key={index}>{header}</TableCell>
        ));

        // RENDER table body.
        const tableRows = data.length > 0
            ? data.map(item => {
                const isSelected = this.isSelected(item[key]);
                return (
                    <TableRow key={item[key]}>
                        {multiSelect && <RowCheckBox checked={isSelected} value={item[key]} />}
                        {accessors.map((accessor, index) => <TableCell key={index}>{item[accessor]}</TableCell>)}
                    </TableRow>
                )
            })
            : (
                <TableRow>
                    <TableCell colSpan={headers.length}>There are no data available</TableCell>
                </TableRow>
            );
    
        return (
            <Paper style={{ overflowX: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {multiSelect && <HeaderCheckBox 
                                selectedCount={selected.length}
                                onSelectAll={this.handleSelectAllClick} 
                                rowCount={body.data.length} />
                            }
                            {tableHeaders}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRows}
                    </TableBody>
                </Table>
                {hasPagination && <TablePagination
                    backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                    nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                    component="div"
                    count={dataLength}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                />}
            </Paper>
        )
    }
}

export default TableComponent;