const BASE = process.env.REACT_APP_BASE_API_URL_MTH;
const ADMIN_PATH = "mthp/admin";
const HCPS = "hcps";
const MESSAGES = "messages";
const PATIENTS = "patients";
const PAYMENTS = "payments";
const PRODUCTS = "products";
const PROMOTIONS = "promotions";
const TRANSACTIONS = "transactions";
const USERS = "users";
const AUTH = "auth";

// Healthcare Professionals
export const ADD_HCP = `${BASE}/${ADMIN_PATH}/${HCPS}/add`
export const GET_HCP_DETAILS = userId => `${BASE}/${ADMIN_PATH}/${HCPS}/${userId}`;
export const GET_HCP_LIST = `${BASE}/${ADMIN_PATH}/${HCPS}`;
export const GET_HCP_NUMBER_OF_TRANSACTIONS = userId =>
  `${BASE}/${ADMIN_PATH}/${HCPS}/${userId}/number-of-transactions`;
export const GET_HCP_PATIENT_LIST = userId =>
  `${BASE}/${ADMIN_PATH}/${HCPS}/${userId}/${PATIENTS}`;
export const GET_HCP_TRANSACTIONS = userId =>
  `${BASE}/${ADMIN_PATH}/${HCPS}/${userId}/transactions`;
export const UPDATE_HCP_DETAILS = userId => `${BASE}/${ADMIN_PATH}/${HCPS}/${userId}`;

// Messages
export const GET_MESSAGE_LOGS = `${BASE}/${ADMIN_PATH}/${MESSAGES}/get-message-logs`;

// Patients
export const GET_PATIENT_LIST = `${BASE}/${ADMIN_PATH}/${PATIENTS}`;
export const GET_PATIENT_PROGRAMME_CODES = patientId =>
  `${BASE}/${ADMIN_PATH}/${PATIENTS}/${patientId}/codes`;
export const GET_PATIENT_TRANSACTIONS = patientId =>
  `${BASE}/${ADMIN_PATH}/${PATIENTS}/${patientId}/transactions`;
export const GET_PATIENT_REGISTRATION_HISTORY = patientId =>
  `${BASE}/${ADMIN_PATH}/${PATIENTS}/${patientId}/registration-history`;

// Payments
export const EXPORT_CLIENT_PAYMENT_REPORT = `${BASE}/${ADMIN_PATH}/${PAYMENTS}/payment-summary/client/export`;
export const GET_CLIENT_PAYMENT_SUMMARY_LIST = `${BASE}/${ADMIN_PATH}/${PAYMENTS}/payment-summary/client`;
export const GET_CLIENT_PAYMENT_TRANSACTION_DETAILS = `${BASE}/${ADMIN_PATH}/${PAYMENTS}/transaction-details/client`;
export const GET_FINANCE_PAYMENT_SUMMARY_LIST = `${BASE}/${ADMIN_PATH}/${PAYMENTS}/payment-summary/finance`;
export const GET_FINANCE_PAYMENT_TRANSACTION_DETAILS = `${BASE}/${ADMIN_PATH}/${PAYMENTS}/transaction-details/finance`;
// Promotions
export const GET_PROMOTION_DETAILS = promotionId =>
  `${BASE}/${ADMIN_PATH}/${PROMOTIONS}/${promotionId}`;
export const GET_PROMOTION_LIST = `${BASE}/${ADMIN_PATH}/${PROMOTIONS}`;

// Products
export const GET_PRODUCT_LIST = `${BASE}/${ADMIN_PATH}/${PRODUCTS}/list`;

// Transactions
export const GET_TRANSACTION_LIST = `${BASE}/${ADMIN_PATH}/${TRANSACTIONS}/list`;

export const RECREATE_TRANSACTION = `${BASE}/${ADMIN_PATH}/${TRANSACTIONS}/add`;
export const UPDATE_PROMOTION_ALLOCATION = promotionId =>
  `${BASE}/${ADMIN_PATH}/${PROMOTIONS}/${promotionId}/update-allocation`;
export const VOID_TRANSACTION = `${BASE}/${ADMIN_PATH}/${TRANSACTIONS}/void`;

// Users
export const ADD_USER = `${BASE}/${ADMIN_PATH}/${USERS}/add`
export const GET_USER_LIST = `${BASE}/${ADMIN_PATH}/${USERS}/list`;
export const UPDATE_USER_STATUS = `${BASE}/${ADMIN_PATH}/${USERS}/update-status`;
export const FORGOT_USER_PASSWORD=`${BASE}/${ADMIN_PATH}/${AUTH}/forgot-password`;
export const UPDATE_USER_PASSWORD=`${BASE}/${ADMIN_PATH}/${AUTH}/reset-password`;
