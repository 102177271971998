import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        primary: {
            main: '#00539B'
        },
        secondary: {
            main: '#F2662B'
        },
        error: {
            main: '#E53935'
        },
        text: {
            primary: '#555'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 13,
        lineHeight: 1.5,
        fontFamily: [
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif'
        ].join(',')
    },
    overrides: {
        MuiButton: {
            root: {
                fontSize: 13,
                margin: 4
            }
        }
    }
});