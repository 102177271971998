import Warp from "warp-sdk-js";

// Actions
import { loginSuccess, loginUnauthorized } from "../../actions/session.action";
import { apiRequestStart, apiRequestEnd } from "../../actions/ui.action";
import * as UserAction from "../../actions/user.action";
import {
  ADD_USER,
  GET_USER_LIST,
  UPDATE_USER_STATUS,
  UPDATE_USER_PASSWORD,
  FORGOT_USER_PASSWORD
} from "../endpoints/mthp.endpoints";

// Utilities
import { headers } from "../utilities/headers.utility";
import { Request } from "../../utils";

export function addUser({
  firstName,
  middleName = "",
  password,
  lastName,
  email,
  promotion = "",
  userType
}) {
  return async dispatch => {
    const trigger = "addUser";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "POST",
          url: ADD_USER,
          data: {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            email,
            promotion_id: promotion,
            password,
            user_type: userType
          }
        },
        headers()
      );

      dispatch(UserAction.addUserSuccess(data));
    } catch (e) {
      if (e.response) dispatch(UserAction.addUserFailed(e.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function getUserList({ search, page = 1, limit = 10 } = {}) {
  return async dispatch => {
    const trigger = "getUserList";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_USER_LIST,
          params: {
            search,
            page,
            limit
          }
        },
        headers()
      );
      dispatch(UserAction.getUserListSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(UserAction.getUserListFailed(error.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function retrieveUser() {
  return async dispatch => {
    const currentUser = await Warp.User.current();

    try {
      if (!currentUser) dispatch(UserAction.retrieveUserFailed());
      else {
        const isDashboardUser = currentUser.get("type") === "admin";

        if (isDashboardUser) {
          dispatch(
            UserAction.retrieveUserSuccess({
              currentUser,
              organizationData: null,
              userPromotionData: null
            })
          );
          dispatch(loginSuccess());
        } else {
          dispatch(loginUnauthorized());
          localStorage.removeItem(
            `${process.env.REACT_APP_WARP_API_URL}:x-warp-session-token`
          );
          localStorage.removeItem(
            `${process.env.REACT_APP_WARP_API_URL}:x-warp-user-current`
          );
          Warp.User._currentUser = null;
        }
      }
    } catch (error) {
      dispatch(UserAction.retrieveUserFailed());
    }
  };
}

export function updateUserStatus({ userId, status }) {
  return async dispatch => {
    const trigger = "updateUserStatus";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "POST",
          url: UPDATE_USER_STATUS,
          data: {
            user_id: userId,
            status
          }
        },
        headers()
      );
      dispatch(UserAction.updateUserStatusSuccess(data));
    } catch (error) {
      if (error.response)
        dispatch(UserAction.updateUserStatusFailed(error.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}


export function forgotPassword({ email }) {
  return async dispatch => {
    const trigger = "forgotUserPassword";
    try {
      dispatch(apiRequestStart(trigger));

      const { data } = await Request({
        method: "POST",
        url: FORGOT_USER_PASSWORD,
        data: {
          email
        }
      });

      dispatch(UserAction.forgotUserPasswordSuccess(data));
    } catch (e) {
      if (e.response)
        dispatch(UserAction.forgotUserPasswordFailed(e.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function updatePassword({
  recoveryCode,
  new_password,
  confirm_password
}) {
  return async dispatch => {
    const trigger = "updateUserPassword";
    try {
      dispatch(apiRequestStart(trigger));

      const { data } = await Request({
        method: "PUT",
        url: UPDATE_USER_PASSWORD,
        data: {
          recovery_key: recoveryCode,
          new_password,
          confirm_password
        }
      });

      dispatch(UserAction.updateUserPasswordSuccess(data));
    } catch (e) {
      if (e.response)
        dispatch(UserAction.updateUserPasswordFailed(e.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}
