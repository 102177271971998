import Warp from "warp-sdk-js";

// Actions
import * as SessionAction from "../../actions/session.action";
import { apiRequestStart, apiRequestEnd } from "../../actions/ui.action";

// Action Creators
import * as UserAction from "../actionCreators/user.actionCreator";

// Constants
import * as Error from "../../constants/error-messages";

export function login({ email, password }) {
  return dispatch => {
    const trigger = "login";
    dispatch(apiRequestStart(trigger));

    return Warp.User.logIn(email, password)
      .then((user) => {
        if(user._attributes.type !== 'admin'){
          dispatch(SessionAction.loginUnauthorized());
          localStorage.removeItem(
            `${process.env.REACT_APP_WARP_API_URL}:x-warp-session-token`
          );
          localStorage.removeItem(
            `${process.env.REACT_APP_WARP_API_URL}:x-warp-user-current`
          );
          Warp.User._currentUser = null;
          dispatch(apiRequestEnd(trigger));
        }else{
          dispatch(UserAction.retrieveUser());
          dispatch(apiRequestEnd(trigger));
        }
      })
      .catch(({ status }) => {
        dispatch(
          SessionAction.loginFailed({
            status,
            message: "Failed",
            result: Error.EMAIL_AND_PASSWORD_INVALID
          })
        );
        dispatch(apiRequestEnd(trigger));
      });
  };
}

export function logout() {
  return dispatch => {
    return Warp.User.logOut()
      .then(() => {
        localStorage.removeItem(
          `${process.env.REACT_APP_WARP_API_URL}:x-warp-session-token`
        );
        localStorage.removeItem(
          `${process.env.REACT_APP_WARP_API_URL}:x-warp-user-current`
        );
        Warp.User._currentUser = null;
        dispatch(SessionAction.logoutSuccess());
      })
      .catch(() => dispatch(SessionAction.logoutSuccess()));
  };
}
