import { Request } from '../../utils';

// Actions
import { apiRequestStart, apiRequestEnd } from './ui.action';

import { refreshToken, headersGlobal } from '../services/utilities.services';

// Constants
import { RETRIEVE_UNVERIFIED_EMAIL_LIST_SUCCESS, RETRIEVE_UNVERIFIED_EMAIL_LIST_FAILED } from '../../constants/actions.contants';
import { GET_UNVERIFIED_EMAIL_LIST } from '../../constants/url-collection-stayon';

const getUnverifiedEmailListSuccess = ({ status, message, result }) => ({
    type: RETRIEVE_UNVERIFIED_EMAIL_LIST_SUCCESS,
    payload: { status, message, result }
});

const getUnverifiedEmailListFailed = ({ status, message, result }) => ({
    type: RETRIEVE_UNVERIFIED_EMAIL_LIST_FAILED,
    payload: { status, message, result }
});

export function getUnverifiedEmailList({ search, page = 1, limit = 10 } = {}) {
    return async dispatch => {
        const trigger = 'getUnverifiedEmailList';

        try {
            dispatch(apiRequestStart(trigger));
            const { data } = await Request({
                method: 'GET',
                url: GET_UNVERIFIED_EMAIL_LIST,
                params: {
                    search,
                    page,
                    limit
                }
            },
            headersGlobal());
            dispatch(getUnverifiedEmailListSuccess(data));
        }
        catch(error) {
            try{
                if(error.response && error.response.status === 401){
                    await refreshToken();

                    const result = await Request({
                        method: 'GET',
                        url: GET_UNVERIFIED_EMAIL_LIST,
                        params: {
                            skip: 0,
                            limit: 100
                        }
                    },
                    headersGlobal());
                    dispatch(getUnverifiedEmailListSuccess(result.data));

                }
                else{
                    throw error;
                }
            }
            catch(e){
                if(e.response)
                dispatch(getUnverifiedEmailListFailed(e.response.data));
            }
        }
        finally {
            dispatch(apiRequestEnd(trigger));
        }
    }
}