import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import { Grid, Button, LinearProgress } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

import UserDetailForm from "../../forms/create-user.form";

// Store
import * as userStore from "../actionCreators/user.actionCreator";

// Custom Components
import Dialog from "../../components/Dialog";
import Table from "../../components/ReactTable";

class UserListPage extends Component {
  state = {
    currentPage: 1,
    searchInput: "",
    previousSearch: "",
    modalAdd: false,
    isResponseDialogOpen: false
  };

  async componentDidMount() {
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== prevProps.history.location.search) {
      this.setState({ previousSearch: prevProps.history.location.search });
      await this._loadData();
    }
  }

  _loadData = async () => {
    const { pathname: currentPath } = this.props.history.location;
    let params = queryString.parse(this.props.location.search) || {};
    const { searchInput } = this.state;

    this.setState({ searchInput: params.search ? params.search : "" });

    await this.props.getUserList({ ...params });

    if (params.page <= this.props.userPages && params.page > 0) {
      this.setState({ currentPage: params.page });
      
    } else if(this.props.userList.length > 0) {
      this.props.history.push(
        `${currentPath}?page=1${searchInput && "&search=" + searchInput}`
      );
    }

   
  };

  _onInputSearch = e => this.setState({ searchInput: e.target.value });

  _onSubmitSearch = e => {
    e.preventDefault();
    const { pathname: currentPath } = this.props.history.location;
    const { searchInput } = this.state;

    this.props.history.push(
      `${currentPath}?page=1${searchInput && "&search=" + searchInput}`
    );
  };

  onSelectButtonAdd = boolean => this.setState({ modalAdd: boolean });
  
  handleResponseDialogVisibility = boolean => {
    if (this.props.apiResponse && this.props.apiResponse.status === 200) {
      this.setState({ isResponseDialogOpen: boolean });
    } else {
      this.setState({ isResponseDialogOpen: boolean, modalAdd: true });
    }
  };

  onSubmitUserDetails = async data => {
    await this.props.addUser(data);
    await this.props.getUserList();
    this.setState({ isResponseDialogOpen: true, modalAdd: false });
  };

  render() {
    const { userCount, userList, ui, apiResponse } = this.props;
    const { modalAdd, isResponseDialogOpen } = this.state;

    const columns = [
      { Header: "User ID", accessor: "user_id" },
      { Header: "Name", accessor: "full_name" },
      { Header: "Email", accessor: "email" },
      { Header: "User Type", accessor: "user_type" },
      {
        Header: "Promotion",
        Cell: props => <span>{props.original.promotion_code || "-"}</span>
      }
      //   { Header: "Last Login Date", accessor: "" }
    ];

    return (
      <Grid container>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <h4>Users</h4>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              this.onSelectButtonAdd(true);
            }}
          >
            <AddIcon />
            Add User
          </Button>
        </Grid>
        <Grid item xs={12}>
          {ui.getUserList.isLoading ? (
            <LinearProgress />
          ) : (
            <Table
              columns={columns}
              count={userCount}
              currentPage={this.state.currentPage}
              data={userList}
              search={{
                onChange: e => this._onInputSearch(e),
                onSubmit: e => this._onSubmitSearch(e),
                value: this.state.searchInput
              }}
            />
          )}
        </Grid>

        <UserDetailForm
          open={modalAdd}
          onClick={this.onSubmitUserDetails}
          title="Add User"
          onClose={() => {
            this.onSelectButtonAdd(false);
          }}
        />

        <Dialog
          open={isResponseDialogOpen}
          title={apiResponse.message}
          content={apiResponse.result}
          actions={
            <Button
              color="primary"
              onClick={() => this.handleResponseDialogVisibility(false)}
            >
              Ok
            </Button>
          }
        />
      </Grid>
    );
  }
}

const mapStateToProps = ({ apiResponse, user, ui }) => ({
  userCount: user.count,
  userList: user.list,
  userPages: user.pages,
  ui,
  apiResponse: apiResponse.default
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...userStore }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListPage);
