// Constants
import { API_REQUEST } from '../constants/actions.contants';

export const apiRequestStart = trigger => ({
    type: API_REQUEST,
    payload: {
        [trigger]: { isLoading: true }
    }
});

export const apiRequestEnd = trigger => ({
    type: API_REQUEST,
    payload: {
        [trigger]: { isLoading: false }
    }
});