// Constants
import {
    EXPORT_CLIENT_PAYMENT_REPORT_SUCCESS,
    EXPORT_CLIENT_PAYMENT_REPORT_FAILED,
    GET_CLIENT_PAYMENT_SUMMARY_LIST_SUCCESS,
    GET_CLIENT_PAYMENT_SUMMARY_LIST_FAILED,
    GET_CLIENT_TRANSACTION_DETAILS_SUCCESS,
    GET_CLIENT_TRANSACTION_DETAILS_FAILED,
    GET_FINANCE_PAYMENT_SUMMARY_LIST_SUCCESS,
    GET_FINANCE_PAYMENT_SUMMARY_LIST_FAILED,
    GET_FINANCE_TRANSACTION_DETAILS_SUCCESS,
    GET_FINANCE_TRANSACTION_DETAILS_FAILED
} from '../constants/actions.contants';

export const exportClientPaymentReportSuccess = ({ status, message, result }) => ({
    type: EXPORT_CLIENT_PAYMENT_REPORT_SUCCESS,
    payload: { status, message, result }
});

export const exportClientPaymentReportFailed = ({ status, message, result }) => ({
    type: EXPORT_CLIENT_PAYMENT_REPORT_FAILED,
    payload: { status, message, result }
});

export const getClientPaymentSummaryListSuccess = ({ status, message, result }) => ({
    type: GET_CLIENT_PAYMENT_SUMMARY_LIST_SUCCESS,
    payload: { status, message, result }
});

export const getClientPaymentSummaryListFailed = ({ status, message, result }) => ({
    type: GET_CLIENT_PAYMENT_SUMMARY_LIST_FAILED,
    payload: { status, message, result }
});

export const getClientTransactionDetailsSuccess = ({ status, message, result }) => ({
    type: GET_CLIENT_TRANSACTION_DETAILS_SUCCESS,
    payload: { status, message, result }
});

export const getClientTransactionDetailsFailed = ({ status, message, result }) => ({
    type: GET_CLIENT_TRANSACTION_DETAILS_FAILED,
    payload: { status, message, result }
});

export const getFinancePaymentSummaryListSuccess = ({ status, message, result }) => ({
    type: GET_FINANCE_PAYMENT_SUMMARY_LIST_SUCCESS,
    payload: { status, message, result }
});

export const getFinancePaymentSummaryListFailed = ({ status, message, result }) => ({
    type: GET_FINANCE_PAYMENT_SUMMARY_LIST_FAILED,
    payload: { status, message, result }
});

export const getFinanceTransactionDetailsSuccess = ({ status, message, result }) => ({
    type: GET_FINANCE_TRANSACTION_DETAILS_SUCCESS,
    payload: { status, message, result }
});

export const getFinanceTransactionDetailsFailed = ({ status, message, result }) => ({
    type: GET_FINANCE_TRANSACTION_DETAILS_FAILED,
    payload: { status, message, result }
});
