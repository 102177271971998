// Constants
import {
    RETRIEVE_PRODUCT_LIST_SUCCESS, RETRIEVE_PRODUCT_LIST_FAILED
} from '../constants/actions.contants';

export const getProductListSuccess = ({ status, message, result }) => ({
    type: RETRIEVE_PRODUCT_LIST_SUCCESS,
    payload: { status, message, result }
});

export const getProductListFailed = ({ status, message, result }) => ({
    type: RETRIEVE_PRODUCT_LIST_FAILED,
    payload: { status, message, result }
});