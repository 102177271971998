import moment from "moment-timezone";
import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Constants
import { REQUIRED_FIELD } from "../constants/error-messages";

// Custom Components
import { DatePicker, Select } from "../components";
import { Checkbox, InputControl } from "../components/FormComponents";

// Store
import * as hcpStore from "../mthp/actionCreators/hcp.actionCreator";
import * as productStore from "../mthp/actionCreators/product.actionCreator";
import * as promotionStore from "../mthp/actionCreators/promotion.actionCreator";
import * as transactionStore from "../mthp/actionCreators/transaction.actionCreator";

/**
 * Styles
 * TODO: Transfer this to a form component together with select
 */
const styles = theme => ({
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  }
});

const dateFormat = "YYYY-MM-DD";

class RecreateTransactionForm extends Component {
  state = {
    createdAt: moment().format(dateFormat),
    description: "",
    entitledForDiscount: true,
    patientProgrammeCode: "",
    selectedCampaignProductQuantity: [],
    selectedPromotionId: 0,
    selectedUser: 0,
    productList: [],
    promotionList: [],
    hcpList: [],
    error: {
      createdAt: "",
      description: "",
      entitledForDiscount: "",
      patientProgrammeCode: "",
      selectedCampaignProductQuantity: "",
      selectedPromotionId: "",
      selectedUser: ""
    }
  };

  async componentDidMount() {
    await this.props.getProductList();
    await this.props.getPromotionList();
    await this.props.getHCPList();

    /**
     * @desc Product List - Dropdown
     */
    const products = this.props.productList
      .filter(
        product =>
          moment(product.campaign_begin_at).format(dateFormat) <=
            this.state.createdAt &&
          moment(product.campaign_end_at).format(dateFormat) >=
            this.state.createdAt
      )
      .map(
        ({ campaign_product_quantity_id, product_name, quantity_display }) => ({
          label: `${product_name} ${quantity_display}`,
          value: campaign_product_quantity_id
        })
      );

    /**
     * @desc Promotion List - Dropwdown
     */
    const promotions = this.props.promotionList.map(({ id, name, code }) => ({
      label: `${name} (${code})`,
      value: id
    }));

    /**
     * @desc User List - Dropdown
     */
    const hcps = this.props.hcpList
      .map(({ user_id, full_name, organization_branch }) => ({
        label: `${organization_branch} - ${full_name} (User ID: ${user_id})`,
        value: user_id
      }))
      .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));

    this.setState({
      productList: products,
      promotionList: promotions,
      hcpList: hcps
    });
  }

  resetState = () => {
    const products = this.props.productList
      .filter(
        product =>
          moment(product.campaign_begin_at).format(dateFormat) <=
            moment().format(dateFormat) &&
          moment(product.campaign_end_at).format(dateFormat) >=
            moment().format(dateFormat)
      )
      .map(
        ({ campaign_product_quantity_id, product_name, quantity_display }) => ({
          label: `${product_name} ${quantity_display}`,
          value: campaign_product_quantity_id
        })
      );

    return {
      createdAt: moment().format(dateFormat),
      description: "",
      entitledForDiscount: true,
      patientProgrammeCode: "",
      selectedCampaignProductQuantity: [],
      selectedPromotionId: 0,
      selectedUser: 0,
      productList: products,
      error: {
        createdAt: "",
        description: "",
        entitledForDiscount: "",
        patientProgrammeCode: "",
        selectedCampaignProductQuantity: "",
        selectedPromotionId: "",
        selectedUser: ""
      }
    };
  };

  onInputChange = ({ target: { checked, type, name, value } }) => {
    // const error = { ...this.state.error };
    // error['patientProgrammeCode'] = '';
    // this.setState({ error });

    if (name === "patientProgrammeCode" && value.match(/^[a-zA-Z0-9]{0,5}$/))
      this.setState({ [name]: value.toUpperCase() });
    // else if (name === 'patientProgrammeCode' && value.length > 0)
    //     this.setState({ error })
    else this.setState({ [name]: type === "checkbox" ? checked : value });
  };

  onSelectChange = name => value => this.setState({ [name]: value });
  onSelectDate = createdAt => {
    const products = this.props.productList
      .filter(
        product =>
          moment(product.campaign_begin_at).format(dateFormat) <=
            moment(createdAt).format(dateFormat) &&
          moment(product.campaign_end_at).format(dateFormat) >=
            moment(createdAt).format(dateFormat)
      )
      .map(
        ({ campaign_product_quantity_id, product_name, quantity_display }) => ({
          label: `${product_name} ${quantity_display}`,
          value: campaign_product_quantity_id
        })
      );

    this.setState({
      createdAt,
      selectedCampaignProductQuantity: [],
      productList: products
    });
  };

  onSubmit = async e => {
    e.preventDefault();
    const {
      createdAt,
      description,
      entitledForDiscount,
      patientProgrammeCode,
      selectedCampaignProductQuantity,
      selectedPromotionId,
      selectedUser
    } = this.state;
    const error = { ...this.state.error };

    error["createdAt"] = "";
    error["description"] = "";
    error["patientProgrammeCode"] = "";
    error["selectedPromotionId"] = "";
    error["selectedCampaignProductQuantity"] = "";
    error["selectedUser"] = null;

    if (selectedUser === 0) error["selectedUser"] = REQUIRED_FIELD("User");
    else if (selectedPromotionId === 0)
      error["selectedPromotionId"] = REQUIRED_FIELD("Promotion");
    else if (!patientProgrammeCode && patientProgrammeCode.length === 0)
      error["patientProgrammeCode"] = REQUIRED_FIELD("Patient Programme Code");
    else if (selectedCampaignProductQuantity.length === 0)
      error["selectedCampaignProductQuantity"] = REQUIRED_FIELD("Product");
    else if (createdAt.length === 0)
      error["createdAt"] = REQUIRED_FIELD("Date of Transaction");
    else {
      await this.props.recreateTransaction({
        campaignProductQuantity: selectedCampaignProductQuantity.map(
          ({ value }) => value
        ),
        createdAt,
        description,
        entitledForDiscount,
        patientProgrammeCode,
        promotionId: selectedPromotionId.value,
        userId: selectedUser.value
      });
      // Close the Recreate Transaction Dialog Component
      if (this.props.apiResponse && this.props.apiResponse.status === 200) {
        this.setState(this.resetState);
      }

      this.props.onClose();

      // Open the API Response Dialog Component
      this.props.onSubmit();
    }

    this.setState({ error });
  };

  render() {
    const { open, onClose } = this.props;
    const {
      createdAt,
      description,
      entitledForDiscount,
      patientProgrammeCode,
      selectedPromotionId,
      selectedCampaignProductQuantity,
      selectedUser,
      productList,
      promotionList,
      hcpList,
      error
    } = this.state;

    return (
      <Fragment>
        <Dialog
          open={open}
          onClose={() => {
            onClose();
            this.setState(this.resetState);
          }}
        >
          <form onSubmit={this.onSubmit}>
            <DialogTitle>Recreate Transaction</DialogTitle>
            <DialogContent>
              <FormControl
                fullWidth
                error={Boolean(error.selectedUser)}
                margin="dense"
              >
                <Select
                  isClearable
                  name="selectedUser"
                  onChange={this.onSelectChange("selectedUser")}
                  options={hcpList}
                  placeholder="Organization"
                  value={selectedUser}
                />
                <FormHelperText>{error.selectedUser}</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                error={Boolean(error.selectedPromotionId)}
                margin="dense"
              >
                <Select
                  isClearable
                  name="selectedPromotionId"
                  onChange={this.onSelectChange("selectedPromotionId")}
                  options={promotionList}
                  placeholder="Promotion"
                  value={selectedPromotionId}
                />
                <FormHelperText>{error.selectedPromotionId}</FormHelperText>
              </FormControl>
              <InputControl
                fullWidth
                error={error.patientProgrammeCode}
                margin="dense"
                inputLabelFor="patientProgrammeCode"
                inputLabel="Patient Programme Code"
                inputName="patientProgrammeCode"
                value={patientProgrammeCode}
                onChange={this.onInputChange}
              />
              <FormControl
                fullWidth
                error={Boolean(error.createdAt)}
                margin="dense"
              >
                <DatePicker
                  fullWidth={false}
                  clearable={false}
                  maxDate={moment().format(dateFormat)}
                  name="date"
                  onChange={this.onSelectDate}
                  style={{ marginBottom: 16 }}
                  value={createdAt}
                />
                <FormHelperText>{error.createdAt}</FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <Checkbox
                  name="entitledForDiscount"
                  checked={entitledForDiscount}
                  onChange={this.onInputChange}
                  label="Entitled for Discount"
                />
              </FormControl>
              <FormControl
                fullWidth
                error={Boolean(error.selectedCampaignProductQuantity)}
                margin="dense"
              >
                <Select
                  isClearable
                  isMulti
                  options={productList}
                  onChange={this.onSelectChange(
                    "selectedCampaignProductQuantity"
                  )}
                  placeholder="Product(s)"
                  value={selectedCampaignProductQuantity}
                />
                <FormHelperText>
                  {error.selectedCampaignProductQuantity}
                </FormHelperText>
              </FormControl>
              <InputControl
                fullWidth
                error={error.description}
                margin="dense"
                inputLabelFor="description"
                inputLabel="Description"
                inputName="description"
                multiline
                value={description}
                onChange={this.onInputChange}
              />
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                onClick={() => {
                  onClose();
                  this.setState(this.resetState);
                }}
              >
                Cancel
              </Button>
              <Button size="small" color="primary" type="submit">
                Submit
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ product, promotion, hcp, apiResponse }) => ({
  promotionList: promotion.promotionList,
  productList: product.list,
  hcpList: hcp.list,
  apiResponse: apiResponse && apiResponse.default
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...productStore,
      ...promotionStore,
      ...transactionStore,
      ...hcpStore
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RecreateTransactionForm));
