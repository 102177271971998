import React, { Component, Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
/**
 * Custom Components
 */
import { HeaderTypography, LabelTypography } from './Typography';

/**
 * Utilities
 */
import { dateTimeFormat } from '../utils';

class TransactionDetails extends Component {
    render() {
        const { selectedTransaction } = this.props;
         
        /**
         * @desc Transaction View - Dialog (Transaction Campaign Data)
         */
        const transctionCampaignContent = transactionCampaigns => {
            const products = transactionCampaigns && transactionCampaigns.length > 0 
                ? transactionCampaigns.map(({ transaction_campaign_id, /**product_id, */ product_full_name, campaign_product_quantity_packaging }, index) => (
                    <Typography key={transaction_campaign_id}>{product_full_name} {campaign_product_quantity_packaging}</Typography>
                ))
                :
                <Typography>An error occured. All transactions should have a corresponding product.</Typography>

            return (
                <Fragment>
                    <Grid item xs={6}>
                        <LabelTypography>Product(s)</LabelTypography>
                    </Grid>
                    <Grid item xs={6}>
                        {products}
                    </Grid>
                </Fragment>
            )
        }

        return (
            <Grid container direction='row' spacing={16}>
                <Grid item xs={12}>
                    <HeaderTypography color='primary'>Transaction Details</HeaderTypography>
                </Grid>
                <Grid item xs={6}>
                    <LabelTypography>Transaction Batch ID</LabelTypography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{selectedTransaction.transaction_batch_id}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <LabelTypography>Patient Code</LabelTypography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{selectedTransaction.promo_code}</Typography>
                </Grid>

                {transctionCampaignContent(selectedTransaction.transaction_campaigns)}

                <Grid item xs={6}>
                    <LabelTypography>Created At</LabelTypography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{dateTimeFormat(selectedTransaction.created_at)}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <LabelTypography>Voided At</LabelTypography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{dateTimeFormat(selectedTransaction.deleted_at)}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <HeaderTypography color='primary'>Patient Details</HeaderTypography>
                </Grid>
                <Grid item xs={6}>
                    <LabelTypography>Patient ID</LabelTypography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{selectedTransaction.patient_id}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <LabelTypography>Patient Mobile</LabelTypography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{selectedTransaction.patient_mobile}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <HeaderTypography color='primary'>User Details</HeaderTypography>
                </Grid>

                <Grid item xs={6}>
                    <LabelTypography>User ID</LabelTypography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{selectedTransaction.user_id}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <LabelTypography>Organization ID</LabelTypography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{selectedTransaction.organization_id}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <LabelTypography>Organization Branch</LabelTypography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{selectedTransaction.organization_branch}</Typography>
                </Grid>
            </Grid>
        )
    }
}

export default TransactionDetails;