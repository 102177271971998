import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  withStyles,
  LinearProgress
} from "@material-ui/core";
import {
  Add as AddIcon,
  ArrowForward as ArrowForwardIcon
} from "@material-ui/icons";
import queryString from 'query-string';

// Actions
import * as transactionStore from "../actionCreators/transaction.actionCreator";

// Custom Components
import { VoidButton } from "../../components/Button";
import Dialog from "../../components/Dialog";
import TransactionDetails from "../../components/TransactionDetails";
import RecreateTransactionForm from "../../forms/recreate-transaction.form";

import Table from "../../components/ReactTable";

// Utilities
import { transactionMapper } from '../../utils/transaction.utils';
import { dateTimeFormat } from '../../utils';

const TransactionDialog = withStyles({
  paper: {
    maxWidth: 450
  }
})(Dialog);

class TransactionListPage extends Component {
  state = {
    currentPage: 1,
    rowsPerPage: 10,
    searchInput: "",
    isVoidButtonClicked: false,
    recreateTransactionDialogOpen: false,
    responseDialogOpen: false,
    selectedTransactionBatch: {},
    transactionDialogOpen: false,
    voidConfirmMessage: "",
    previousSearch: ""
  };

  async componentDidMount() {
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== prevProps.history.location.search) {
      this.setState({ previousSearch: prevProps.history.location.search });
      await this._loadData();
    }
  }

  _loadData = async () => {
    const { pathname: currentPath } = this.props.history.location;
    let params = queryString.parse(this.props.location.search) || {};
    const { searchInput } = this.state;

    this.setState({ searchInput: params.search ? params.search : '' }); 

    await this.props.getTransactions({ ...params });

    if (params.page <= this.props.transactionPages && params.page > 0) {
      this.setState({ currentPage: params.page });
      
    } else if(this.props.transactionList.length > 0) {
      this.props.history.push(
        `${currentPath}?page=1${searchInput && "&search=" + searchInput}`
      );
    }

  };
getUserList
  _onInputSearch = e => this.setState({ searchInput: e.target.value });getUserList
getUserList
  _onSubmitSearch = e => {
    e.preventDefault();
    const { pathname: currentPath } = this.props.history.location;
    const { searchInput } = this.state;

    this.props.history.push(`${currentPath}?page=1${searchInput && '&search='+searchInput}`);
  };

  handleRecreateTransactionDialogOpen = boolean => this.setState({ recreateTransactionDialogOpen: boolean });
  
  handleResponseDialogOpen = boolean => {
      if(this.props.apiResponse && this.props.apiResponse.status === 200){
      this.setState({ responseDialogOpen: boolean });
      }
      else{
          this.setState({ responseDialogOpen: boolean, recreateTransactionDialogOpen: true }); 
      }
  }

  handleTransactionDialogOpen = boolean =>
    this.setState({
      transactionDialogOpen: boolean,
      isVoidButtonClicked: false,
      voidConfirmMessage: ""
    });

  onClickTransactionBatch = ({
    transactionDialogOpen,
    selectedTransactionBatchId
  }) => {
    const { transactionList } = this.props;
    const transactionBatch = transactionList.find(
      ({ transaction_batch_id }) =>
        transaction_batch_id === selectedTransactionBatchId
    );
    this.setState({
      selectedTransactionBatch: transactionBatch,
      transactionDialogOpen
    });
  };

  onClickVoid = isVoidButtonClicked => {
    this.setState({
      isVoidButtonClicked,
      voidConfirmMessage:
        isVoidButtonClicked === true
          ? "Are you sure you want to void this transaction?"
          : ""
    });
  };

  onConfirmVoid = async transactionBatchId => {
    await this.props.voidTransaction({ transactionBatchId });
    this.setState({
      transactionDialogOpen: false,
      isVoidButtonClicked: false,
      voidConfirmMessage: ""
    });
    await this.props.getTransactions();
  };

  render() {
    const { apiResponse, transactionCount, transactionList, ui } = this.props;
    const {
      recreateTransactionDialogOpen,
      responseDialogOpen,
      selectedTransactionBatch,
      transactionDialogOpen,
      isVoidButtonClicked,
      voidConfirmMessage
    } = this.state;

    const columns = [
      { Header: "Transaction Batch ID", accessor: "transaction_batch_id" },
      {
        Header: "Transaction Date",
        accessor: "created_at",
        Cell: props => <span>{dateTimeFormat(props.original.created_at)}</span>
      },
      { Header: "Patient Code", accessor: "promo_code" },
      { Header: "Products", accessor: "products" },
      {
        Header: "Status",
        accessor: "deleted_at",
        Cell: props =>
          props.original.deleted_at !== null ? "Voided" : "Approved"
      },
      {
        Header: "View Tranaction",
        Cell: props => (
          <IconButton
            size="small"
            color="primary"
            onClick={() =>
              this.onClickTransactionBatch({
                transactionDialogOpen: true,
                selectedTransactionBatchId: props.original.transaction_batch_id
              })
            }
          >
            <ArrowForwardIcon />
          </IconButton>
        )
      }
    ];

    const transactions = transactionMapper(transactionList);

    return (
      <Fragment>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <h4>Transactions</h4>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => this.handleRecreateTransactionDialogOpen(true)}
          >
            <AddIcon />
            Recreate Transaction
          </Button>
        </Grid>

        <Grid container spacing={16}>
          <Grid item xs={12}>
            {ui.getTransactions.isLoading ? (
            <LinearProgress />
          ) : (
            <Table
              columns={columns}
              count={transactionCount}
              currentPage={this.state.currentPage}
              data={transactions}
              search={{
                onChange: e => this._onInputSearch(e),
                onSubmit: e => this._onSubmitSearch(e),
                value: this.state.searchInput
              }}
            />
          )}
          </Grid>
        </Grid>

        <TransactionDialog
          open={transactionDialogOpen}
          title={null}
          content={
            <TransactionDetails
              selectedTransaction={selectedTransactionBatch}
            />
          }
          scroll="paper"
          actions={
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Typography>{voidConfirmMessage}</Typography>
              <Button
                size="small"
                onClick={() => this.handleTransactionDialogOpen(false)}
              >
                Cancel
              </Button>
              {isVoidButtonClicked && (
                <VoidButton
                  size="small"
                  color="primary"
                  onClick={() =>
                    this.onConfirmVoid(
                      selectedTransactionBatch.transaction_batch_id
                    )
                  }
                >
                  Yes
                </VoidButton>
              )}
              {!isVoidButtonClicked &&
                selectedTransactionBatch.deleted_at === null && (
                  <VoidButton
                    size="small"
                    variant="contained"
                    onClick={() => this.onClickVoid(true)}
                  >
                    Void
                  </VoidButton>
                )}
            </Grid>
          }
        />

        <RecreateTransactionForm
          open={recreateTransactionDialogOpen}
          onSubmit={() => this.setState({responseDialogOpen: true })}
          onClose={() => this.handleRecreateTransactionDialogOpen(false)}
        />

        <Dialog
          open={responseDialogOpen}
          title={apiResponse.message}
          content={apiResponse.result}
          actions={
            <Button onClick={() => this.handleResponseDialogOpen(false)}>
              Ok
            </Button>
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ apiResponse, transaction, ui }) => ({
  apiResponse: apiResponse && apiResponse.default,
  transactionCount: transaction.count,
  transactionList: transaction.list,
  transactionPages: transaction.pages,
  ui
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...transactionStore }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionListPage);
