import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import _ from "lodash";
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  Grid,
  CircularProgress,
  colors,
} from '@material-ui/core';
import {
  createHcp as createHcpAction,
} from '../actions/hcp.action';

const styles = theme => ({
  content: {
    // minWidth: '620px',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: colors.green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class HcpCreation extends Component {
  state = {
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    clinicName: '',
    mobile: '',
    zuelligAccountNo: '',
    errors: {},
    isLoading: false,
    isAlert: false,
    alertMessage: '',
  }

  submit = async () => {
    const { createHcp } = this.props;
    const {
      email,
      firstName,
      middleName,
      lastName,
      clinicName,
      mobile,
      zuelligAccountNo,
    } = this.state;

    if (this.validate()) {
      try {
        this.setState({ isLoading: true });
        await createHcp({
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          mobile_number: mobile,
          email,
          promotion_id: '2',
          userType: 'physician',
          organization_branch: clinicName,
          organization_code: zuelligAccountNo,
        });

        this.reset();
        this.setState({
          isLoading: false,
          alertMessage: 'HCP successfully added',
        });
      } catch (error) {
        const { data } = error.response;

        this.setState({
          isLoading: false,
          alertMessage: data.message,
        });
      }
      this.setState({ isAlert: true })
    }
  }

  close = () => {
    const { history } = this.props;
    return () => history.push('/stayon/hcps');
  }

  validate = (fieldName) => {
    let errors = {};
    const {
      email,
      firstName,
      lastName,
      clinicName,
      mobile,
      zuelligAccountNo,
    } = this.state;

    if (_.isEmpty(email)) {
      errors =  this.addError(errors, { email: 'Should not be empty' });
    }
    if (_.isEmpty(firstName)) {
      errors =  this.addError(errors, { firstName: 'Should not be empty' });
    }
    if (_.isEmpty(lastName)) {
      errors =  this.addError(errors, { lastName: 'Should not be empty' });
    }
    if (_.isEmpty(clinicName)) {
      errors =  this.addError(errors, { clinicName: 'Should not be empty' });
    }
    if (_.isEmpty(mobile)) {
      errors =  this.addError(errors, { mobile: 'Should not be empty' });
    }
    if (_.isEmpty(zuelligAccountNo)) {
      errors =  this.addError(errors, { zuelligAccountNo: 'Should not be empty' });
    }
    this.setState({ errors });

    return _.isEmpty(errors);
  }

  reset = () => {
    this.setState({
      email: '',
      firstName: '',
      middleName: '',
      lastName: '',
      clinicName: '',
      mobile: '',
      zuelligAccountNo: '',
    });
  }

  handleChange = (event) => {
    const { target } = event;
    const { id, value } = target;
    this.setState({
      [id]: value,
    });
  }

  addError = (arr, err) => {
    return { ...arr, ...err }
  };

  isError = (fieldName) => _.has(this.state, `errors.${fieldName}`)

  getError = (fieldName) => _.get(this.state, `errors.${fieldName}`) || ''

  render() {
    const { classes } = this.props;
    const {
      email,
      firstName,
      middleName,
      lastName,
      clinicName,
      mobile,
      zuelligAccountNo,
      isLoading,
      isAlert,
      alertMessage,
    } = this.state;

    return (
      <Fragment>
        <Dialog
          open={true}
          onClose={this.close()}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="simple-dialog-title">Add HCP</DialogTitle>
          <DialogContent
            className={classes.content}
          >
            <DialogContentText>
              Please fill up the form completely
            </DialogContentText>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  id="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  required
                  error={this.isError('email')}
                  helperText={this.getError('email')}
                  onChange={this.handleChange}
                  value={email}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  id="firstName"
                  label="First Name"
                  fullWidth
                  required
                  error={this.isError('firstName')}
                  helperText={this.getError('firstName')}
                  onChange={this.handleChange}
                  value={firstName}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  id="middleName"
                  label="Middle Name"
                  fullWidth
                  error={this.isError('middleName')}
                  helperText={this.getError('middleName')}
                  onChange={this.handleChange}
                  value={middleName}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  id="lastName"
                  label="Last Name"
                  fullWidth
                  required
                  error={this.isError('lastName')}
                  helperText={this.getError('lastName')}
                  onChange={this.handleChange}
                  value={lastName}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  id="clinicName"
                  label="Clinic Name"
                  fullWidth
                  required
                  error={this.isError('clinicName')}
                  helperText={this.getError('clinicName')}
                  onChange={this.handleChange}
                  value={clinicName}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  id="mobile"
                  label="Mobile Number"
                  type="number"
                  fullWidth
                  required
                  error={this.isError('mobile')}
                  helperText={this.getError('mobile')}
                  onChange={this.handleChange}
                  value={mobile}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  id="zuelligAccountNo"
                  label="Zuellig Account Number (min 4 alphanumeric)"
                  fullWidth
                  required
                  error={this.isError('zuelligAccountNo')}
                  helperText={this.getError('zuelligAccountNo')}
                  onChange={this.handleChange}
                  value={zuelligAccountNo}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.close()} color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={() => this.submit()}
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                Add
              </Button>
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </DialogActions>
        </Dialog>

        {/* Popup Component */}
        <Dialog
          open={isAlert}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {alertMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ isAlert: false })}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
};

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = dispatch => bindActionCreators({
  createHcp: createHcpAction,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HcpCreation))
