import React, { Component } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Button, FormControl, FormHelperText,
} from '@material-ui/core';

/**
 * Custom Components
 */
import { Checkbox, InputControl } from '../FormComponents';

class PromotionAllocationForm extends Component {
    constructor(props) {
        super(props);

        const {
            allowed_users: allowedUsers,
            has_user_segment: hasUserSegment = false,
            user_segment: userSegment = {}
        } = this.props.configurations;

        this.state = {
            allowedUsers,
            hasUserSegment,
            userSegment,
            error: {
                allowedUsers: '',
                hasUserSegment: '',
                userSegment: '',
                physician:'',
                pharmacist:''
            }
        }
    }

    onInputChange = ({ target: { checked, type, name, value } }) => {
        value = type === 'checkbox' ? checked : value;

        const error = {...this.state.error};

        
        error['physician'] = '';
        error['pharmacist'] = '';
        error['allowedUsers'] = '';

        // TODO: Add validation here.
        // Validate if name exists in userSegment.
        name in this.state.userSegment 
            ? this.setState({ userSegment: {...this.state.userSegment, ...{ [name]: value }}})
            : this.setState({ [name]: value });

        this.setState({ error });
    };

    onSubmit = () => {
        const { allowedUsers, hasUserSegment , userSegment, } = this.state;

        const {
            allowed_users: initialUserValue,
            user_segment: initialUserSegment
        } = this.props.configurations;
        const error = {...this.state.error};

        error['physician'] = '';
        error['pharmacist'] = '';
        error['allowedUsers'] = '';

        if(hasUserSegment && ("physician" in userSegment) && (!userSegment.physician || userSegment.physician.length === 0))
            error['physician'] = 'Physician is required';
        else if(hasUserSegment && ("physician" in userSegment) && (isNaN(userSegment.physician)))
            error['physician'] = 'Physician should be integer';
        else if(hasUserSegment && ("pharmacist" in userSegment) && (!userSegment.pharmacist || userSegment.pharmacist.length === 0))
            error['pharmacist'] = 'Pharmacist is required';
        else if(hasUserSegment  && ("pharmacist" in userSegment) && (isNaN(userSegment.pharmacist)))
            error['pharmacist'] = 'Pharmacist should be integer'; 
        else if(!hasUserSegment && (!allowedUsers || allowedUsers.length === 0))
            error['allowedUsers'] = 'User is required';
        else if(!hasUserSegment && (allowedUsers && isNaN(allowedUsers)))
            error['allowedUsers'] = 'User should be integer';
        else {
            this.props.onClick({
                ...(hasUserSegment ? {userSegment,hasUserSegment,allowedUsers: initialUserValue} : {hasUserSegment, allowedUsers, userSegment: initialUserSegment}),
                ...{ error: undefined }
            }); 
        }

        this.setState({error})
    };

    render() {
        const { open, title, onClose, configurations } = this.props;
        const { allowedUsers, hasUserSegment, userSegment, error } = this.state;
        let [dialogTitle, dialogContent, dialogActions, allocationContent] = [null, null, null, null];

        // Check if there are configurations in the promotion.
        if (!configurations) {
            dialogTitle=<DialogTitle>Configuration not found</DialogTitle>
            dialogContent = (
                <DialogContent>
                    <DialogContentText>Oops. Seems that this promotion has no configurations.</DialogContentText>
                </DialogContent>
            );
            dialogActions = (
                <DialogActions>
                    <Button size='small' variant='contained'
                        onClick={onClose}>Cancel</Button>
                </DialogActions>
            )
        }
        else {
            if (!hasUserSegment) {
                allocationContent = (
                    <InputControl fullWidth
                        error={error.allowedUsers}
                        margin='dense'
                        inputLabelFor='allowedUsers'
                        inputLabel='Number of allowed users'
                        inputName='allowedUsers'
                        value={allowedUsers}
                        onChange={this.onInputChange} />
                )
            }
            else {
                allocationContent = Object.entries(userSegment).map(([userType, value]) => 
                    <InputControl fullWidth
                        key={userType}
                        error={error[userType]}
                        margin='dense'
                        inputLabelFor={userType}
                        inputLabel={`Number of allowed ${userType}s`}
                        inputName={userType}
                        value={value}
                        onChange={this.onInputChange} />
                );
            }
            dialogTitle = <DialogTitle>{title}</DialogTitle>;
            dialogContent = (
                <DialogContent>
                    {/* <DialogContentText>{subtitle}</DialogContentText> */}
                    <FormControl>
                        <Checkbox
                            name='hasUserSegment'
                            checked={hasUserSegment}
                            onChange={this.onInputChange}
                            label='Does this promotion have user segments?' />
                        <FormHelperText>By checking the box, users allowed in the promotion will be counted by type.</FormHelperText>
                        {allocationContent}
                    </FormControl>
                </DialogContent>
            );
            dialogActions = (
                <DialogActions>
                    <Button size='small' onClick={onClose}>Cancel</Button>
                    <Button size='small' color='primary' onClick={this.onSubmit}>Submit</Button>
                </DialogActions>
            );
        }
        return (
            <Dialog open={open} onClose={onClose}>
                {dialogTitle}
                {dialogContent}
                {dialogActions}
            </Dialog>
        )
    }
}

export default PromotionAllocationForm;