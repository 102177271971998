import { Request } from "../../utils";

// Actions
import {
  getTransactionsSuccess,
  getTransactionsFailed,
  recreateTransactionSuccess,
  recreateTransactionFailed,
  voidTransactionSuccess,
  voidTransactionFailed
} from "../../actions/transaction.action";
import { apiRequestStart, apiRequestEnd } from "../../actions/ui.action";

// Endpoints
import {
  GET_TRANSACTION_LIST,
  RECREATE_TRANSACTION,
  VOID_TRANSACTION
} from "../endpoints/mthp.endpoints";

// Utilities
import { headers } from "../utilities/headers.utility";

export function getTransactions({ search, page = 1, limit = 10 } = {}) {
  return async dispatch => {
    const trigger = "getTransactions";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_TRANSACTION_LIST,
          params: {
            search,
            page,
            limit
          }
        },
        headers()
      );
      dispatch(getTransactionsSuccess(data));
    } catch (error) {
      if (error.response) dispatch(getTransactionsFailed(error));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function recreateTransaction({
  campaignProductQuantity,
  createdAt,
  description,
  entitledForDiscount,
  patientProgrammeCode,
  promotionId,
  userId
}) {
  return async dispatch => {
    const trigger = "recreateTransaction";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "POST",
          url: RECREATE_TRANSACTION,
          data: {
            campaign_product_quantity_id: campaignProductQuantity,
            created_at: createdAt,
            description,
            entitled_for_discount: entitledForDiscount,
            promo_code: patientProgrammeCode,
            promotion_id: promotionId,
            transaction_client: "web",
            user_id: userId
          }
        },
        headers()
      );
      dispatch(recreateTransactionSuccess(data));
    } catch (error) {
      dispatch(recreateTransactionFailed(error.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}

export function voidTransaction({ transactionBatchId }) {
  return async dispatch => {
    const trigger = "voidTransaction";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "POST",
          url: VOID_TRANSACTION,
          data: {
            transaction_batch_id: transactionBatchId
          }
        },
        headers()
      );
      dispatch(voidTransactionSuccess(data));
    } catch (error) {
      dispatch(voidTransactionFailed(error.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}
