import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ErrorPage from '../common/pages/error.page';
import MainPage from '../common/pages/main.page'

import RequireAuthentication from '../components/auth/RequireAuthentication.hoc';
import StayOnRequireAuthentication from '../components/auth/Authentication.hoc';
import MyCareRequireAuthentication from '../components/auth/Authentication.hoc.mycare';

import Mthp from '../mthp/pages/index.page';
import StayOn from '../stayon/pages/index.page';
import MyCare from '../mycare/pages/index.page';

const AppRouter = () => {
    return (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <BrowserRouter>
                <Switch>
                    <Route path='/' component={MainPage} exact />
                    <Route path='/mthp/login' component={Mthp.LoginPage} />
                    <Route path='/mthp/forgot-password' component={Mthp.ForgotPasswordPage} />
                    <Route path='/mthp/reset-password' component={Mthp.ResetPasswordPage} />
                    <RequireAuthentication path='/mthp' component={Mthp.HomePage} exact />
                    <RequireAuthentication path='/mthp/hcps' component={Mthp.HCPListPage} exact />
                    <RequireAuthentication path='/mthp/hcps/:userId' component={Mthp.HCPDetailsPage} exact />
                    <RequireAuthentication path='/mthp/patients' component={Mthp.PatientListPage} exact />
                    <RequireAuthentication path='/mthp/patients/:patientId' component={Mthp.PatientDetailsPage} />
                    <RequireAuthentication path='/mthp/promotions' component={Mthp.PromotionListPage} exact />
                    <RequireAuthentication path='/mthp/promotions/:promotionId' component={Mthp.PromotionDetailsPage} exact />
                    <RequireAuthentication path='/mthp/transactions' component={Mthp.TransactionListPage} exact />
                    <RequireAuthentication path='/mthp/payments' component={Mthp.PaymentPage} exact />
                    <RequireAuthentication path='/mthp/messages' component={Mthp.MessagePage} />
                    <RequireAuthentication path='/mthp/about' component={Mthp.AboutPage} />
                    <RequireAuthentication path= '/mthp/users' component = {Mthp.UserListPage}/>
                    {/**
                    // TODO: (Stay On) Add an authentication layer.
                    */}
                    <Route path='/stayon/login' component={StayOn.LoginPage} exact />
                    <StayOnRequireAuthentication path='/stayon' component={StayOn.HomePage} exact />
                    <StayOnRequireAuthentication path='/stayon/hcps' component={StayOn.HcpListPage} exact />
                    <StayOnRequireAuthentication path='/stayon/hcps/add' component={StayOn.HcpListPage} exact />
                    <StayOnRequireAuthentication path='/stayon/hcps/:userId' component={StayOn.HcpDetailsPage} exact />
                    <StayOnRequireAuthentication path='/stayon/unverified-emails' component={StayOn.UnverifiedEmailListPage} exact />
                    <StayOnRequireAuthentication path='/stayon/patients' component={StayOn.PatientListPage} exact />                    
                    <StayOnRequireAuthentication path='/stayon/transactions' component={StayOn.TransactionListPage} exact />

                    {/**
                    // TODO: (My Care) Add an authentication layer.
                    */}
                    <Route path='/mycare/login' component={MyCare.LoginPage} exact />
                    <MyCareRequireAuthentication path='/mycare' component={MyCare.HomePage} exact />
                    <MyCareRequireAuthentication path='/mycare/hcps' component={MyCare.HcpListPage} exact />
                    <MyCareRequireAuthentication path='/mycare/hcps/:userId' component={MyCare.HcpDetailPage} exact />
                    <Route path='*' component={ErrorPage} />
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default AppRouter;