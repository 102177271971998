export const EMAIL_AND_PASSWORD_INVALID = 'Invalid Email Address and Password';
export const EMAIL_REQUIRED = 'A valid Email Address is required';
export const EMAIL_INVALID_FORMAT = 'A valid Email Address is required';
export const RECOVERY_CODE_REQUIRED = 'Recovery Code is required';
export const FIRST_NAME_REQUIRED = 'First Name is required';
export const LAST_NAME_REQUIRED = 'Last Name is required';
export const PASSWORD_REQUIRED = 'Password field is required';
export const PASSWORD_MIN_LENGTH = 'Password should be 8-16 characters';
export const PASSWORD_MAX_LENGTH = 'Password should be 8-16 characters';
export const PASSWORD_AND_CONFIRM_PASSWORD_NOT_MATCH = 'Password and confirm password does not match';
export const UNAUTHORIZED_ACCESS = 'You are not authorized to access this page';
export const USER_TYPE_REQUIRED = 'User Type is required';
export const PROMOTION_IS_REQUIRED = 'Promotion is required';
export const HCP_CODE_REQUIRED = 'HCP Code is required';

export const HEAD_OFFICE_EMAIL_REQUIRED = 'Head Office Email required';
export const HEAD_OFFICE_EMAIL_INVALID_FORMAT = 'Head Office Email format is invalid';
export const MOBILE_NUMBER_REQUIRED = 'Mobile Number is required';
export const MOBILE_NUMBER_INVALID_FORMAT = 'Mobile Number format is invalid';

export const PHARMACY_NAME_REQUIRED = 'Pharmacy Name is required';
export const PHARMACY_ADDRESS_REQUIRED = 'Pharmacy Address is required';
export const CLINIC_NAME_REQUIRED = 'Clinic Name is required';
export const CLINIC_ADDRESS_REQUIRED = 'Clinic Address is required';

export const BANK_NAME_REQUIRED = 'Bank Name is required';
export const BANK_ACCOUNT_NAME_REQUIRED = 'Bank Account Name is required';
export const BANK_ACCOUNT_NUMBER_REQUIRED = 'Bank Account Number is required';
export const GST_NUMBER_REQUIRED = 'GST Number is required';
export const GST_NUMBER_LENGTH = 'GST Number must be 12 digits';

export const REQUIRED_FIELD = fieldName => `${fieldName} is required`;
export const MIN_MAX_FIELD = (fieldName, minLength, maxLength) => `${fieldName} should be ${minLength}-${maxLength} characters`;