import React from 'react';
import {
    Paper, Table, TableHead, TableBody, TableRow, TableCell
} from '@material-ui/core';

const TableComponent = ({ headers, body, onInputSearch }) => {
    let tableHeaders = headers.length > 0 && headers.map((header, index) => (
        <TableCell key={index}>{header}</TableCell>
    ));

    // TODO: Enhance this component since i'm too lazy to this right now.
    // Basically what you need to do is loop the content and loop the objects.
    // const bodyRows =  body.length > 0
    //     ? body.map(item => (
    //         <TableRow>
    //             <TableCell>{item}</TableCell>
    //         </TableRow>
    //     ))
    //     : (
    //         <TableRow>
    //             <TableCell colSpan={headers.length}>There are no data available</TableCell>
    //         </TableRow>
    //     );

    return (
        <Paper style={{ overflowX: 'auto' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableHeaders}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {body}
                </TableBody>
            </Table>
        </Paper>
    )
}

export default TableComponent;