import { Request } from '../../utils';

// Actions
import { apiRequestStart, apiRequestEnd } from './ui.action';

import { refreshToken, headersGlobal } from '../services/utilities.services';

// Constants
import {
    GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_FAILED,
    RECREATE_TRANSACTION_SUCCESS, RECREATE_TRANSACTION_FAILED,
    VOID_TRANSACTION_SUCCESS, VOID_TRANSACTION_FAILED
} from '../../constants/actions.contants';
import {
    GET_TRANSACTIONS,
    RECREATE_TRANSACTION,
} from '../../constants/url-collection-stayon';

const getTransactionsSuccess = ({ status, message, result }) => ({
    type: GET_TRANSACTIONS_SUCCESS,
    payload: { status, message, result }
});

const getTransactionsFailed = ({ status, message, result }) => ({
    type: GET_TRANSACTIONS_FAILED,
    payload: { status, message, result }
});

const recreateTransactionSuccess = ({ status, message, result }) => ({
    type: RECREATE_TRANSACTION_SUCCESS,
    payload: { status, message, result }
});

const recreateTransactionFailed = ({ status, message, result }) => ({
    type: RECREATE_TRANSACTION_FAILED,
    payload: { status, message, result }
});

const voidTransactionSuccess = ({ status, message, result }) => ({
    type: VOID_TRANSACTION_SUCCESS,
    payload: { status, message, result }
});

const voidTransactionFailed = ({ status, message, result }) => ({
    type: VOID_TRANSACTION_FAILED,
    payload: { status, message, result }    
});

export function getTransactions({ query = '', page = 0, limit = 10 } = {}) {
    return async dispatch => {
        const trigger = 'getTransactions';
        try {
            dispatch(apiRequestStart(trigger));
            const { data } = await Request({
                method: 'GET',
                url: GET_TRANSACTIONS,
                params: {
                    query,
                    page,
                    limit
                }
            });
            dispatch(getTransactionsSuccess(data));
        }
        catch (error) {
            dispatch(getTransactionsFailed(error));
        }
        finally {
            dispatch(apiRequestEnd(trigger));
        }
    }
}

export function recreateTransaction({
    campaignProductQuantity, createdAt, description,
    entitledForDiscount, patientProgrammeCode,
    promotionId, userId
}) {
    return async dispatch => {
        const trigger = 'recreateTransaction';
        try {
            dispatch(apiRequestStart(trigger));
            const { data } = await Request({
                method: 'POST',
                url: RECREATE_TRANSACTION,
                data: {
                    campaign_product_quantity_id: campaignProductQuantity,
                    created_at: createdAt,
                    description,
                    entitled_for_discount: entitledForDiscount,
                    promo_code: patientProgrammeCode,
                    promotion_id: promotionId,
                    transaction_client: 'web',
                    user_id: userId
                }
            })
            dispatch(recreateTransactionSuccess(data));
        }
        catch (error) {
            dispatch(recreateTransactionFailed(error.response.data));
        }
        finally {
            dispatch(apiRequestEnd(trigger));
        }
    }
}

export function voidTransaction({ transactionBatchId }) {
    return async dispatch => {
        const trigger = 'voidTransaction';
        try {
            dispatch(apiRequestStart(trigger));
            const { data } = await Request({
                method: 'DELETE', //to do:
                url: `${process.env.REACT_APP_BASE_API_URL_STAYON}/stayon/admin/transactions/${transactionBatchId}`,
                data: {
                    transaction_batch_id: transactionBatchId
                }
            },
            headersGlobal());
            dispatch(voidTransactionSuccess(data));
        }
        catch (error) {
            try{
                if(error.response && error.response.status === 401){
                    await refreshToken();

                    const { data } = await Request({
                        method: 'DELETE',
                        url: `${process.env.REACT_APP_BASE_API_URL_STAYON}/stayon/admin/transactions/${transactionBatchId}/void-transactions`, //to do
                        data: {
                            transaction_batch_id: transactionBatchId
                        }
                    },
                    headersGlobal());
                    dispatch(voidTransactionSuccess(data));
                }
                else{
                    throw error;
                }
            }
            catch(e){
                if(e.response)
                dispatch(voidTransactionFailed(e.response.data));
                
            }
        }
        finally {
            dispatch(apiRequestEnd(trigger));
        }
    }
}