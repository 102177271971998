// Actions
import * as MessageAction from "../../actions/message.action";
import { apiRequestStart, apiRequestEnd } from "../../actions/ui.action";

// Endpoints
import { GET_MESSAGE_LOGS } from "../endpoints/mthp.endpoints";

// Utilities
import { headers } from "../utilities/headers.utility";
import { Request} from "../../utils";

/**
 * Get Message Logs
 * @param {number} page
 * @param {number} limit
 * @param {Object} options
 */
export function getMessageLogs({search, page = 1, limit = 10} = {}){
  return async dispatch => {
    const trigger = "getMessageLogs";
    try {
      dispatch(apiRequestStart(trigger));
      const { data } = await Request(
        {
          method: "GET",
          url: GET_MESSAGE_LOGS,
          params: {
            search,
            page,
            limit
          }
        },
        headers()
      );
      dispatch(MessageAction.getMessageLogsSuccess(data));
    } catch (e) {
      if (e.response)
        dispatch(MessageAction.getMessageLogsFailed(e.response.data));
    } finally {
      dispatch(apiRequestEnd(trigger));
    }
  };
}
